import React, { FC, memo } from 'react'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { useProjectState } from '@hooks/useProject'
import { PrimitiveSymmetryChecked } from '@services/engine/types'
import { ActionButton, FieldLabel, Icon, OverlayTrigger, Tooltip } from 'ui'

import DuplicateMirrorXIcon from '/public/s2_icon_duplicate_mirror_x.svg'
import DuplicateMirrorYIcon from '/public/s2_icon_duplicate_mirror_y.svg'
import DuplicateMirrorZIcon from '/public/s2_icon_duplicate_mirror_z.svg'
import { useTranslations } from 'use-intl'

const Symmetry: FC = () => {
  const t = useTranslations()
  const { setPropertyState } = useSceneActions()
  const symmetryX = useSceneState('symmetryX')
  const symmetryY = useSceneState('symmetryY')
  const symmetryZ = useSceneState('symmetryZ')

  const sizePreference = useProjectState('sizePreference')

  function toggleSymmetry(
    key: 'symmetryX' | 'symmetryY' | 'symmetryZ',
    checked: boolean
  ) {
    setPropertyState({
      key,
      value: checked
        ? PrimitiveSymmetryChecked.CHECKED
        : PrimitiveSymmetryChecked.UNCHECKED
    })
  }

  return (
    <>
      <FieldLabel style={{ fontWeight: 700 }}>
        {t('object:properties:repeat:mirror')}
      </FieldLabel>

      <div className="flex gap-xxs">
        <OverlayTrigger placement="top" offset={0}>
          <ActionButton
            size={sizePreference}
            quiet
            selected={!!symmetryX}
            onClick={() => toggleSymmetry('symmetryX', !symmetryX)}
            slot="trigger"
            key="x"
            value="x"
            aria-label="symmetry x">
            <Icon slot="icon">
              <DuplicateMirrorXIcon />
            </Icon>
          </ActionButton>
          <Tooltip slot="hover-content">
            {t('object:properties:repeat:mirrorX')}
          </Tooltip>
        </OverlayTrigger>
        <OverlayTrigger placement="top" offset={0}>
          <ActionButton
            size={sizePreference}
            quiet
            selected={!!symmetryY}
            onClick={() => toggleSymmetry('symmetryY', !symmetryY)}
            slot="trigger"
            key="y"
            value="y"
            aria-label="symmetry y">
            <Icon slot="icon">
              <DuplicateMirrorYIcon />
            </Icon>
          </ActionButton>
          <Tooltip slot="hover-content">
            {t('object:properties:repeat:mirrorY')}
          </Tooltip>
        </OverlayTrigger>
        <OverlayTrigger placement="top" offset={0}>
          <ActionButton
            size={sizePreference}
            quiet
            selected={!!symmetryZ}
            onClick={() => toggleSymmetry('symmetryZ', !symmetryZ)}
            slot="trigger"
            key="z"
            value="z"
            aria-label="symmetry z">
            <Icon slot="icon">
              <DuplicateMirrorZIcon />
            </Icon>
          </ActionButton>
          <Tooltip slot="hover-content">
            {t('object:properties:repeat:mirrorZ')}
          </Tooltip>
        </OverlayTrigger>
      </div>
    </>
  )
}

export default memo(Symmetry)
