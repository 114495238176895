import React, { FC, memo } from 'react'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { EngineCommitChange } from '@services/engine/types'
import { PropertyInputSlider } from '@components/slider/FirstAndSubsequentInputSlider'
import { ElementSize } from '@store/slices/projectSlice'
import { useTranslations } from 'use-intl'

interface Props {
  size: ElementSize
}

const Lights: FC<Props> = ({ size }) => {
  const t = useTranslations()
  const lightAngle1 = useSceneState('lightAngle1')
  const lightAngle2 = useSceneState('lightAngle2')
  const lightOcclusionDistance = useSceneState('lightOcclusionDistance')
  const { setPropertyState } = useSceneActions()

  return (
    <>
      <PropertyInputSlider
        size={size}
        variant="filled"
        min={0}
        max={360}
        step={0.1}
        aria-label={t('scene:styles:environment:lightAngle1')}
        label={t('scene:styles:environment:lightAngle1')}
        value={lightAngle1}
        onInput={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            key: 'lightAngle1',
            value
          })
        }}
        onMouseDown={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            key: 'lightAngle1',
            value,
            commit: EngineCommitChange.BEGIN_COMMIT
          })
        }}
        onMouseUp={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            key: 'lightAngle1',
            value,
            commit: EngineCommitChange.END_COMMIT
          })
        }}
        editable
        hideStepper
      />
      <PropertyInputSlider
        size={size}
        variant="filled"
        min={0}
        max={90}
        step={0.1}
        aria-label={t('scene:styles:environment:lightAngle2')}
        label={t('scene:styles:environment:lightAngle2')}
        value={lightAngle2}
        onInput={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            key: 'lightAngle2',
            value
          })
        }}
        onMouseDown={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            key: 'lightAngle2',
            value,
            commit: EngineCommitChange.BEGIN_COMMIT
          })
        }}
        onMouseUp={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            key: 'lightAngle2',
            value,
            commit: EngineCommitChange.END_COMMIT
          })
        }}
        editable
        hideStepper
      />
      <PropertyInputSlider
        size={size}
        variant="filled"
        min={0}
        max={0.1}
        step={0.001}
        aria-label={t('scene:styles:environment:lightOcclusionDistance')}
        label={t('scene:styles:environment:lightOcclusionDistance')}
        value={lightOcclusionDistance}
        onInput={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            key: 'lightOcclusionDistance',
            value
          })
        }}
        onMouseDown={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            key: 'lightOcclusionDistance',
            value,
            commit: EngineCommitChange.BEGIN_COMMIT
          })
        }}
        onMouseUp={e => {
          const value = (e as unknown as { target: { value: number } }).target
            .value
          setPropertyState({
            key: 'lightOcclusionDistance',
            value,
            commit: EngineCommitChange.END_COMMIT
          })
        }}
        editable
        hideStepper
      />
    </>
  )
}

export default memo(
  Lights,
  (prevProps, nextProps) => prevProps.size === nextProps.size
)
