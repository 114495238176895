import React, { FC } from 'react'
import {
  useMediumMinimumSizePreference,
  useProjectState
} from '@hooks/useProject'
import {
  ActionButton,
  OverlayTrigger,
  ProgressCircle,
  StatusLight,
  Tooltip
} from 'ui'
import {
  SpIconCloud,
  SpIconCloudDisconnected,
  SpIconCloudError
} from 'workflow-icons'
import { useAuth } from '@hooks/useAuth'
import { useFormatter, useTranslations } from 'use-intl'

const DocumentSnapshotStatusIndicator: FC = () => {
  const t = useTranslations()
  const formatter = useFormatter()
  const isFeatured = useProjectState('isFeatured')
  const documentChannelStatus = useProjectState('documentChannelStatus')
  const documentSnapshotSuccess = useProjectState('documentSnapshotSuccess')
  const documentSnapshotTimestamp = useProjectState('documentSnapshotTimestamp')
  const ownerUserUuid = useProjectState('ownerUserUuid')
  const size = useMediumMinimumSizePreference()

  const { localUser, status } = useAuth()

  const isProjectOwner = localUser?.uuid === ownerUserUuid

  const lastSavedTime = formatter.dateTime(documentSnapshotTimestamp, {
    hour: 'numeric',
    minute: 'numeric'
  })

  const getConnStatus = (): {
    icon: JSX.Element
    tooltipHeader: string
    status: 'info' | 'notice' | 'positive' | 'negative'
  } => {
    if (ownerUserUuid !== null && localUser !== null && isFeatured) {
      return {
        icon: <SpIconCloudError slot="icon" />,
        tooltipHeader: t('studio:connection:duplicateProjectToSave'),
        status: 'notice'
      }
    }

    if (ownerUserUuid !== null && localUser !== null && !isProjectOwner) {
      return {
        icon: <SpIconCloudError slot="icon" />,
        tooltipHeader: t('studio:connection:communityProject'),
        status: 'notice'
      }
    }
    switch (documentChannelStatus) {
      case null:
        return {
          icon: <ProgressCircle size="s" indeterminate label="Connecting" />,
          tooltipHeader: t('studio:connection:connecting'),
          status: 'info'
        }
      case true:
        return !documentSnapshotSuccess
          ? {
              icon: <SpIconCloudError slot="icon" />,
              tooltipHeader: t('studio:connection:saveError'),
              status: 'notice'
            }
          : {
              icon: <SpIconCloud slot="icon" />,
              tooltipHeader: t('studio:connection:connected'),
              status: 'positive'
            }
      case false:
        return {
          icon: <SpIconCloudDisconnected slot="icon" />,
          tooltipHeader: t('studio:connection:disconnected'),
          status: 'negative'
        }
    }
  }

  const connectionState = getConnStatus()

  if (status === 'CHECKING_STATUS' || status === 'ERROR_CHECKING_STATUS') {
    return null
  }

  return (
    <>
      <OverlayTrigger offset={0} placement="bottom">
        <ActionButton
          slot="trigger"
          aria-label={t('studio:connection:status')}
          quiet
          size={size}>
          {connectionState.icon}
        </ActionButton>

        <Tooltip slot="hover-content">
          <p
            style={{
              margin: 0
            }}>
            {connectionState.tooltipHeader}
          </p>
          {isProjectOwner &&
            !isFeatured &&
            t('studio:connection:lastSaved', { time: lastSavedTime })}
        </Tooltip>
      </OverlayTrigger>
      <StatusLight
        size={size}
        hidden={connectionState.status === 'info'}
        variant={connectionState.status}
      />
    </>
  )
}

export default DocumentSnapshotStatusIndicator
