import React from 'react'
import { ActionButton, Icon, ProgressBar } from 'ui'
import VideoPreviewPlay from '/public/video-preview-play.svg'
import VideoPreviewStop from '/public/s2_icon_visibility_off.svg'
import {
  MediaIO,
  VideoRecordingState,
  useVideoRecordingProgress,
  useVideoRecordingState
} from '@services/engine/MediaIO'
import { useProjectState } from '@hooks/useProject'
import { useTranslations } from 'use-intl'

function VideoPreviewButton({
  onPreviewClick
}: {
  onPreviewClick: () => void
}) {
  const t = useTranslations()
  const sizePreference = useProjectState('sizePreference')
  const { cancelCurrentVideoEncodingOrPreview } = MediaIO
  const videoRecordingState = useVideoRecordingState()
  const videoRecordingProgress = useVideoRecordingProgress()

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
      }}>
      <ActionButton
        size={sizePreference}
        onBlur={
          videoRecordingState === VideoRecordingState.previewing
            ? cancelCurrentVideoEncodingOrPreview
            : () => null
        }
        onClick={
          videoRecordingState === VideoRecordingState.previewing
            ? cancelCurrentVideoEncodingOrPreview
            : onPreviewClick
        }
        aria-label="preview video">
        <Icon slot="icon" className="icon-m">
          {videoRecordingState === VideoRecordingState.previewing ? (
            <VideoPreviewStop />
          ) : (
            <VideoPreviewPlay />
          )}
        </Icon>
        {videoRecordingState === VideoRecordingState.previewing
          ? t('studio:downloadMenu:mp4:stopPreviewButton')
          : t('studio:downloadMenu:mp4:startPreviewButton')}
      </ActionButton>
      {videoRecordingState === VideoRecordingState.previewing && (
        <ProgressBar
          style={{
            margin: 0,
            width: '100%',
            height: 10,
            position: 'absolute',
            bottom: -14
          }}
          size="xxs"
          progress={videoRecordingProgress * 100}
        />
      )}
    </div>
  )
}

export default VideoPreviewButton
