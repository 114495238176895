import {
  DefaultStudioEnvironmentKey,
  SceneToImageStudioEnvironmentKey
} from '@constants/appConstants'
import { useProjectState } from './useProject'

export const useStudioEnvironment = () => {
  const studioEnvironment = useProjectState('studioEnvironment')

  return {
    ...studioEnvironment,
    isDefaultEnvironment:
      studioEnvironment?.environment === DefaultStudioEnvironmentKey,
    isSceneToImageEnvironment:
      studioEnvironment?.environment === SceneToImageStudioEnvironmentKey
  }
}
