import React, { useEffect } from 'react'
import { UNAV_ELEMENT_ID } from '@services/universalNav/config'
import { useAuthState } from '@hooks/useAuth'
import { useDispatch } from 'react-redux'
import { loadUniversalNav } from '@store/middleware/universalNav/UniversalNavSaga'

const UniversalNavContainer = () => {
  const authStatus = useAuthState('status')
  const localUser = useAuthState('localUser')
  const dispatch = useDispatch()

  useEffect(() => {
    // Ensure user is authenticated and nav container element is mounted to DOM
    if (
      authStatus === 'AUTHENTICATED' &&
      localUser &&
      document?.getElementById(UNAV_ELEMENT_ID)
    ) {
      dispatch(loadUniversalNav())
    }
  }, [authStatus, localUser])

  return <div id={UNAV_ELEMENT_ID} />
}

export default UniversalNavContainer
