import React, { FC, memo } from 'react'
import {
  SceneStateProperties,
  useSceneActions,
  useSceneState
} from '@hooks/useScene'
import { EngineBlendType } from '@services/engine/types'
import { ActionButton, ActionGroup, Checkbox, FieldLabel } from 'ui'
import PanelAccordion from '@components/panel/PanelAccordion'
import { useProjectState, useProjectActions } from '@hooks/useProject'
import { Section } from './ObjectProperties'
import { PercentPropertySlider } from '@components/slider/PercentPropertySlider'
import { useStudioEnvironment } from '@hooks/useStudioEnvironment'
import { NeoDiscoverButton } from '@components/neoDiscoverButton/NeoDiscoverButton'
import { ToNeoHoverCard } from '@components/toNeo/ToNeoHoverCard'
import { useTranslations } from 'use-intl'

const Combine: FC = () => {
  const t = useTranslations()
  const threeDCombineCollapsed = useProjectState('threeDCombineCollapsed')
  const sizePreference = useProjectState('sizePreference')
  const { setPanelAccordionItem } = useProjectActions()
  const blendType = useSceneState('blendType')
  const address = useSceneState('address')
  const drawMaterial = useSceneState('drawMaterial')
  const showUseMaterialCheckbox =
    blendType === EngineBlendType.SUBTRACT ||
    blendType === EngineBlendType.INTERSECT
  const { setPropertyState } = useSceneActions()
  const { isSceneToImageEnvironment } = useStudioEnvironment()

  return (
    <PanelAccordion
      id={Section.combine}
      label={t('object:properties:combine')}
      collapsed={threeDCombineCollapsed}
      onChange={collapsed =>
        setPanelAccordionItem({ key: 'threeDCombineCollapsed', collapsed })
      }>
      <FieldLabel>{t('object:properties:additive')}</FieldLabel>
      <ActionGroup
        size={sizePreference}
        selects="single"
        selected={[blendType.toString()]}
        change={e => {
          const keys = (e as unknown as { target: { selected: string[] } })
            .target.selected
          if (!keys.length) return
          setPropertyState({
            address,
            key: 'blendType',
            value: Number(keys[0]) as SceneStateProperties['blendType']
          })
        }}>
        <ActionButton
          key={EngineBlendType.ADD.toString()}
          value={EngineBlendType.ADD.toString()}
          aria-label={t('object:properties:additive:add')}>
          {t('object:properties:additive:add')}
        </ActionButton>

        <ActionButton
          disabled={isSceneToImageEnvironment}
          key={EngineBlendType.COLOR.toString()}
          value={EngineBlendType.COLOR.toString()}
          aria-label={t('object:properties:additive:color')}>
          {t('object:properties:additive:color')}
        </ActionButton>
        <ActionButton
          disabled={isSceneToImageEnvironment}
          key={EngineBlendType.REPEL.toString()}
          value={EngineBlendType.REPEL.toString()}
          aria-label={t('object:properties:additive:repel')}>
          {t('object:properties:additive:repel')}
        </ActionButton>
        <ActionButton
          disabled={isSceneToImageEnvironment}
          key={EngineBlendType.AVOID.toString()}
          value={EngineBlendType.AVOID.toString()}
          aria-label={t('object:properties:additive:avoid')}>
          {t('object:properties:additive:avoid')}
        </ActionButton>
      </ActionGroup>

      <FieldLabel>{t('object:properties:subtractive')}</FieldLabel>
      <ActionGroup
        size={sizePreference}
        selects="single"
        selected={[blendType.toString()]}
        change={e => {
          const keys = (e as unknown as { target: { selected: string[] } })
            .target.selected
          if (!keys.length) return
          setPropertyState({
            address,
            key: 'blendType',
            value: Number(keys[0]) as SceneStateProperties['blendType']
          })
        }}>
        <ActionButton
          disabled={isSceneToImageEnvironment}
          key={EngineBlendType.SUBTRACT.toString()}
          value={EngineBlendType.SUBTRACT.toString()}
          aria-label={t('object:properties:subtractive:carve')}>
          {t('object:properties:subtractive:carve')}
        </ActionButton>
        <ActionButton
          disabled={isSceneToImageEnvironment}
          key={EngineBlendType.INTERSECT.toString()}
          value={EngineBlendType.INTERSECT.toString()}
          aria-label={t('object:properties:subtractive:intersect')}>
          {t('object:properties:subtractive:intersect')}
        </ActionButton>
      </ActionGroup>

      <PercentPropertySlider
        disabled={isSceneToImageEnvironment}
        ariaLabel={t('object:properties:blending')}
        label={t('object:properties:blending')}
        valueKey="blendAmount"
        propertyPath="treeNode.standard.blendShape"
        enableUpperboundOverride
      />
      {isSceneToImageEnvironment && (
        <>
          <NeoDiscoverButton
            data-tracking-event="studio:combinePanel:tryNeo"
            id="combine-panel-to-neo-button">
            {t('object:properties:combineShapes')}
          </NeoDiscoverButton>
          <ToNeoHoverCard
            trigger="combine-panel-to-neo-button@hover"
            placement="left"
          />
        </>
      )}
      {showUseMaterialCheckbox && (
        <div className="flex align-center gap-xs">
          <Checkbox
            id="primitive-draw-material"
            size={sizePreference}
            checked={drawMaterial}
            change={e =>
              setPropertyState({
                address,
                key: 'drawMaterial',
                value: !drawMaterial
              })
            }></Checkbox>
          <FieldLabel for="primitive-draw-material">
            {t('object:properties:useMaterial')}
          </FieldLabel>
        </div>
      )}
    </PanelAccordion>
  )
}

export default memo(Combine)
