import React, { FC, useRef, useState } from 'react'
import { ActionButton, ActionMenu, Icon, MenuItem, Picker, Toast } from 'ui'
import cn from 'classnames'
import styles from '@styles/components/FireflyPreview.module.scss'
import DownloadIcon from '/public/s2_icon_download.svg'
import FilterIcon from '/public/s2_icon_filters.svg'
import AiGenerateIcon from '/public/s2_icon_ai_generate.svg'
import TextToImageIcon from '/public/s2_icon_fill_text_to_image_all_product.svg'
import { saveAs } from 'file-saver'
import FireflyImageSkeleton from '@components/fireflyPanel/FireflyImageSkeleton'
import { useFirefly } from '@hooks/useFirefly'
import { SpIconMore } from 'workflow-icons'
import { AspectRatio } from '@store/slices/fireflySlice'
import { useMediumMinimumSizePreference } from '@hooks/useProject'
import PasteIcon from '/public/s2_icon_paste.svg'
import { useTranslations } from 'use-intl'

const MenuItems = [
  {
    icon: <AiGenerateIcon />,
    label: 'studio:firefly:image:generateSimilar',
    key: 'generate-similar'
  },
  {
    icon: <FilterIcon />,
    label: 'studio:firefly:image:styleReference',
    key: 'style-reference'
  }
] as const

const FireflyPreview: FC = () => {
  const [showImageCopiedToast, setShowImageCopiedToast] =
    useState<boolean>(false)
  const t = useTranslations()
  const size = useMediumMinimumSizePreference()
  const generatedImgRef = useRef<HTMLImageElement>(null)

  const {
    aspectRatio,
    imageGenerationStatus,
    generateSimilarImages,
    selectedGeneratedImage,
    setPropertyState,
    addDownloadURL,
    downloadURLs
  } = useFirefly()

  async function downloadImage() {
    if (!selectedGeneratedImage) return

    let downloadURL = downloadURLs.find(
      ({ originalURL }) => originalURL === selectedGeneratedImage
    )?.downloadURL

    if (!downloadURL) {
      // Can not use image URL from firefly api to download image because using a different origin will open a new tab instead of downloading (https://html.spec.whatwg.org/multipage/links.html#downloading-resources)
      const res = await fetch(selectedGeneratedImage)
      const blob = await res.blob()
      downloadURL = URL.createObjectURL(blob)

      // Add downloadURL for reference if user decides to download again and to revoke URL when it's not used anymore
      addDownloadURL({ originalURL: selectedGeneratedImage, downloadURL })
    }

    const localizedFilename = t('studio:firefly:image:saveAsFilename')

    saveAs(downloadURL, `${localizedFilename}.png`)
  }

  async function copyImage() {
    if (!generatedImgRef.current) return
    setShowImageCopiedToast(true)

    // I know this is ugly :(. Safari requires that we return a value of type Promise<Blob> for the value of 'image/png' and that no async behaviour occurs before calling navigator.clipboard.write, otherwise it will throw an exception
    navigator.clipboard.write([
      new ClipboardItem({
        'image/png': new Promise(async (resolve, _) => {
          const blob = await fetch(generatedImgRef.current!.src).then(img =>
            img.blob()
          )

          const url = URL.createObjectURL(blob)
          const img = new Image()
          img.src = url

          img.onload = () => {
            const canvas = document.createElement('canvas')
            canvas.width = img.width
            canvas.height = img.height
            canvas.getContext('2d')?.drawImage(img, 0, 0)
            canvas.toBlob(blob => resolve(blob as Blob))
            URL.revokeObjectURL(url)
          }
        })
      })
    ])
  }

  function onMenuClick({
    target: { value }
  }: {
    target: { value: (typeof MenuItems)[number]['key'] }
  }) {
    switch (value) {
      case 'generate-similar':
        return (
          selectedGeneratedImage &&
          generateSimilarImages({ imgUrl: selectedGeneratedImage })
        )
      case 'style-reference':
        return useGeneratedImgAsStyleRef()
      default:
        break
    }
  }

  function useGeneratedImgAsStyleRef() {
    selectedGeneratedImage &&
      setPropertyState({
        key: 'styleImageReference',
        value: { type: 'generated-output', url: selectedGeneratedImage }
      })
  }

  return (
    <div className={styles['container']}>
      <Toast
        className={styles['toast']}
        variant="positive"
        open={showImageCopiedToast}
        timeout={4000}
        close={() => setShowImageCopiedToast(false)}>
        Image copied to clipboard
      </Toast>
      <Picker
        size={size}
        className={cn(
          'picker-min-width',
          'w-auto',
          styles['aspect-ratio-picker']
        )}
        label={t('studio:firefly:image:aspectRatio')}
        quiet
        value={aspectRatio}
        onchange={e => {
          const value = (e as unknown as { target: { value: AspectRatio } })
            .target.value

          setPropertyState({
            key: 'aspectRatio',
            value
          })
        }}>
        <MenuItem key={AspectRatio.LANDSCAPE} value={AspectRatio.LANDSCAPE}>
          {t('studio:firefly:image:landscape')}
        </MenuItem>
        <MenuItem key={AspectRatio.PORTRAIT} value={AspectRatio.PORTRAIT}>
          {t('studio:firefly:image:portrait')}
        </MenuItem>
        <MenuItem key={AspectRatio.SQUARE} value={AspectRatio.SQUARE}>
          {t('studio:firefly:image:square')}
        </MenuItem>
        <MenuItem key={AspectRatio.WIDESCREEN} value={AspectRatio.WIDESCREEN}>
          {t('studio:firefly:image:widescreen')}
        </MenuItem>
      </Picker>

      {imageGenerationStatus === 'generating-new-images' ? (
        <FireflyImageSkeleton />
      ) : selectedGeneratedImage ? (
        <div className={styles['content']}>
          {selectedGeneratedImage && (
            <>
              <div className={styles['image-wrapper']}>
                <img
                  className={styles['image']}
                  src={selectedGeneratedImage}
                  alt={t('studio:firefly:image:altText')}
                  ref={generatedImgRef}
                />
              </div>
              <div className={styles['action-button-wrapper']}>
                <ActionMenu
                  size={size}
                  onchange={onMenuClick}
                  placement="bottom">
                  <SpIconMore slot="icon" />
                  {MenuItems.map(({ key, icon, label }) => (
                    <MenuItem
                      key={key}
                      value={key}
                      data-tracking-event={
                        key === 'generate-similar'
                          ? 'studio:firefly:generateSimilar'
                          : undefined
                      }>
                      <Icon className="icon-m" slot="icon">
                        {icon}
                      </Icon>
                      {t(label)}
                    </MenuItem>
                  ))}
                </ActionMenu>
                <div className="flex gap-xs">
                  <ActionButton size={size} onClick={copyImage}>
                    <Icon className="icon-m" slot="icon">
                      <PasteIcon />
                    </Icon>
                    {t('studio:firefly:image:copy')}
                  </ActionButton>
                  <ActionButton
                    data-tracking-event="studio:firefly:download"
                    size={size}
                    onClick={downloadImage}>
                    <Icon className="icon-m" slot="icon">
                      <DownloadIcon />
                    </Icon>
                    {t('studio:firefly:image:download')}
                  </ActionButton>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className={styles['empty']}>
          <div className={styles['empty__icon-container']}>
            <TextToImageIcon />
          </div>
          <p className={styles['empty__title']}>
            {t('studio:firefly:image:empty:title')}
          </p>
          <p className={styles['empty__body-text']}>
            {t('studio:firefly:image:empty:body')}
          </p>
        </div>
      )}
    </div>
  )
}

export default FireflyPreview
