import React, { FC } from 'react'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { ActionButton, Icon, OverlayTrigger, Tooltip } from 'ui'
import UserAvatarIcon from '/public/s2_icon_user_avatar.svg'
import { useMediumMinimumSizePreference } from '@hooks/useProject'
import { useTranslations } from 'use-intl'

const AvatarsVisibilityToggle: FC = () => {
  const t = useTranslations()
  const size = useMediumMinimumSizePreference()
  const showAvatars = useSceneState('showAvatars')
  const { setPropertyState } = useSceneActions()

  return (
    <OverlayTrigger offset={0} placement="bottom">
      <ActionButton
        size={size}
        slot="trigger"
        aria-label="Show/Hide Remote user avatars"
        quiet
        onclick={() => {
          setPropertyState({
            key: 'showAvatars',
            value: !showAvatars
          })
          document.getElementById('canvas')?.focus()
        }}>
        <Icon slot="icon">
          <UserAvatarIcon />
        </Icon>
      </ActionButton>
      <Tooltip slot="hover-content">
        {showAvatars
          ? t('studio:tooltips:hideUserAvatars')
          : t('studio:tooltips:showUserAvatars')}
      </Tooltip>
    </OverlayTrigger>
  )
}

export default AvatarsVisibilityToggle
