import { EventEmitter } from 'events'
import { APP_VERSION, UNAV_ELEMENT_ID, UNAV_URL, UNAV_VERSION } from './config'
import {
  UniversalNavConfig,
  UniversalNavChild,
  EventPayload,
  MenuMessageEventDetail,
  UserProfile
} from './types'
import { ColorKey } from '@store/slices/projectSlice'

const ACCOUNT_MENU_TEST_ID = 'user-avatar-account-icon'

/**
 *
 * Service to load Universal Nav component
 * https://wiki.corp.adobe.com/display/CCHome/Universal+Nav
 *
 */
export class UniversalNav {
  public EventChannel: EventEmitter = new EventEmitter()
  // This is used to keep track of the current nav promise
  // until it is done before reloading if it is still pending
  private currentNavPromise: Promise<void> | null = null

  private targetId = UNAV_ELEMENT_ID

  public async load(
    accessToken: string,
    userProfile: UserProfile,
    themeColor: ColorKey
  ) {
    const targetEl = document.getElementById(this.targetId)

    if (!targetEl) {
      throw new Error(`Element with id ${this.targetId} not found`)
    }

    const config = this.getUniversalNavConfig(
      targetEl,
      accessToken,
      userProfile,
      themeColor,
      'en_US', // TODO update to change locale

      {
        'enable-buy-credits': true,
        'enable-credits-request-access': true
      }
    )

    // Check if UniversalNav is already loaded. If it is, just reload it
    if (window.UniversalNav) {
      // If reload is not available, wait for the current nav promise to resolve
      /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition  -- This will be undefined if reload is not available */
      if (!window.UniversalNav.reload) {
        await this.currentNavPromise
        return
      }

      await window.UniversalNav.reload(config)
      return
    }

    await Promise.all([this.fetchJS(), this.fetchCss()]).then(async () => {
      if (window.UniversalNav) {
        this.currentNavPromise = window.UniversalNav(config)
        await this.currentNavPromise
      }
    })
  }

  public async changeTheme(theme: ColorKey) {
    if (!window.UniversalNav) return

    if (this.currentNavPromise) {
      await this.currentNavPromise
      window.UniversalNav.changeTheme(theme)
    }
  }

  private fetchJS() {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.src = UNAV_URL.js(UNAV_VERSION)
      script.async = true
      script.onload = resolve
      script.onerror = reject
      document.body.appendChild(script)
    })
  }

  private fetchCss() {
    return new Promise((resolve, reject) => {
      const css = document.createElement('link')
      css.rel = 'stylesheet'
      css.type = 'text/css'
      css.href = UNAV_URL.css(UNAV_VERSION)
      // @ts-expect-error - async exists
      css.async = true

      css.onload = resolve
      css.onerror = reject
      const head = document.getElementsByTagName('head')[0]
      head.appendChild(css)
    })
  }

  private getUniversalNavConfig(
    targetEl: HTMLElement,
    accessToken: string,
    userProfile: UserProfile | null,
    themeColor: string,
    lang: string,
    initialAccountMenuAttributes?: Record<string, boolean | string | null>
  ): UniversalNavConfig {
    const children = this.getChildren(
      accessToken,
      userProfile,
      themeColor,
      initialAccountMenuAttributes
    )

    // TODO generate guid for analytics
    const guid = ''

    const config: UniversalNavConfig = {
      accountType: userProfile?.accountType,
      target: targetEl,
      env:
        process.env.NEXT_PUBLIC_CLIENT_APP_ENVIRONMENT === 'prd'
          ? 'prod'
          : 'stage',
      theme: themeColor,
      locale: lang,
      children,
      accessToken: accessToken,
      imsClientId: process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID!,
      userId: userProfile?.userId,
      authId: userProfile?.userId,
      email: userProfile?.email,
      isSectionDividerRequired: false,
      analyticsContext: {
        // TODO pass correct device info
        consumer: {
          name: 'ProjectNeo',
          version: APP_VERSION,
          platform: 'Web',
          device: 'macOS',
          os_version: 'Catalina'
        },
        event: {
          visitor_guid: guid
        }
      }
    }

    return config
  }

  private getChildren(
    accessToken: string,
    userProfile: UserProfile | null,
    themeColor: string,
    initialAccountMenuAttributes?: Record<string, boolean | string | null>
  ): Array<UniversalNavChild> {
    return [
      {
        name: 'app-switcher',
        attributes: {
          crossOrigin: 'anonymous'
        }
      },
      {
        name: 'profile',
        attributes: {
          crossOrigin: 'anonymous',
          accountMenuTriggerAttributes: {
            'data-testid': ACCOUNT_MENU_TEST_ID,
            ...initialAccountMenuAttributes
          },
          callbacks: {
            // applicable for logged-out mode only
            onSignIn: () => {
              console.log('onSignIn')
            }
          },
          isSignUpRequired: false, // applicable for logged-out mode only
          componentLoaderConfig: {
            config: this.getAccountMenuConfig(
              accessToken,
              userProfile,
              themeColor
            )
          },
          messageEventListener: async (event: {
            detail: MenuMessageEventDetail
          }) => {
            const { payload } = event.detail
            if (payload.data?.key && payload.data.value?.eventName) {
              this.EventChannel.emit('event', {
                type: 'account-menu',
                payload: { event: payload.data.value.eventName }
              } as EventPayload)
            }
          }
        }
      }
    ]
  }

  private getAccountMenuConfig(
    accessToken: string,
    userProfile: UserProfile | null,
    themeColor: string
  ) {
    return {
      preloadAccountMenu: true,
      getAccessToken: () => Promise.resolve(accessToken),
      getProfile: () => Promise.resolve(userProfile),
      imsClientId: process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID,
      enableLocalSection: true,
      enableProfileSwitcher: true,
      userId: userProfile?.userId,
      miniAppContext: {
        colorScheme: themeColor,
        logger: {
          trace: () => null,
          debug: () => null,
          info: () => null,
          warn: () => null,
          error: () => null
        }
      }
    }
  }
}
