import React, { FC, SVGProps, useRef, useState } from 'react'
import Symmetry from '@components/propertiesPanel/Symmetry'
import Combine from '@components/propertiesPanel/Combine'
import Repeat from '@components/propertiesPanel/Repeat'
import Transform from '@components/propertiesPanel/Transform'
import VolumeShaping from '@components/propertiesPanel/VolumeShaping'
import Material from '@components/propertiesPanel/Material'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import {
  ElementIconId,
  EngineSelectedSceneNode,
  ScenePrimType
} from '@services/engine/types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ActionButton, ActionGroup, Divider } from 'ui'
import { useProjectActions, useProjectState } from '@hooks/useProject'
import styles from '@styles/components/ObjectProperties.module.scss'
import { ElementSize } from '@store/slices/projectSlice'
import PanelHeader from '@components/panel/PanelHeader'
import InlineAlert from '@components/inlineAlert/InlineAlert'
import PanelAccordion from '@components/panel/PanelAccordion'
import { UIElementIdIconMap } from '@components/SceneLayers/SortableElement'
import Text from './Text'
import { useDoubleClick } from '@hooks/useDoubleClick'
import Textfield from '@components/textfield/Textfield'
import { useStudioEnvironment } from '@hooks/useStudioEnvironment'
import { useTranslations } from 'use-intl'

export const Section = {
  appearance: 'object-properties-appearance',
  text: 'object-properties-text',
  combine: 'object-properties-combine',
  shape: 'object-properties-shape',
  transform: 'object-properties-transform',
  repeat: 'object-properties-repeat'
} as const

type SectionKey = keyof typeof Section
type SectionValue = (typeof Section)[SectionKey]

const ScrollAnchorButtons: {
  label: string
  scrollElementTarget: SectionValue
}[] = [
  {
    label: 'object:properties:appearance',
    scrollElementTarget: Section.appearance
  },
  { label: 'object:properties:text', scrollElementTarget: Section.text },
  { label: 'object:properties:combine', scrollElementTarget: Section.combine },
  { label: 'object:properties:shape', scrollElementTarget: Section.shape },
  {
    label: 'object:properties:transform',
    scrollElementTarget: Section.transform
  },
  { label: 'object:properties:repeat', scrollElementTarget: Section.repeat }
]

const ObjectProperties: FC = () => {
  const t = useTranslations()
  const flags = useFlags()

  const scrollContainer = useRef<HTMLDivElement>(null)

  const threeDRepeatCollapsed = useProjectState('threeDRepeatCollapsed')
  const sizePreference = useProjectState('sizePreference')
  const { setPanelAccordionItem } = useProjectActions()

  const selectedSceneNode = useSceneState('selectedSceneNode')
  const elementName = useSceneState('elementName')
  const elements = useSceneState('elements')
  const primitiveType = useSceneState('primitiveType')
  const { isDefaultEnvironment } = useStudioEnvironment()

  const selectedElement = elements.find(el => el.selected)

  const scrollActionButtonSize: ElementSize = sizePreference === 'l' ? 'm' : 's'

  const { setPropertyState } = useSceneActions()

  const showColorsTab = selectedSceneNode !== EngineSelectedSceneNode.GROUP

  const SelectedElementIcon: FC<SVGProps<SVGSVGElement>> | undefined =
    typeof selectedElement?.iconID === 'number' &&
    UIElementIdIconMap[selectedElement.iconID]

  const showTransformTextInput =
    flags['base-pf-ui-text-object'] && primitiveType === ScenePrimType.TEXT

  const [showObjectRenameInput, setShowObjectRenameInput] = useState(false)

  const [renameInputValue, setRenameInputValue] = useState(elementName)

  const handleObjectNameClick = useDoubleClick(() => {
    setShowObjectRenameInput(true)
    setRenameInputValue(elementName)
  })

  function handleRenameChange() {
    setShowObjectRenameInput(false)

    if (renameInputValue && renameInputValue !== elementName) {
      setPropertyState({
        key: 'elementName',
        value: renameInputValue
      })
    }
  }

  function scrollToSection(section: SectionValue) {
    document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' })
  }

  if (selectedSceneNode === EngineSelectedSceneNode.MULTI_SELECTED) {
    return (
      <>
        <PanelHeader textAlign="center">{t('object:properties')}</PanelHeader>
        <div className={styles['multi-selected-description']}>
          <InlineAlert>
            {t('object:properties:multipleObjectsAlert')}
          </InlineAlert>
        </div>
      </>
    )
  }
  return (
    <>
      <PanelHeader textAlign="center">{t('object:properties')}</PanelHeader>

      {isDefaultEnvironment && (
        <div className={styles['layer-info']}>
          <div className={styles['layer-info-object']}>
            {SelectedElementIcon && (
              <SelectedElementIcon
                style={{
                  ...(selectedElement?.iconID !== ElementIconId.GROUP &&
                    selectedElement && {
                      color:
                        '#' +
                        selectedElement.color.toString(16).padStart(6, '0')
                    })
                }}
              />
            )}
          </div>

          {showObjectRenameInput && isDefaultEnvironment ? (
            <Textfield
              autoFocus
              maxLength={15}
              value={renameInputValue}
              onInput={e => setRenameInputValue(e.currentTarget.value)}
              onChange={handleRenameChange}
              onEscape={() => setShowObjectRenameInput(false)}
            />
          ) : (
            <h6
              onClick={
                flags['base-pf-ui-object-rename-property-panel']
                  ? handleObjectNameClick
                  : undefined
              }>
              {elementName}
            </h6>
          )}
        </div>
      )}

      {isDefaultEnvironment && (
        <ActionGroup className="tabs-container">
          {ScrollAnchorButtons.filter(({ scrollElementTarget }) => {
            switch (scrollElementTarget) {
              case Section.appearance:
                return showColorsTab
              case Section.text:
                return showTransformTextInput
              default:
                return true
            }
          }).map(({ label, scrollElementTarget }) => (
            <ActionButton
              key={scrollElementTarget}
              size={scrollActionButtonSize}
              onClick={() => scrollToSection(scrollElementTarget)}>
              {t(label)}
            </ActionButton>
          ))}
        </ActionGroup>
      )}

      <div className="tab-panel">
        <div className="tab-panel-content" ref={scrollContainer}>
          <Material />
          {showTransformTextInput && <Text />}
          <Combine />
          {isDefaultEnvironment && <VolumeShaping />}
          <Transform />
          {isDefaultEnvironment && (
            <PanelAccordion
              id={Section.repeat}
              label={t('object:properties:repeat')}
              collapsed={threeDRepeatCollapsed}
              onChange={collapsed =>
                setPanelAccordionItem({
                  key: 'threeDRepeatCollapsed',
                  collapsed
                })
              }>
              <Repeat />
              <Divider size="s" />
              <Symmetry />
            </PanelAccordion>
          )}
        </div>
      </div>
    </>
  )
}

export default ObjectProperties
