import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import cn from 'classnames'
import { Button } from 'ui'
import styles from '@styles/components/FloatingEditShapeToggle.module.scss'
import { useUISettings } from '@services/engine/useUISettings'
import { useSceneState } from '@hooks/useScene'
import { EngineSelectedSceneNode } from '@services/engine/types'
import { useTranslations } from 'use-intl'

const ButtonWidth = 72
const ButtonHeight = 32

const FloatingEditShapeToggle = () => {
  const t = useTranslations()
  const flags = useFlags()

  const { shapeEditionMode } = useUISettings()
  const [getShapeEditionMode, setShapeEditionMode] = shapeEditionMode()
  const isShapeEditionMode = getShapeEditionMode() ?? false
  const selectedSceneNode = useSceneState('selectedSceneNode')

  const {
    status,
    editButtonGizmoPosition: { centerX, centerY }
  } = useSceneState('selectedObjectUI')

  function toggleShapeEditionMode() {
    setShapeEditionMode(!isShapeEditionMode)
  }

  const left = centerX - ButtonWidth / 2
  const top = centerY - ButtonHeight / 2 - 140

  if (status !== -1 || selectedSceneNode !== EngineSelectedSceneNode.SHAPE) {
    return null
  }
  return isShapeEditionMode ? (
    <Button
      treatment={
        flags['base-tf-ui-floating-edit-shape-button-treatment-fill'] // TODO remove after testing which style to use
          ? 'fill'
          : 'outline'
      }
      className={cn(styles['btn'], styles['done-btn'], {
        [styles['btn-treatment-filled']]:
          flags['base-tf-ui-floating-edit-shape-button-treatment-fill']
      })}
      onClick={toggleShapeEditionMode}>
      {t('studio:contextBar:done')}
    </Button>
  ) : (
    <Button
      treatment={
        flags['base-tf-ui-floating-edit-shape-button-treatment-fill'] // TODO remove after testing which style to use
          ? 'fill'
          : 'outline'
      }
      className={cn(styles['btn'], {
        [styles['btn-treatment-filled']]:
          flags['base-tf-ui-floating-edit-shape-button-treatment-fill']
      })}
      style={{ top, left }}
      onClick={toggleShapeEditionMode}>
      {t('studio:contextBar:edit')}
    </Button>
  )
}

export default FloatingEditShapeToggle
