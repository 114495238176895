import { FC, useEffect } from 'react'
import { useProjectActions, useProjectState } from '@hooks/useProject'
import styles from '@styles/components/ToNeoLoadingScreen.module.scss'
import cn from 'classnames'
import { useTranslations } from 'use-intl'

export const ToNeoLoadingScreen: FC = () => {
  const directToNeoProperStatus = useProjectState('directToNeoProperStatus')
  const { setDirectToNeoProperStatus } = useProjectActions()
  const t = useTranslations()

  useEffect(() => {
    if (directToNeoProperStatus === 'COMPLETE')
      // 500ms is how long it takes for the animation to complete. So set to null after the animation completes
      setTimeout(() => setDirectToNeoProperStatus(null), 500)
  }, [directToNeoProperStatus])

  if (!directToNeoProperStatus) return null

  return (
    <>
      <div
        className={cn(styles['container'], {
          [styles['container--fade-in']]:
            directToNeoProperStatus === 'DIRECTING',
          [styles['container--fade-out']]:
            directToNeoProperStatus === 'COMPLETE'
        })}>
        <img
          className={styles['loading-image']}
          src="/firefly/module/to-neo-loading-image/to-neo-loading-image.png"
        />
        <h2 className={cn(styles['text'], styles['heading'])}>
          {t('studio:toNeoLoadingScreen:heading')}
        </h2>
        <p className={cn(styles['text'], styles['body-text'])}>
          {t('studio:toNeoLoadingScreen:body')}
        </p>
      </div>
    </>
  )
}
