import {
  dispatchToLDContextUpdater,
  ldContextSuccessfullyUpdated,
  setLDInitialized,
  updateLDContext
} from '@store/slices/launchDarklySlice'
import { RootState } from '@store/store'
import { LDContext } from 'launchdarkly-js-client-sdk'
import {
  actionChannel,
  ActionChannelEffect,
  call,
  fork,
  put,
  select,
  take
} from 'redux-saga/effects'
import { setIsAuthenticationStateLoaded } from '@store/slices/authSlice'
import { User } from '@store/graphql/__generated__/schema'

function* waitForLDInitialization() {
  const isLDInitialized: boolean = yield select(
    (state: RootState) => state.launchDarkly.initialized
  )

  if (!isLDInitialized) yield take(setLDInitialized)
}

function* waitForUserAuthentication() {
  const isAuthenticationStateLoaded: boolean = yield select(
    (state: RootState) => state.auth.isAuthenticationStateLoaded
  )

  if (!isAuthenticationStateLoaded)
    yield take(setIsAuthenticationStateLoaded.type)
}

function* handleLaunchDarklyContextUpdate(context: LDContext) {
  const localUser: User = yield select(
    (state: RootState) => state.auth.localUser
  )

  yield put(
    dispatchToLDContextUpdater({
      ...context,
      email: localUser.adobeUserEmail as string,
      key: localUser.adobeUserEmail as string
    })
  )
}

function* watchLDContextUpdateRequests() {
  const channel: ActionChannelEffect = yield actionChannel(updateLDContext.type)
  while (true) {
    const { payload } = yield take(channel as any)
    yield call(waitForLDInitialization)
    yield call(waitForUserAuthentication)
    yield call(handleLaunchDarklyContextUpdate, payload)

    // make sure to wait for LD to finish updating its context before processing any more update requests
    yield take(ldContextSuccessfullyUpdated.type)
  }
}

export function* launchDarklySaga() {
  yield fork(watchLDContextUpdateRequests)
}
