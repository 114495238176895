import React, { useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import cn from 'classnames'
import {
  ActionButton,
  Icon,
  Overlay,
  OverlayCloseEvent,
  Popover,
  Tooltip
} from 'ui'
import styles from '@styles/components/StylesPicker.module.scss'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import {
  useMediumMinimumSizePreference,
  useProjectActions
} from '@hooks/useProject'
import {
  getEngineModeLabelMap,
  EngineModeImgMap,
  Styles
} from '@components/styles/StylesActionMenu'
import { EngineMode } from '@services/engine/types'
import EditIcon from '/public/s2_icon_edit.svg'
import { useTranslations } from 'use-intl'

const StylesPicker = () => {
  const t = useTranslations()
  const engineModeLabelMap = getEngineModeLabelMap(t)
  const flags = useFlags()

  const size = useMediumMinimumSizePreference()

  const mode = useSceneState('mode')

  const { setPropertyState } = useSceneActions()

  const { setOpenedPanel, setShowStylesMenuPopover } = useProjectActions()

  const [isOpen, setIsOpen] = useState(false)

  const enabledStyles = Styles.filter(style => {
    if (
      style === EngineMode.ILLUSTRATIVE &&
      !flags['base-tf-fx-illustrative-style']
    ) {
      return null
    } else {
      return style
    }
  })

  function handleSelectStyle(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: EngineMode
  ) {
    e.currentTarget.dispatchEvent(OverlayCloseEvent)
    setPropertyState({
      key: 'mode',
      value
    })
  }

  function handleMoreStylesClick(
    e: React.MouseEvent<typeof ActionButton, MouseEvent>
  ) {
    e.currentTarget.dispatchEvent(OverlayCloseEvent)

    if (flags['base-pf-ui-styles-floating-menu']) {
      return setShowStylesMenuPopover(true)
    }

    if (flags['base-pf-ui-styles-panel']) {
      return setOpenedPanel('style')
    }
  }

  return (
    <>
      <ActionButton
        size={size}
        id="context-bar-styles-picker"
        className={styles['trigger']}
        holdAffordance
        selected={isOpen}
        slot="trigger">
        <div className={styles['trigger-label']}>
          <img
            className={styles['thumbnail']}
            src={EngineModeImgMap[mode]}
            slot="icon"
          />
          {t('scene:styles:changeStyle')}
        </div>
        <Tooltip selfManaged placement="bottom">
          {t('studio:tooltips:styles')}
        </Tooltip>
      </ActionButton>

      <Overlay
        spOpened={() => setIsOpen(true)}
        spClosed={() => setIsOpen(false)}
        placement="top"
        offset={10}
        trigger="context-bar-styles-picker@click">
        <Popover className={styles['popover']}>
          {enabledStyles.map(style => (
            <div
              key={style}
              className={cn(styles['menu-item'], {
                [styles['selected']]: style === mode
              })}
              onClick={e => handleSelectStyle(e, style)}>
              <img src={EngineModeImgMap[style]} />
              {engineModeLabelMap[style]}
            </div>
          ))}

          {(flags['base-pf-ui-styles-floating-menu'] ||
            flags['base-pf-ui-styles-panel']) && (
            <ActionButton quiet onClick={handleMoreStylesClick}>
              <Icon slot="icon">
                <EditIcon />
              </Icon>
              {t('scene:styles:edit')}
            </ActionButton>
          )}
        </Popover>
      </Overlay>
    </>
  )
}

export default StylesPicker
