import React, {
  ComponentProps,
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react'
import cn from 'classnames'
import { Button, Icon, Theme } from 'ui'
import ArrowLeftIcon from '/public/s2_icon_arrow_left.svg'
import ArrowRightIcon from '/public/s2_icon_arrow_right.svg'
import styles from '@styles/components/CarouselProjects.module.scss'

interface Props {
  cards: ReactNode
  color?: ComponentProps<typeof Theme>['color']
  className?: string
  gap?: number
  autoScroll?: boolean
  autoScrollSpeed?: number
  autoScrollInterval?: number
  buttonScrollAmount?: number
}

const CarouselWrapper: FC<Props> = ({
  cards,
  color = 'dark',
  className,
  gap,
  autoScroll = false,
  autoScrollSpeed = 1,
  autoScrollInterval = 15,
  buttonScrollAmount = 300
}) => {
  const [showLeftButton, setShowLeftButton] = useState(false)
  const [showRightButton, setShowRightButton] = useState(true)
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)
  const intervalRef = useRef<number | null>(null)

  const cardArray = React.Children.toArray(cards)
  const infiniteCards = autoScroll
    ? [...cardArray, ...cardArray, ...cardArray].map((c, i) =>
        React.cloneElement(c, { key: 'carousel-internal-card-' + i })
      )
    : cardArray

  const autoScrollIncrement = autoScrollSpeed

  useEffect(() => {
    if (!autoScroll) return

    const scrollContainer = scrollContainerRef.current
    if (!scrollContainer) return

    intervalRef.current = window.setInterval(() => {
      if (scrollContainer.scrollLeft >= (scrollContainer.scrollWidth / 3) * 2) {
        scrollContainer.scrollLeft = scrollContainer.scrollWidth / 3
      } else {
        scrollContainer.scrollLeft += autoScrollIncrement
      }
      updateScrollIndicators()
    }, autoScrollInterval)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [autoScroll, autoScrollIncrement, autoScrollInterval])

  const smoothScroll = (scrollContainer: HTMLElement, target: number) => {
    const start = scrollContainer.scrollLeft
    const change = target - start
    const duration = 600
    let startTime: number | null = null

    const animateScroll = (timestamp: number) => {
      if (!startTime) startTime = timestamp
      const elapsed = timestamp - startTime
      const progress = Math.min(elapsed / duration, 1)
      const easeInOutQuad = (t: number) =>
        t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
      scrollContainer.scrollLeft = start + change * easeInOutQuad(progress)

      if (progress < 1) {
        requestAnimationFrame(animateScroll)
      }
    }

    requestAnimationFrame(animateScroll)
  }

  const scroll = (direction: 'left' | 'right') => {
    const scrollContainer = scrollContainerRef.current
    if (!scrollContainer) return

    const scrollAmount =
      direction === 'left' ? -buttonScrollAmount : buttonScrollAmount
    const targetScrollLeft = scrollContainer.scrollLeft + scrollAmount

    if (direction === 'left') {
      if (scrollContainer.scrollLeft <= 0) {
        scrollContainer.scrollLeft = scrollContainer.scrollWidth
      } else {
        smoothScroll(scrollContainer, targetScrollLeft)
      }
    } else {
      if (
        scrollContainer.scrollLeft >=
        scrollContainer.scrollWidth - scrollContainer.clientWidth
      ) {
        scrollContainer.scrollLeft = 0
      } else {
        smoothScroll(scrollContainer, targetScrollLeft)
      }
    }

    updateScrollIndicators()
  }

  const updateScrollIndicators = () => {
    const scrollContainer = scrollContainerRef.current
    if (!scrollContainer) return

    const maxScrollLeft =
      scrollContainer.scrollWidth - scrollContainer.clientWidth
    setShowLeftButton(scrollContainer.scrollLeft > 0)
    setShowRightButton(scrollContainer.scrollLeft < maxScrollLeft)
  }

  return (
    <div
      className={cn(styles['container'], className)}
      data-testid="carousel-projects">
      <div className={styles['scroll-container']}>
        <div
          ref={scrollContainerRef}
          className={cn(styles['scroll-content'], {
            [styles['mask-right']]: !showLeftButton && showRightButton,
            [styles['mask-left']]: showLeftButton && !showRightButton,
            [styles['mask-both']]: showLeftButton && showRightButton
          })}
          onScroll={updateScrollIndicators}>
          {infiniteCards}
        </div>
        {showLeftButton && (
          <Button
            key={'carousel-left-button'}
            className={cn(styles['scroll-button'], styles['left'], {
              [styles['light-button']]: color === 'dark',
              [styles['dark-button']]: color === 'light'
            })}
            onClick={() => scroll('left')}
            variant={'secondary'}
            icon-only>
            <Icon slot="icon">
              <ArrowLeftIcon />
            </Icon>
          </Button>
        )}
        {showRightButton && (
          <Button
            key={'carousel-right-button'}
            className={cn(styles['scroll-button'], styles['right'], {
              [styles['light-button']]: color === 'dark',
              [styles['dark-button']]: color === 'light'
            })}
            onClick={() => scroll('right')}
            variant={'secondary'}
            icon-only>
            <Icon slot="icon">
              <ArrowRightIcon />
            </Icon>
          </Button>
        )}
      </div>
    </div>
  )
}

export default CarouselWrapper
