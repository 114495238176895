import React, { FC } from 'react'
import styles from '@styles/components/FrameDimension.module.scss'
import { useSceneState } from '@hooks/useScene'

const FrameDimension: FC = () => {
  const frameEnabled = useSceneState('frameEnabled')
  const { top, left } = useSceneState('framePosition')
  const { w, h } = useSceneState('frameSize')

  if (!frameEnabled) return null

  return (
    <p
      className={styles['text']}
      style={{
        top: top - 24,
        left
      }}>{`${w} x ${h}`}</p>
  )
}

export default FrameDimension
