import {
  useLaunchDarklyActions,
  useLaunchDarklyState
} from '@hooks/useLaunchDarkly'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { FC, PropsWithChildren, useEffect } from 'react'

// This component is used solely for dispatching the context update call to LaunchDarkly.

export const LaunchDarklyContextUpdater: FC<PropsWithChildren> = ({
  children
}) => {
  const launchDarklyContext = useLaunchDarklyState('launchDarklyContext')
  const isLDInitialized = useLaunchDarklyState('initialized')

  const { setLDInitialized, ldContextSuccessfullyUpdated } =
    useLaunchDarklyActions()
  const ldClient = useLDClient()

  useEffect(() => {
    if (ldClient && !isLDInitialized) setLDInitialized()
  }, [ldClient, isLDInitialized])

  useEffect(() => {
    // Launch darkly will throw an exception if you try to update it with an empty object
    if (Object.keys(launchDarklyContext).length > 0) {
      ldClient
        ?.identify(launchDarklyContext)
        .then(() => ldContextSuccessfullyUpdated())
    }
  }, [launchDarklyContext])

  return <>{children}</>
}
