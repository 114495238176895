import { FC } from 'react'
import { ActionButton, Icon, OverlayTrigger, Tooltip } from 'ui'
import PasteMaterialIcon from '/public/temp-icon-material-paste.svg'
import { useSceneActions } from '@hooks/useScene'
import { useMediumMinimumSizePreference } from '@hooks/useProject'
import { useTranslations } from 'use-intl'

export const PasteMaterial: FC = () => {
  const t = useTranslations()
  const { pasteMaterials } = useSceneActions()
  const size = useMediumMinimumSizePreference()

  return (
    <OverlayTrigger placement="top" offset={0}>
      <ActionButton
        size={size}
        quiet
        slot="trigger"
        onClick={() => {
          pasteMaterials()
          document.getElementById('canvas')?.focus()
        }}>
        <Icon slot="icon" className="icon-m">
          <PasteMaterialIcon />
        </Icon>
      </ActionButton>
      <Tooltip slot="hover-content">
        {t('studio:tooltips:pasteMaterial')}
      </Tooltip>
    </OverlayTrigger>
  )
}
