import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Asset = {
  __typename?: 'Asset';
  document?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  ownerUserUuid?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type DocumentFrameSize = {
  __typename?: 'DocumentFrameSize';
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type DocumentFrameSizeUpdate = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export enum OrderDirections {
  Asc = 'asc',
  Desc = 'desc'
}

export type PaginatedMetadata = {
  __typename?: 'PaginatedMetadata';
  currentOffset?: Maybe<Scalars['Int']>;
  nextOffset?: Maybe<Scalars['Int']>;
  previousOffset?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatedMetadataDeprecated = {
  __typename?: 'PaginatedMetadataDeprecated';
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PaginatedProjects = {
  __typename?: 'PaginatedProjects';
  entries?: Maybe<Array<Maybe<Project>>>;
  metadata?: Maybe<PaginatedMetadata>;
};

export type PaginatedReactions = {
  __typename?: 'PaginatedReactions';
  entries?: Maybe<Array<Maybe<Reaction>>>;
  metadata?: Maybe<PaginatedMetadataDeprecated>;
  totalReactions?: Maybe<Scalars['Int']>;
  uniqueReactions?: Maybe<Array<Maybe<UniqueReaction>>>;
  userReaction?: Maybe<Reaction>;
};

export type Project = {
  __typename?: 'Project';
  archived?: Maybe<Scalars['Boolean']>;
  documentFrameSize?: Maybe<DocumentFrameSize>;
  featured?: Maybe<Scalars['Boolean']>;
  imageUrl?: Maybe<Scalars['String']>;
  insertedAt?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ownerUserUuid?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  totalReactions?: Maybe<Scalars['Int']>;
  trendFactor?: Maybe<Scalars['Float']>;
  uniqueReactionTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  userReactedWith?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export enum ProjectOrderBy {
  InsertedAt = 'inserted_at',
  TotalReactions = 'total_reactions',
  TrendFactor = 'trend_factor'
}

export type ProjectSubscriptionUpdate = {
  __typename?: 'ProjectSubscriptionUpdate';
  projectUpdated?: Maybe<Project>;
  topicChanged?: Maybe<Scalars['Boolean']>;
};

export type Reaction = {
  __typename?: 'Reaction';
  adobeUserAvatarUrl?: Maybe<Scalars['String']>;
  adobeUserDisplayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insertedAt?: Maybe<Scalars['String']>;
  projectUuid?: Maybe<Scalars['String']>;
  reactionType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  userUuid?: Maybe<Scalars['String']>;
};

export type ReactionUpdated = {
  __typename?: 'ReactionUpdated';
  adobeUserAvatarUrl?: Maybe<Scalars['String']>;
  adobeUserDisplayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  insertedAt?: Maybe<Scalars['String']>;
  projectUuid?: Maybe<Scalars['String']>;
  reactionType?: Maybe<Scalars['String']>;
  uniqueReactions?: Maybe<Array<Maybe<UniqueReaction>>>;
  updatedAt?: Maybe<Scalars['String']>;
  userUuid?: Maybe<Scalars['String']>;
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  /** Archive a user asset */
  archiveUserLibraryAsset?: Maybe<Asset>;
  /** Create an anonymous user */
  createAnonymousUser?: Maybe<User>;
  /** Create a project - requires authorization */
  createProject?: Maybe<Project>;
  /** Create a project with document - requires authorization */
  createProjectWithDocument?: Maybe<Project>;
  /** Create a reaction - requires authorization */
  createReaction?: Maybe<Reaction>;
  /** Create a user from profile data and adobe_user_id in valid token */
  createUser?: Maybe<User>;
  /** Create a user asset */
  createUserLibraryAsset?: Maybe<Asset>;
  /** Deletes a reaction - requires authorization */
  deleteReaction?: Maybe<ReactionUpdated>;
  /** Duplicate a project */
  duplicateProject?: Maybe<Project>;
  /** Publish a project - requires authorization */
  publishProject?: Maybe<Project>;
  /** Update a project */
  updateProject?: Maybe<Project>;
  /** Update a document */
  updateProjectDocument?: Maybe<Project>;
  /** Updates a reaction - requires authorization */
  updateReaction?: Maybe<ReactionUpdated>;
  /** Update a user */
  updateUser?: Maybe<User>;
};


export type RootMutationTypeArchiveUserLibraryAssetArgs = {
  assetUuid: Scalars['String'];
};


export type RootMutationTypeCreateProjectArgs = {
  name: Scalars['String'];
  public: Scalars['Boolean'];
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type RootMutationTypeCreateProjectWithDocumentArgs = {
  document?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type RootMutationTypeCreateReactionArgs = {
  projectUuid?: InputMaybe<Scalars['String']>;
  reactionType?: InputMaybe<Scalars['String']>;
  userUuid?: InputMaybe<Scalars['String']>;
};


export type RootMutationTypeCreateUserArgs = {
  adobeUserAvatarUrl?: InputMaybe<Scalars['String']>;
  adobeUserDisplayName?: InputMaybe<Scalars['String']>;
  adobeUserEmail: Scalars['String'];
  adobeUserId: Scalars['String'];
  preferences?: InputMaybe<UserPreferencesMutation>;
};


export type RootMutationTypeCreateUserLibraryAssetArgs = {
  document: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};


export type RootMutationTypeDeleteReactionArgs = {
  projectUuid?: InputMaybe<Scalars['String']>;
  reactionType?: InputMaybe<Scalars['String']>;
  userUuid?: InputMaybe<Scalars['String']>;
};


export type RootMutationTypeDuplicateProjectArgs = {
  uuid: Scalars['String'];
};


export type RootMutationTypePublishProjectArgs = {
  uuid: Scalars['String'];
};


export type RootMutationTypeUpdateProjectArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  documentFrameSize?: InputMaybe<DocumentFrameSizeUpdate>;
  featured?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  public?: InputMaybe<Scalars['Boolean']>;
  uuid: Scalars['String'];
};


export type RootMutationTypeUpdateProjectDocumentArgs = {
  document: Scalars['String'];
  uuid: Scalars['String'];
};


export type RootMutationTypeUpdateReactionArgs = {
  projectUuid?: InputMaybe<Scalars['String']>;
  reactionType?: InputMaybe<Scalars['String']>;
  userUuid?: InputMaybe<Scalars['String']>;
};


export type RootMutationTypeUpdateUserArgs = {
  adobeUserAvatarUrl?: InputMaybe<Scalars['String']>;
  adobeUserDisplayName?: InputMaybe<Scalars['String']>;
  adobeUserEmail?: InputMaybe<Scalars['String']>;
  adobeUserId?: InputMaybe<Scalars['String']>;
  preferences?: InputMaybe<UserPreferencesMutation>;
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  /** Get user by adobe_user_id */
  adobeUser?: Maybe<User>;
  /** Get a single project by its UUID */
  project?: Maybe<Project>;
  /** List all projects */
  projects?: Maybe<PaginatedProjects>;
  /** List paginated reactions */
  reactions?: Maybe<PaginatedReactions>;
  /** Get a single user by their adobe_user_id field */
  userByAdobeUserId?: Maybe<User>;
  /** Get a single user by their uuid */
  userByUuid?: Maybe<User>;
  /** Get all current user's assets */
  userLibraryAssets?: Maybe<Array<Maybe<Asset>>>;
  /** Get current user's assets by type */
  userLibraryAssetsByType?: Maybe<Array<Maybe<Asset>>>;
  /** Get projects belonging to user defined in GraphQL context - requires authorization */
  userProjects?: Maybe<Array<Maybe<Project>>>;
  /** List all users */
  users?: Maybe<Array<Maybe<User>>>;
};


export type RootQueryTypeAdobeUserArgs = {
  adobeUserId: Scalars['String'];
};


export type RootQueryTypeProjectArgs = {
  uuid: Scalars['String'];
};


export type RootQueryTypeProjectsArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  featured?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<ProjectOrderBy>>>;
  orderDirections?: InputMaybe<Array<InputMaybe<OrderDirections>>>;
  ownerUserUuid?: InputMaybe<Scalars['String']>;
  public?: InputMaybe<Scalars['Boolean']>;
  userReactedUuid?: InputMaybe<Scalars['String']>;
};


export type RootQueryTypeReactionsArgs = {
  cursorAfter?: InputMaybe<Scalars['String']>;
  projectUuid?: InputMaybe<Scalars['String']>;
  reactionType?: InputMaybe<Scalars['String']>;
  userUuid?: InputMaybe<Scalars['String']>;
};


export type RootQueryTypeUserByAdobeUserIdArgs = {
  adobeUserId: Scalars['String'];
};


export type RootQueryTypeUserByUuidArgs = {
  uuid: Scalars['String'];
};


export type RootQueryTypeUserLibraryAssetsByTypeArgs = {
  type: Scalars['String'];
};

export type RootSubscriptionType = {
  __typename?: 'RootSubscriptionType';
  projectCreated?: Maybe<Project>;
  projectDuplicated?: Maybe<Project>;
  projectUpdated?: Maybe<ProjectSubscriptionUpdate>;
  reactionCreated?: Maybe<ReactionUpdated>;
  reactionDeleted?: Maybe<ReactionUpdated>;
  reactionUpdated?: Maybe<ReactionUpdated>;
};


export type RootSubscriptionTypeProjectCreatedArgs = {
  subscriptionTopic?: InputMaybe<Scalars['String']>;
};


export type RootSubscriptionTypeProjectDuplicatedArgs = {
  subscriptionTopic?: InputMaybe<Scalars['String']>;
};


export type RootSubscriptionTypeProjectUpdatedArgs = {
  subscriptionTopic?: InputMaybe<Scalars['String']>;
};

export type UniqueReaction = {
  __typename?: 'UniqueReaction';
  reactionType?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  adobeUserAvatarUrl?: Maybe<Scalars['String']>;
  adobeUserDisplayName?: Maybe<Scalars['String']>;
  adobeUserEmail?: Maybe<Scalars['String']>;
  adobeUserId?: Maybe<Scalars['String']>;
  insertedAt?: Maybe<Scalars['String']>;
  isAnonymous?: Maybe<Scalars['Boolean']>;
  preferences?: Maybe<UserPreferences>;
  uuid?: Maybe<Scalars['String']>;
};

export type UserPreferences = {
  __typename?: 'UserPreferences';
  color?: Maybe<Array<Maybe<Scalars['Int']>>>;
  projectsPrivateDefault?: Maybe<Scalars['Boolean']>;
};

export type UserPreferencesMutation = {
  color?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  projectsPrivateDefault?: InputMaybe<Scalars['Boolean']>;
};

export type CreateUserLibraryAssetMutationVariables = Exact<{
  name: Scalars['String'];
  type: Scalars['String'];
  document: Scalars['String'];
}>;


export type CreateUserLibraryAssetMutation = { __typename?: 'RootMutationType', createUserLibraryAsset?: { __typename?: 'Asset', uuid?: string | null, ownerUserUuid?: string | null, name?: string | null, type?: string | null, document?: string | null } | null };

export type ArchiveUserLibraryAssetMutationVariables = Exact<{
  assetUuid: Scalars['String'];
}>;


export type ArchiveUserLibraryAssetMutation = { __typename?: 'RootMutationType', archiveUserLibraryAsset?: { __typename?: 'Asset', uuid?: string | null } | null };

export type GetAllUserLibraryAssetsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUserLibraryAssetsQuery = { __typename?: 'RootQueryType', userLibraryAssets?: Array<{ __typename?: 'Asset', uuid?: string | null, ownerUserUuid?: string | null, name?: string | null, type?: string | null, document?: string | null } | null> | null };

export type GetUserLibraryAssetsByTypeQueryVariables = Exact<{
  type: Scalars['String'];
}>;


export type GetUserLibraryAssetsByTypeQuery = { __typename?: 'RootQueryType', userLibraryAssetsByType?: Array<{ __typename?: 'Asset', uuid?: string | null, ownerUserUuid?: string | null, name?: string | null, type?: string | null, document?: string | null } | null> | null };

export type GetProjectsQueryVariables = Exact<{
  archived?: InputMaybe<Scalars['Boolean']>;
  featured?: InputMaybe<Scalars['Boolean']>;
  ownerUserUuid?: InputMaybe<Scalars['String']>;
  public?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  userReactedUuid?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<ProjectOrderBy> | ProjectOrderBy>;
  orderDirections?: InputMaybe<Array<OrderDirections> | OrderDirections>;
}>;


export type GetProjectsQuery = { __typename?: 'RootQueryType', projects?: { __typename?: 'PaginatedProjects', metadata?: { __typename?: 'PaginatedMetadata', currentOffset?: number | null, previousOffset?: number | null, nextOffset?: number | null, totalCount?: number | null } | null, entries?: Array<{ __typename?: 'Project', uuid?: string | null, name?: string | null, imageUrl?: string | null, tags?: Array<string | null> | null, ownerUserUuid?: string | null, insertedAt?: string | null, public?: boolean | null, featured?: boolean | null, archived?: boolean | null, trendFactor?: number | null, totalReactions?: number | null, uniqueReactionTypes?: Array<string | null> | null, userReactedWith?: string | null, documentFrameSize?: { __typename?: 'DocumentFrameSize', width?: number | null, height?: number | null } | null } | null> | null } | null };

export type GetProjectByUuidQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type GetProjectByUuidQuery = { __typename?: 'RootQueryType', project?: { __typename?: 'Project', uuid?: string | null, name?: string | null, imageUrl?: string | null, tags?: Array<string | null> | null, ownerUserUuid?: string | null, insertedAt?: string | null, public?: boolean | null, featured?: boolean | null, archived?: boolean | null, documentFrameSize?: { __typename?: 'DocumentFrameSize', width?: number | null, height?: number | null } | null } | null };

export type CreateProjectMutationVariables = Exact<{
  name: Scalars['String'];
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  public: Scalars['Boolean'];
}>;


export type CreateProjectMutation = { __typename?: 'RootMutationType', createProject?: { __typename?: 'Project', uuid?: string | null, name?: string | null, tags?: Array<string | null> | null, ownerUserUuid?: string | null, insertedAt?: string | null, public?: boolean | null, featured?: boolean | null, archived?: boolean | null, documentFrameSize?: { __typename?: 'DocumentFrameSize', width?: number | null, height?: number | null } | null } | null };

export type CreateProjectWithDocumentMutationVariables = Exact<{
  name: Scalars['String'];
  document: Scalars['String'];
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type CreateProjectWithDocumentMutation = { __typename?: 'RootMutationType', createProjectWithDocument?: { __typename?: 'Project', uuid?: string | null, name?: string | null, tags?: Array<string | null> | null, ownerUserUuid?: string | null, insertedAt?: string | null, public?: boolean | null, featured?: boolean | null, archived?: boolean | null, documentFrameSize?: { __typename?: 'DocumentFrameSize', width?: number | null, height?: number | null } | null } | null };

export type PublishProjectMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type PublishProjectMutation = { __typename?: 'RootMutationType', publishProject?: { __typename?: 'Project', uuid?: string | null } | null };

export type UpdateProjectMutationVariables = Exact<{
  uuid: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  public?: InputMaybe<Scalars['Boolean']>;
  featured?: InputMaybe<Scalars['Boolean']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  documentFrameSize?: InputMaybe<DocumentFrameSizeUpdate>;
}>;


export type UpdateProjectMutation = { __typename?: 'RootMutationType', updateProject?: { __typename?: 'Project', uuid?: string | null, name?: string | null, public?: boolean | null, featured?: boolean | null, archived?: boolean | null, documentFrameSize?: { __typename?: 'DocumentFrameSize', width?: number | null, height?: number | null } | null } | null };

export type DuplicateProjectMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type DuplicateProjectMutation = { __typename?: 'RootMutationType', duplicateProject?: { __typename?: 'Project', uuid?: string | null } | null };

export type ProjectCreatedSubscriptionVariables = Exact<{
  subscriptionTopic: Scalars['String'];
}>;


export type ProjectCreatedSubscription = { __typename?: 'RootSubscriptionType', projectCreated?: { __typename?: 'Project', uuid?: string | null, name?: string | null, imageUrl?: string | null, tags?: Array<string | null> | null, ownerUserUuid?: string | null, insertedAt?: string | null, public?: boolean | null, featured?: boolean | null, archived?: boolean | null, documentFrameSize?: { __typename?: 'DocumentFrameSize', width?: number | null, height?: number | null } | null } | null };

export type ProjectDuplicatedSubscriptionVariables = Exact<{
  subscriptionTopic: Scalars['String'];
}>;


export type ProjectDuplicatedSubscription = { __typename?: 'RootSubscriptionType', projectDuplicated?: { __typename?: 'Project', uuid?: string | null, name?: string | null, imageUrl?: string | null, tags?: Array<string | null> | null, ownerUserUuid?: string | null, insertedAt?: string | null, public?: boolean | null, featured?: boolean | null, archived?: boolean | null, documentFrameSize?: { __typename?: 'DocumentFrameSize', width?: number | null, height?: number | null } | null } | null };

export type ProjectUpdatedSubscriptionVariables = Exact<{
  subscriptionTopic: Scalars['String'];
}>;


export type ProjectUpdatedSubscription = { __typename?: 'RootSubscriptionType', projectUpdated?: { __typename?: 'ProjectSubscriptionUpdate', topicChanged?: boolean | null, projectUpdated?: { __typename?: 'Project', uuid?: string | null, name?: string | null, imageUrl?: string | null, tags?: Array<string | null> | null, ownerUserUuid?: string | null, insertedAt?: string | null, public?: boolean | null, featured?: boolean | null, archived?: boolean | null, documentFrameSize?: { __typename?: 'DocumentFrameSize', width?: number | null, height?: number | null } | null } | null } | null };

export type GetReactionsQueryVariables = Exact<{
  projectUuid: Scalars['String'];
  userUuid?: InputMaybe<Scalars['String']>;
  reactionType?: InputMaybe<Scalars['String']>;
  cursorAfter?: InputMaybe<Scalars['String']>;
}>;


export type GetReactionsQuery = { __typename?: 'RootQueryType', reactions?: { __typename?: 'PaginatedReactions', totalReactions?: number | null, metadata?: { __typename?: 'PaginatedMetadataDeprecated', after?: string | null, before?: string | null, limit?: number | null } | null, entries?: Array<{ __typename?: 'Reaction', id?: string | null, reactionType?: string | null, projectUuid?: string | null, userUuid?: string | null, adobeUserDisplayName?: string | null, adobeUserAvatarUrl?: string | null, insertedAt?: string | null, updatedAt?: string | null } | null> | null, uniqueReactions?: Array<{ __typename?: 'UniqueReaction', reactionType?: string | null, total?: number | null } | null> | null, userReaction?: { __typename?: 'Reaction', reactionType?: string | null } | null } | null };

export type CreateReactionMutationVariables = Exact<{
  projectUuid: Scalars['String'];
  userUuid: Scalars['String'];
  reactionType?: InputMaybe<Scalars['String']>;
}>;


export type CreateReactionMutation = { __typename?: 'RootMutationType', createReaction?: { __typename?: 'Reaction', id?: string | null, reactionType?: string | null, projectUuid?: string | null, userUuid?: string | null } | null };

export type UpdateReactionMutationVariables = Exact<{
  projectUuid: Scalars['String'];
  userUuid: Scalars['String'];
  reactionType: Scalars['String'];
}>;


export type UpdateReactionMutation = { __typename?: 'RootMutationType', updateReaction?: { __typename?: 'ReactionUpdated', reactionType?: string | null, projectUuid?: string | null, userUuid?: string | null, uniqueReactions?: Array<{ __typename?: 'UniqueReaction', reactionType?: string | null, total?: number | null } | null> | null } | null };

export type DeleteReactionMutationVariables = Exact<{
  projectUuid: Scalars['String'];
  userUuid: Scalars['String'];
  reactionType: Scalars['String'];
}>;


export type DeleteReactionMutation = { __typename?: 'RootMutationType', deleteReaction?: { __typename?: 'ReactionUpdated', reactionType?: string | null, projectUuid?: string | null, userUuid?: string | null, uniqueReactions?: Array<{ __typename?: 'UniqueReaction', reactionType?: string | null, total?: number | null } | null> | null } | null };

export type ReactionCreatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ReactionCreatedSubscription = { __typename?: 'RootSubscriptionType', reactionCreated?: { __typename?: 'ReactionUpdated', id?: string | null, reactionType?: string | null, projectUuid?: string | null, userUuid?: string | null, uniqueReactions?: Array<{ __typename?: 'UniqueReaction', reactionType?: string | null, total?: number | null } | null> | null } | null };

export type ReactionDeletedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ReactionDeletedSubscription = { __typename?: 'RootSubscriptionType', reactionDeleted?: { __typename?: 'ReactionUpdated', reactionType?: string | null, projectUuid?: string | null, userUuid?: string | null, uniqueReactions?: Array<{ __typename?: 'UniqueReaction', reactionType?: string | null, total?: number | null } | null> | null } | null };

export type ReactionUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ReactionUpdatedSubscription = { __typename?: 'RootSubscriptionType', reactionUpdated?: { __typename?: 'ReactionUpdated', reactionType?: string | null, projectUuid?: string | null, userUuid?: string | null, uniqueReactions?: Array<{ __typename?: 'UniqueReaction', reactionType?: string | null, total?: number | null } | null> | null } | null };

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUsersQuery = { __typename?: 'RootQueryType', users?: Array<{ __typename?: 'User', uuid?: string | null, isAnonymous?: boolean | null, adobeUserId?: string | null, adobeUserEmail?: string | null, adobeUserDisplayName?: string | null, adobeUserAvatarUrl?: string | null, preferences?: { __typename?: 'UserPreferences', color?: Array<number | null> | null } | null } | null> | null };

export type GetUserByAdobeUserIdQueryVariables = Exact<{
  adobeUserId: Scalars['String'];
}>;


export type GetUserByAdobeUserIdQuery = { __typename?: 'RootQueryType', userByAdobeUserId?: { __typename?: 'User', uuid?: string | null, isAnonymous?: boolean | null, adobeUserId?: string | null, adobeUserEmail?: string | null, adobeUserDisplayName?: string | null, adobeUserAvatarUrl?: string | null, preferences?: { __typename?: 'UserPreferences', color?: Array<number | null> | null } | null } | null };

export type GetUserByUuidQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type GetUserByUuidQuery = { __typename?: 'RootQueryType', userByUuid?: { __typename?: 'User', uuid?: string | null, isAnonymous?: boolean | null, adobeUserId?: string | null, adobeUserEmail?: string | null, adobeUserDisplayName?: string | null, adobeUserAvatarUrl?: string | null, preferences?: { __typename?: 'UserPreferences', color?: Array<number | null> | null, projectsPrivateDefault?: boolean | null } | null } | null };

export type GetAdobeUserQueryVariables = Exact<{
  adobeUserId: Scalars['String'];
}>;


export type GetAdobeUserQuery = { __typename?: 'RootQueryType', adobeUser?: { __typename?: 'User', uuid?: string | null, isAnonymous?: boolean | null, adobeUserId?: string | null, adobeUserEmail?: string | null, adobeUserDisplayName?: string | null, adobeUserAvatarUrl?: string | null, insertedAt?: string | null, preferences?: { __typename?: 'UserPreferences', color?: Array<number | null> | null, projectsPrivateDefault?: boolean | null } | null } | null };

export type CreateUserMutationVariables = Exact<{
  adobeUserId: Scalars['String'];
  adobeUserEmail: Scalars['String'];
  adobeUserDisplayName: Scalars['String'];
  adobeUserAvatarUrl: Scalars['String'];
  preferences?: InputMaybe<UserPreferencesMutation>;
}>;


export type CreateUserMutation = { __typename?: 'RootMutationType', createUser?: { __typename?: 'User', uuid?: string | null, isAnonymous?: boolean | null, adobeUserId?: string | null, adobeUserEmail?: string | null, adobeUserDisplayName?: string | null, adobeUserAvatarUrl?: string | null, preferences?: { __typename?: 'UserPreferences', color?: Array<number | null> | null, projectsPrivateDefault?: boolean | null } | null } | null };

export type CreateAnonymousUserMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateAnonymousUserMutation = { __typename?: 'RootMutationType', createAnonymousUser?: { __typename?: 'User', uuid?: string | null, isAnonymous?: boolean | null, adobeUserId?: string | null, adobeUserEmail?: string | null, adobeUserDisplayName?: string | null, adobeUserAvatarUrl?: string | null, preferences?: { __typename?: 'UserPreferences', color?: Array<number | null> | null } | null } | null };

export type UpdateUserMutationVariables = Exact<{
  adobeUserId?: InputMaybe<Scalars['String']>;
  adobeUserEmail?: InputMaybe<Scalars['String']>;
  adobeUserDisplayName?: InputMaybe<Scalars['String']>;
  adobeUserAvatarUrl?: InputMaybe<Scalars['String']>;
  preferences?: InputMaybe<UserPreferencesMutation>;
}>;


export type UpdateUserMutation = { __typename?: 'RootMutationType', updateUser?: { __typename?: 'User', uuid?: string | null, isAnonymous?: boolean | null, adobeUserId?: string | null, adobeUserEmail?: string | null, adobeUserDisplayName?: string | null, adobeUserAvatarUrl?: string | null, preferences?: { __typename?: 'UserPreferences', color?: Array<number | null> | null, projectsPrivateDefault?: boolean | null } | null } | null };


export const CreateUserLibraryAssetDocument = gql`
    mutation CreateUserLibraryAsset($name: String!, $type: String!, $document: String!) {
  createUserLibraryAsset(name: $name, type: $type, document: $document) {
    uuid
    ownerUserUuid
    name
    type
    document
  }
}
    `;
export type CreateUserLibraryAssetMutationFn = Apollo.MutationFunction<CreateUserLibraryAssetMutation, CreateUserLibraryAssetMutationVariables>;

/**
 * __useCreateUserLibraryAssetMutation__
 *
 * To run a mutation, you first call `useCreateUserLibraryAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserLibraryAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserLibraryAssetMutation, { data, loading, error }] = useCreateUserLibraryAssetMutation({
 *   variables: {
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      document: // value for 'document'
 *   },
 * });
 */
export function useCreateUserLibraryAssetMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserLibraryAssetMutation, CreateUserLibraryAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserLibraryAssetMutation, CreateUserLibraryAssetMutationVariables>(CreateUserLibraryAssetDocument, options);
      }
export type CreateUserLibraryAssetMutationHookResult = ReturnType<typeof useCreateUserLibraryAssetMutation>;
export type CreateUserLibraryAssetMutationResult = Apollo.MutationResult<CreateUserLibraryAssetMutation>;
export type CreateUserLibraryAssetMutationOptions = Apollo.BaseMutationOptions<CreateUserLibraryAssetMutation, CreateUserLibraryAssetMutationVariables>;
export const ArchiveUserLibraryAssetDocument = gql`
    mutation ArchiveUserLibraryAsset($assetUuid: String!) {
  archiveUserLibraryAsset(assetUuid: $assetUuid) {
    uuid
  }
}
    `;
export type ArchiveUserLibraryAssetMutationFn = Apollo.MutationFunction<ArchiveUserLibraryAssetMutation, ArchiveUserLibraryAssetMutationVariables>;

/**
 * __useArchiveUserLibraryAssetMutation__
 *
 * To run a mutation, you first call `useArchiveUserLibraryAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveUserLibraryAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveUserLibraryAssetMutation, { data, loading, error }] = useArchiveUserLibraryAssetMutation({
 *   variables: {
 *      assetUuid: // value for 'assetUuid'
 *   },
 * });
 */
export function useArchiveUserLibraryAssetMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveUserLibraryAssetMutation, ArchiveUserLibraryAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveUserLibraryAssetMutation, ArchiveUserLibraryAssetMutationVariables>(ArchiveUserLibraryAssetDocument, options);
      }
export type ArchiveUserLibraryAssetMutationHookResult = ReturnType<typeof useArchiveUserLibraryAssetMutation>;
export type ArchiveUserLibraryAssetMutationResult = Apollo.MutationResult<ArchiveUserLibraryAssetMutation>;
export type ArchiveUserLibraryAssetMutationOptions = Apollo.BaseMutationOptions<ArchiveUserLibraryAssetMutation, ArchiveUserLibraryAssetMutationVariables>;
export const GetAllUserLibraryAssetsDocument = gql`
    query GetAllUserLibraryAssets {
  userLibraryAssets {
    uuid
    ownerUserUuid
    name
    type
    document
  }
}
    `;

/**
 * __useGetAllUserLibraryAssetsQuery__
 *
 * To run a query within a React component, call `useGetAllUserLibraryAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUserLibraryAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUserLibraryAssetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUserLibraryAssetsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUserLibraryAssetsQuery, GetAllUserLibraryAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUserLibraryAssetsQuery, GetAllUserLibraryAssetsQueryVariables>(GetAllUserLibraryAssetsDocument, options);
      }
export function useGetAllUserLibraryAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUserLibraryAssetsQuery, GetAllUserLibraryAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUserLibraryAssetsQuery, GetAllUserLibraryAssetsQueryVariables>(GetAllUserLibraryAssetsDocument, options);
        }
export type GetAllUserLibraryAssetsQueryHookResult = ReturnType<typeof useGetAllUserLibraryAssetsQuery>;
export type GetAllUserLibraryAssetsLazyQueryHookResult = ReturnType<typeof useGetAllUserLibraryAssetsLazyQuery>;
export type GetAllUserLibraryAssetsQueryResult = Apollo.QueryResult<GetAllUserLibraryAssetsQuery, GetAllUserLibraryAssetsQueryVariables>;
export const GetUserLibraryAssetsByTypeDocument = gql`
    query GetUserLibraryAssetsByType($type: String!) {
  userLibraryAssetsByType(type: $type) {
    uuid
    ownerUserUuid
    name
    type
    document
  }
}
    `;

/**
 * __useGetUserLibraryAssetsByTypeQuery__
 *
 * To run a query within a React component, call `useGetUserLibraryAssetsByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLibraryAssetsByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLibraryAssetsByTypeQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetUserLibraryAssetsByTypeQuery(baseOptions: Apollo.QueryHookOptions<GetUserLibraryAssetsByTypeQuery, GetUserLibraryAssetsByTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserLibraryAssetsByTypeQuery, GetUserLibraryAssetsByTypeQueryVariables>(GetUserLibraryAssetsByTypeDocument, options);
      }
export function useGetUserLibraryAssetsByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserLibraryAssetsByTypeQuery, GetUserLibraryAssetsByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserLibraryAssetsByTypeQuery, GetUserLibraryAssetsByTypeQueryVariables>(GetUserLibraryAssetsByTypeDocument, options);
        }
export type GetUserLibraryAssetsByTypeQueryHookResult = ReturnType<typeof useGetUserLibraryAssetsByTypeQuery>;
export type GetUserLibraryAssetsByTypeLazyQueryHookResult = ReturnType<typeof useGetUserLibraryAssetsByTypeLazyQuery>;
export type GetUserLibraryAssetsByTypeQueryResult = Apollo.QueryResult<GetUserLibraryAssetsByTypeQuery, GetUserLibraryAssetsByTypeQueryVariables>;
export const GetProjectsDocument = gql`
    query GetProjects($archived: Boolean, $featured: Boolean, $ownerUserUuid: String, $public: Boolean, $limit: Int, $offset: Int, $userReactedUuid: String, $orderBy: [ProjectOrderBy!], $orderDirections: [OrderDirections!]) {
  projects(
    archived: $archived
    featured: $featured
    ownerUserUuid: $ownerUserUuid
    public: $public
    limit: $limit
    offset: $offset
    userReactedUuid: $userReactedUuid
    orderBy: $orderBy
    orderDirections: $orderDirections
  ) {
    metadata {
      currentOffset
      previousOffset
      nextOffset
      totalCount
    }
    entries {
      uuid
      name
      imageUrl
      tags
      ownerUserUuid
      insertedAt
      public
      featured
      archived
      trendFactor
      totalReactions
      uniqueReactionTypes
      userReactedWith
      documentFrameSize {
        width
        height
      }
    }
  }
}
    `;

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *      archived: // value for 'archived'
 *      featured: // value for 'featured'
 *      ownerUserUuid: // value for 'ownerUserUuid'
 *      public: // value for 'public'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      userReactedUuid: // value for 'userReactedUuid'
 *      orderBy: // value for 'orderBy'
 *      orderDirections: // value for 'orderDirections'
 *   },
 * });
 */
export function useGetProjectsQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
      }
export function useGetProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, options);
        }
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<typeof useGetProjectsLazyQuery>;
export type GetProjectsQueryResult = Apollo.QueryResult<GetProjectsQuery, GetProjectsQueryVariables>;
export const GetProjectByUuidDocument = gql`
    query GetProjectByUuid($uuid: String!) {
  project(uuid: $uuid) {
    uuid
    name
    imageUrl
    tags
    ownerUserUuid
    insertedAt
    public
    featured
    archived
    documentFrameSize {
      width
      height
    }
  }
}
    `;

/**
 * __useGetProjectByUuidQuery__
 *
 * To run a query within a React component, call `useGetProjectByUuidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectByUuidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectByUuidQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetProjectByUuidQuery(baseOptions: Apollo.QueryHookOptions<GetProjectByUuidQuery, GetProjectByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectByUuidQuery, GetProjectByUuidQueryVariables>(GetProjectByUuidDocument, options);
      }
export function useGetProjectByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectByUuidQuery, GetProjectByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectByUuidQuery, GetProjectByUuidQueryVariables>(GetProjectByUuidDocument, options);
        }
export type GetProjectByUuidQueryHookResult = ReturnType<typeof useGetProjectByUuidQuery>;
export type GetProjectByUuidLazyQueryHookResult = ReturnType<typeof useGetProjectByUuidLazyQuery>;
export type GetProjectByUuidQueryResult = Apollo.QueryResult<GetProjectByUuidQuery, GetProjectByUuidQueryVariables>;
export const CreateProjectDocument = gql`
    mutation CreateProject($name: String!, $tags: [String!], $public: Boolean!) {
  createProject(name: $name, tags: $tags, public: $public) {
    uuid
    name
    tags
    ownerUserUuid
    insertedAt
    public
    featured
    archived
    documentFrameSize {
      width
      height
    }
  }
}
    `;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      name: // value for 'name'
 *      tags: // value for 'tags'
 *      public: // value for 'public'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const CreateProjectWithDocumentDocument = gql`
    mutation CreateProjectWithDocument($name: String!, $document: String!, $tags: [String!]) {
  createProjectWithDocument(name: $name, document: $document, tags: $tags) {
    uuid
    name
    tags
    ownerUserUuid
    insertedAt
    public
    featured
    archived
    documentFrameSize {
      width
      height
    }
  }
}
    `;
export type CreateProjectWithDocumentMutationFn = Apollo.MutationFunction<CreateProjectWithDocumentMutation, CreateProjectWithDocumentMutationVariables>;

/**
 * __useCreateProjectWithDocumentMutation__
 *
 * To run a mutation, you first call `useCreateProjectWithDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectWithDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectWithDocumentMutation, { data, loading, error }] = useCreateProjectWithDocumentMutation({
 *   variables: {
 *      name: // value for 'name'
 *      document: // value for 'document'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useCreateProjectWithDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectWithDocumentMutation, CreateProjectWithDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectWithDocumentMutation, CreateProjectWithDocumentMutationVariables>(CreateProjectWithDocumentDocument, options);
      }
export type CreateProjectWithDocumentMutationHookResult = ReturnType<typeof useCreateProjectWithDocumentMutation>;
export type CreateProjectWithDocumentMutationResult = Apollo.MutationResult<CreateProjectWithDocumentMutation>;
export type CreateProjectWithDocumentMutationOptions = Apollo.BaseMutationOptions<CreateProjectWithDocumentMutation, CreateProjectWithDocumentMutationVariables>;
export const PublishProjectDocument = gql`
    mutation PublishProject($uuid: String!) {
  publishProject(uuid: $uuid) {
    uuid
  }
}
    `;
export type PublishProjectMutationFn = Apollo.MutationFunction<PublishProjectMutation, PublishProjectMutationVariables>;

/**
 * __usePublishProjectMutation__
 *
 * To run a mutation, you first call `usePublishProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishProjectMutation, { data, loading, error }] = usePublishProjectMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function usePublishProjectMutation(baseOptions?: Apollo.MutationHookOptions<PublishProjectMutation, PublishProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishProjectMutation, PublishProjectMutationVariables>(PublishProjectDocument, options);
      }
export type PublishProjectMutationHookResult = ReturnType<typeof usePublishProjectMutation>;
export type PublishProjectMutationResult = Apollo.MutationResult<PublishProjectMutation>;
export type PublishProjectMutationOptions = Apollo.BaseMutationOptions<PublishProjectMutation, PublishProjectMutationVariables>;
export const UpdateProjectDocument = gql`
    mutation UpdateProject($uuid: String!, $name: String, $public: Boolean, $featured: Boolean, $archived: Boolean, $documentFrameSize: DocumentFrameSizeUpdate) {
  updateProject(
    uuid: $uuid
    name: $name
    public: $public
    featured: $featured
    archived: $archived
    documentFrameSize: $documentFrameSize
  ) {
    uuid
    name
    public
    featured
    archived
    documentFrameSize {
      width
      height
    }
  }
}
    `;
export type UpdateProjectMutationFn = Apollo.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      name: // value for 'name'
 *      public: // value for 'public'
 *      featured: // value for 'featured'
 *      archived: // value for 'archived'
 *      documentFrameSize: // value for 'documentFrameSize'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, options);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const DuplicateProjectDocument = gql`
    mutation DuplicateProject($uuid: String!) {
  duplicateProject(uuid: $uuid) {
    uuid
  }
}
    `;
export type DuplicateProjectMutationFn = Apollo.MutationFunction<DuplicateProjectMutation, DuplicateProjectMutationVariables>;

/**
 * __useDuplicateProjectMutation__
 *
 * To run a mutation, you first call `useDuplicateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateProjectMutation, { data, loading, error }] = useDuplicateProjectMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDuplicateProjectMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateProjectMutation, DuplicateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateProjectMutation, DuplicateProjectMutationVariables>(DuplicateProjectDocument, options);
      }
export type DuplicateProjectMutationHookResult = ReturnType<typeof useDuplicateProjectMutation>;
export type DuplicateProjectMutationResult = Apollo.MutationResult<DuplicateProjectMutation>;
export type DuplicateProjectMutationOptions = Apollo.BaseMutationOptions<DuplicateProjectMutation, DuplicateProjectMutationVariables>;
export const ProjectCreatedDocument = gql`
    subscription ProjectCreated($subscriptionTopic: String!) {
  projectCreated(subscriptionTopic: $subscriptionTopic) {
    uuid
    name
    imageUrl
    tags
    ownerUserUuid
    insertedAt
    public
    featured
    archived
    documentFrameSize {
      width
      height
    }
  }
}
    `;

/**
 * __useProjectCreatedSubscription__
 *
 * To run a query within a React component, call `useProjectCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProjectCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCreatedSubscription({
 *   variables: {
 *      subscriptionTopic: // value for 'subscriptionTopic'
 *   },
 * });
 */
export function useProjectCreatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<ProjectCreatedSubscription, ProjectCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ProjectCreatedSubscription, ProjectCreatedSubscriptionVariables>(ProjectCreatedDocument, options);
      }
export type ProjectCreatedSubscriptionHookResult = ReturnType<typeof useProjectCreatedSubscription>;
export type ProjectCreatedSubscriptionResult = Apollo.SubscriptionResult<ProjectCreatedSubscription>;
export const ProjectDuplicatedDocument = gql`
    subscription ProjectDuplicated($subscriptionTopic: String!) {
  projectDuplicated(subscriptionTopic: $subscriptionTopic) {
    uuid
    name
    imageUrl
    tags
    ownerUserUuid
    insertedAt
    public
    featured
    archived
    documentFrameSize {
      width
      height
    }
  }
}
    `;

/**
 * __useProjectDuplicatedSubscription__
 *
 * To run a query within a React component, call `useProjectDuplicatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProjectDuplicatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectDuplicatedSubscription({
 *   variables: {
 *      subscriptionTopic: // value for 'subscriptionTopic'
 *   },
 * });
 */
export function useProjectDuplicatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<ProjectDuplicatedSubscription, ProjectDuplicatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ProjectDuplicatedSubscription, ProjectDuplicatedSubscriptionVariables>(ProjectDuplicatedDocument, options);
      }
export type ProjectDuplicatedSubscriptionHookResult = ReturnType<typeof useProjectDuplicatedSubscription>;
export type ProjectDuplicatedSubscriptionResult = Apollo.SubscriptionResult<ProjectDuplicatedSubscription>;
export const ProjectUpdatedDocument = gql`
    subscription ProjectUpdated($subscriptionTopic: String!) {
  projectUpdated(subscriptionTopic: $subscriptionTopic) {
    topicChanged
    projectUpdated {
      uuid
      name
      imageUrl
      tags
      ownerUserUuid
      insertedAt
      public
      featured
      archived
      documentFrameSize {
        width
        height
      }
    }
  }
}
    `;

/**
 * __useProjectUpdatedSubscription__
 *
 * To run a query within a React component, call `useProjectUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProjectUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectUpdatedSubscription({
 *   variables: {
 *      subscriptionTopic: // value for 'subscriptionTopic'
 *   },
 * });
 */
export function useProjectUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<ProjectUpdatedSubscription, ProjectUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ProjectUpdatedSubscription, ProjectUpdatedSubscriptionVariables>(ProjectUpdatedDocument, options);
      }
export type ProjectUpdatedSubscriptionHookResult = ReturnType<typeof useProjectUpdatedSubscription>;
export type ProjectUpdatedSubscriptionResult = Apollo.SubscriptionResult<ProjectUpdatedSubscription>;
export const GetReactionsDocument = gql`
    query GetReactions($projectUuid: String!, $userUuid: String, $reactionType: String, $cursorAfter: String) {
  reactions(
    projectUuid: $projectUuid
    userUuid: $userUuid
    reactionType: $reactionType
    cursorAfter: $cursorAfter
  ) {
    metadata {
      after
      before
      limit
    }
    entries {
      id
      reactionType
      projectUuid
      userUuid
      adobeUserDisplayName
      adobeUserAvatarUrl
      insertedAt
      updatedAt
    }
    uniqueReactions {
      reactionType
      total
    }
    totalReactions
    userReaction {
      reactionType
    }
  }
}
    `;

/**
 * __useGetReactionsQuery__
 *
 * To run a query within a React component, call `useGetReactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReactionsQuery({
 *   variables: {
 *      projectUuid: // value for 'projectUuid'
 *      userUuid: // value for 'userUuid'
 *      reactionType: // value for 'reactionType'
 *      cursorAfter: // value for 'cursorAfter'
 *   },
 * });
 */
export function useGetReactionsQuery(baseOptions: Apollo.QueryHookOptions<GetReactionsQuery, GetReactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReactionsQuery, GetReactionsQueryVariables>(GetReactionsDocument, options);
      }
export function useGetReactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReactionsQuery, GetReactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReactionsQuery, GetReactionsQueryVariables>(GetReactionsDocument, options);
        }
export type GetReactionsQueryHookResult = ReturnType<typeof useGetReactionsQuery>;
export type GetReactionsLazyQueryHookResult = ReturnType<typeof useGetReactionsLazyQuery>;
export type GetReactionsQueryResult = Apollo.QueryResult<GetReactionsQuery, GetReactionsQueryVariables>;
export const CreateReactionDocument = gql`
    mutation CreateReaction($projectUuid: String!, $userUuid: String!, $reactionType: String) {
  createReaction(
    projectUuid: $projectUuid
    userUuid: $userUuid
    reactionType: $reactionType
  ) {
    id
    reactionType
    projectUuid
    userUuid
  }
}
    `;
export type CreateReactionMutationFn = Apollo.MutationFunction<CreateReactionMutation, CreateReactionMutationVariables>;

/**
 * __useCreateReactionMutation__
 *
 * To run a mutation, you first call `useCreateReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReactionMutation, { data, loading, error }] = useCreateReactionMutation({
 *   variables: {
 *      projectUuid: // value for 'projectUuid'
 *      userUuid: // value for 'userUuid'
 *      reactionType: // value for 'reactionType'
 *   },
 * });
 */
export function useCreateReactionMutation(baseOptions?: Apollo.MutationHookOptions<CreateReactionMutation, CreateReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReactionMutation, CreateReactionMutationVariables>(CreateReactionDocument, options);
      }
export type CreateReactionMutationHookResult = ReturnType<typeof useCreateReactionMutation>;
export type CreateReactionMutationResult = Apollo.MutationResult<CreateReactionMutation>;
export type CreateReactionMutationOptions = Apollo.BaseMutationOptions<CreateReactionMutation, CreateReactionMutationVariables>;
export const UpdateReactionDocument = gql`
    mutation UpdateReaction($projectUuid: String!, $userUuid: String!, $reactionType: String!) {
  updateReaction(
    projectUuid: $projectUuid
    userUuid: $userUuid
    reactionType: $reactionType
  ) {
    reactionType
    projectUuid
    userUuid
    uniqueReactions {
      reactionType
      total
    }
  }
}
    `;
export type UpdateReactionMutationFn = Apollo.MutationFunction<UpdateReactionMutation, UpdateReactionMutationVariables>;

/**
 * __useUpdateReactionMutation__
 *
 * To run a mutation, you first call `useUpdateReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReactionMutation, { data, loading, error }] = useUpdateReactionMutation({
 *   variables: {
 *      projectUuid: // value for 'projectUuid'
 *      userUuid: // value for 'userUuid'
 *      reactionType: // value for 'reactionType'
 *   },
 * });
 */
export function useUpdateReactionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReactionMutation, UpdateReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReactionMutation, UpdateReactionMutationVariables>(UpdateReactionDocument, options);
      }
export type UpdateReactionMutationHookResult = ReturnType<typeof useUpdateReactionMutation>;
export type UpdateReactionMutationResult = Apollo.MutationResult<UpdateReactionMutation>;
export type UpdateReactionMutationOptions = Apollo.BaseMutationOptions<UpdateReactionMutation, UpdateReactionMutationVariables>;
export const DeleteReactionDocument = gql`
    mutation DeleteReaction($projectUuid: String!, $userUuid: String!, $reactionType: String!) {
  deleteReaction(
    projectUuid: $projectUuid
    userUuid: $userUuid
    reactionType: $reactionType
  ) {
    reactionType
    projectUuid
    userUuid
    uniqueReactions {
      reactionType
      total
    }
  }
}
    `;
export type DeleteReactionMutationFn = Apollo.MutationFunction<DeleteReactionMutation, DeleteReactionMutationVariables>;

/**
 * __useDeleteReactionMutation__
 *
 * To run a mutation, you first call `useDeleteReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReactionMutation, { data, loading, error }] = useDeleteReactionMutation({
 *   variables: {
 *      projectUuid: // value for 'projectUuid'
 *      userUuid: // value for 'userUuid'
 *      reactionType: // value for 'reactionType'
 *   },
 * });
 */
export function useDeleteReactionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReactionMutation, DeleteReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReactionMutation, DeleteReactionMutationVariables>(DeleteReactionDocument, options);
      }
export type DeleteReactionMutationHookResult = ReturnType<typeof useDeleteReactionMutation>;
export type DeleteReactionMutationResult = Apollo.MutationResult<DeleteReactionMutation>;
export type DeleteReactionMutationOptions = Apollo.BaseMutationOptions<DeleteReactionMutation, DeleteReactionMutationVariables>;
export const ReactionCreatedDocument = gql`
    subscription ReactionCreated {
  reactionCreated {
    id
    reactionType
    projectUuid
    userUuid
    uniqueReactions {
      reactionType
      total
    }
  }
}
    `;

/**
 * __useReactionCreatedSubscription__
 *
 * To run a query within a React component, call `useReactionCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useReactionCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReactionCreatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useReactionCreatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ReactionCreatedSubscription, ReactionCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ReactionCreatedSubscription, ReactionCreatedSubscriptionVariables>(ReactionCreatedDocument, options);
      }
export type ReactionCreatedSubscriptionHookResult = ReturnType<typeof useReactionCreatedSubscription>;
export type ReactionCreatedSubscriptionResult = Apollo.SubscriptionResult<ReactionCreatedSubscription>;
export const ReactionDeletedDocument = gql`
    subscription ReactionDeleted {
  reactionDeleted {
    reactionType
    projectUuid
    userUuid
    uniqueReactions {
      reactionType
      total
    }
  }
}
    `;

/**
 * __useReactionDeletedSubscription__
 *
 * To run a query within a React component, call `useReactionDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useReactionDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReactionDeletedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useReactionDeletedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ReactionDeletedSubscription, ReactionDeletedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ReactionDeletedSubscription, ReactionDeletedSubscriptionVariables>(ReactionDeletedDocument, options);
      }
export type ReactionDeletedSubscriptionHookResult = ReturnType<typeof useReactionDeletedSubscription>;
export type ReactionDeletedSubscriptionResult = Apollo.SubscriptionResult<ReactionDeletedSubscription>;
export const ReactionUpdatedDocument = gql`
    subscription ReactionUpdated {
  reactionUpdated {
    reactionType
    projectUuid
    userUuid
    uniqueReactions {
      reactionType
      total
    }
  }
}
    `;

/**
 * __useReactionUpdatedSubscription__
 *
 * To run a query within a React component, call `useReactionUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useReactionUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReactionUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useReactionUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ReactionUpdatedSubscription, ReactionUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ReactionUpdatedSubscription, ReactionUpdatedSubscriptionVariables>(ReactionUpdatedDocument, options);
      }
export type ReactionUpdatedSubscriptionHookResult = ReturnType<typeof useReactionUpdatedSubscription>;
export type ReactionUpdatedSubscriptionResult = Apollo.SubscriptionResult<ReactionUpdatedSubscription>;
export const GetAllUsersDocument = gql`
    query GetAllUsers {
  users {
    uuid
    isAnonymous
    adobeUserId
    adobeUserEmail
    adobeUserDisplayName
    adobeUserAvatarUrl
    preferences {
      color
    }
  }
}
    `;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
      }
export function useGetAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<GetAllUsersQuery, GetAllUsersQueryVariables>;
export const GetUserByAdobeUserIdDocument = gql`
    query GetUserByAdobeUserId($adobeUserId: String!) {
  userByAdobeUserId(adobeUserId: $adobeUserId) {
    uuid
    isAnonymous
    adobeUserId
    adobeUserEmail
    adobeUserDisplayName
    adobeUserAvatarUrl
    preferences {
      color
    }
  }
}
    `;

/**
 * __useGetUserByAdobeUserIdQuery__
 *
 * To run a query within a React component, call `useGetUserByAdobeUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByAdobeUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByAdobeUserIdQuery({
 *   variables: {
 *      adobeUserId: // value for 'adobeUserId'
 *   },
 * });
 */
export function useGetUserByAdobeUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserByAdobeUserIdQuery, GetUserByAdobeUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByAdobeUserIdQuery, GetUserByAdobeUserIdQueryVariables>(GetUserByAdobeUserIdDocument, options);
      }
export function useGetUserByAdobeUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByAdobeUserIdQuery, GetUserByAdobeUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByAdobeUserIdQuery, GetUserByAdobeUserIdQueryVariables>(GetUserByAdobeUserIdDocument, options);
        }
export type GetUserByAdobeUserIdQueryHookResult = ReturnType<typeof useGetUserByAdobeUserIdQuery>;
export type GetUserByAdobeUserIdLazyQueryHookResult = ReturnType<typeof useGetUserByAdobeUserIdLazyQuery>;
export type GetUserByAdobeUserIdQueryResult = Apollo.QueryResult<GetUserByAdobeUserIdQuery, GetUserByAdobeUserIdQueryVariables>;
export const GetUserByUuidDocument = gql`
    query GetUserByUuid($uuid: String!) {
  userByUuid(uuid: $uuid) {
    uuid
    isAnonymous
    adobeUserId
    adobeUserEmail
    adobeUserDisplayName
    adobeUserAvatarUrl
    preferences {
      color
      projectsPrivateDefault
    }
  }
}
    `;

/**
 * __useGetUserByUuidQuery__
 *
 * To run a query within a React component, call `useGetUserByUuidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByUuidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByUuidQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetUserByUuidQuery(baseOptions: Apollo.QueryHookOptions<GetUserByUuidQuery, GetUserByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByUuidQuery, GetUserByUuidQueryVariables>(GetUserByUuidDocument, options);
      }
export function useGetUserByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByUuidQuery, GetUserByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByUuidQuery, GetUserByUuidQueryVariables>(GetUserByUuidDocument, options);
        }
export type GetUserByUuidQueryHookResult = ReturnType<typeof useGetUserByUuidQuery>;
export type GetUserByUuidLazyQueryHookResult = ReturnType<typeof useGetUserByUuidLazyQuery>;
export type GetUserByUuidQueryResult = Apollo.QueryResult<GetUserByUuidQuery, GetUserByUuidQueryVariables>;
export const GetAdobeUserDocument = gql`
    query getAdobeUser($adobeUserId: String!) {
  adobeUser(adobeUserId: $adobeUserId) {
    uuid
    isAnonymous
    adobeUserId
    adobeUserEmail
    adobeUserDisplayName
    adobeUserAvatarUrl
    preferences {
      color
      projectsPrivateDefault
    }
    insertedAt
  }
}
    `;

/**
 * __useGetAdobeUserQuery__
 *
 * To run a query within a React component, call `useGetAdobeUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdobeUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdobeUserQuery({
 *   variables: {
 *      adobeUserId: // value for 'adobeUserId'
 *   },
 * });
 */
export function useGetAdobeUserQuery(baseOptions: Apollo.QueryHookOptions<GetAdobeUserQuery, GetAdobeUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdobeUserQuery, GetAdobeUserQueryVariables>(GetAdobeUserDocument, options);
      }
export function useGetAdobeUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdobeUserQuery, GetAdobeUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdobeUserQuery, GetAdobeUserQueryVariables>(GetAdobeUserDocument, options);
        }
export type GetAdobeUserQueryHookResult = ReturnType<typeof useGetAdobeUserQuery>;
export type GetAdobeUserLazyQueryHookResult = ReturnType<typeof useGetAdobeUserLazyQuery>;
export type GetAdobeUserQueryResult = Apollo.QueryResult<GetAdobeUserQuery, GetAdobeUserQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($adobeUserId: String!, $adobeUserEmail: String!, $adobeUserDisplayName: String!, $adobeUserAvatarUrl: String!, $preferences: UserPreferencesMutation) {
  createUser(
    adobeUserId: $adobeUserId
    adobeUserEmail: $adobeUserEmail
    adobeUserDisplayName: $adobeUserDisplayName
    adobeUserAvatarUrl: $adobeUserAvatarUrl
    preferences: $preferences
  ) {
    uuid
    isAnonymous
    adobeUserId
    adobeUserEmail
    adobeUserDisplayName
    adobeUserAvatarUrl
    preferences {
      color
      projectsPrivateDefault
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      adobeUserId: // value for 'adobeUserId'
 *      adobeUserEmail: // value for 'adobeUserEmail'
 *      adobeUserDisplayName: // value for 'adobeUserDisplayName'
 *      adobeUserAvatarUrl: // value for 'adobeUserAvatarUrl'
 *      preferences: // value for 'preferences'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const CreateAnonymousUserDocument = gql`
    mutation CreateAnonymousUser {
  createAnonymousUser {
    uuid
    isAnonymous
    adobeUserId
    adobeUserEmail
    adobeUserDisplayName
    adobeUserAvatarUrl
    preferences {
      color
    }
  }
}
    `;
export type CreateAnonymousUserMutationFn = Apollo.MutationFunction<CreateAnonymousUserMutation, CreateAnonymousUserMutationVariables>;

/**
 * __useCreateAnonymousUserMutation__
 *
 * To run a mutation, you first call `useCreateAnonymousUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnonymousUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnonymousUserMutation, { data, loading, error }] = useCreateAnonymousUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateAnonymousUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnonymousUserMutation, CreateAnonymousUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAnonymousUserMutation, CreateAnonymousUserMutationVariables>(CreateAnonymousUserDocument, options);
      }
export type CreateAnonymousUserMutationHookResult = ReturnType<typeof useCreateAnonymousUserMutation>;
export type CreateAnonymousUserMutationResult = Apollo.MutationResult<CreateAnonymousUserMutation>;
export type CreateAnonymousUserMutationOptions = Apollo.BaseMutationOptions<CreateAnonymousUserMutation, CreateAnonymousUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($adobeUserId: String, $adobeUserEmail: String, $adobeUserDisplayName: String, $adobeUserAvatarUrl: String, $preferences: UserPreferencesMutation) {
  updateUser(
    adobeUserId: $adobeUserId
    adobeUserEmail: $adobeUserEmail
    adobeUserDisplayName: $adobeUserDisplayName
    adobeUserAvatarUrl: $adobeUserAvatarUrl
    preferences: $preferences
  ) {
    uuid
    isAnonymous
    adobeUserId
    adobeUserEmail
    adobeUserDisplayName
    adobeUserAvatarUrl
    preferences {
      color
      projectsPrivateDefault
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      adobeUserId: // value for 'adobeUserId'
 *      adobeUserEmail: // value for 'adobeUserEmail'
 *      adobeUserDisplayName: // value for 'adobeUserDisplayName'
 *      adobeUserAvatarUrl: // value for 'adobeUserAvatarUrl'
 *      preferences: // value for 'preferences'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;