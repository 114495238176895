import React, { useContext } from 'react'
import { LocaleContext, SupportedLocales, LocaleString } from './LocaleProvider'
import { MenuItem, Picker } from 'ui'
import { useTranslations } from 'use-intl'

const LocaleSwitcher = () => {
  const { activeLocale, changeApplicationLocale } = useContext(LocaleContext)
  const t = useTranslations()

  const handleTabChange = (event: any) => {
    const selectedLocale: LocaleString = event.target?.value
    changeApplicationLocale(selectedLocale)
  }

  return (
    <Picker
      id="locale-switcher"
      size="m"
      label={t(`locale:${activeLocale}`)}
      value={activeLocale}
      onchange={handleTabChange}>
      {SupportedLocales.map((localeString: LocaleString) => (
        <MenuItem
          selected={localeString === activeLocale}
          key={localeString}
          value={localeString}>
          {t(`locale:${localeString}`)}
        </MenuItem>
      ))}
    </Picker>
  )
}
export default LocaleSwitcher
