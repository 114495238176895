import * as Effects from 'redux-saga/effects'
import AdobeIMS from '@services/auth/IMS'
import {
  AspectRatio,
  ContentClass,
  generateImages,
  generateSimilarImages,
  resetState,
  setGeneratedImages,
  setImageGenerationStatus,
  setPropertyState,
  selectGeneratedImage,
  setVisibleToast,
  stopImagGeneration,
  setShowOnboardingDialog,
  setShowLimitedFreeTimeToast,
  addDownloadURL,
  setDownloadURLs
} from '@store/slices/fireflySlice'
import { EngineCaptureImagePayload } from '@services/engine/types'
import createEventChannel from '@store/middleware/createEventChannel'
import Context from '@store/middleware/context'
import { EventChannel } from 'redux-saga'
import { exportCapture, setCaptureStatus } from '@store/slices/sceneSlice'
import { PayloadType, RootState } from '@store/store'
import { ITokenInformation } from '@identity/imslib/adobe-id/custom-types/CustomTypes'
import { setShowFireflyPopover } from '@store/slices/projectSlice'
import { PayloadAction } from '@reduxjs/toolkit'
import { setPropertyState as setScenePropertyState } from '@store/slices/sceneSlice'
import * as Sentry from '@sentry/nextjs'
import {
  LocalStorageBooleanValue,
  LocalStorageKey
} from 'constants/localStorage'
import { saveAs } from 'file-saver'

// Allowed options (2688, 1536), (896, 1152), (1344, 768), (2304, 1792), (1152, 896), (2048, 2048), (1792, 2304), (1024, 1024)
const AspectRatioSizeMap = {
  [AspectRatio.LANDSCAPE]: { width: 2304, height: 1792 },
  [AspectRatio.PORTRAIT]: { width: 1792, height: 2304 },
  [AspectRatio.SQUARE]: { width: 2048, height: 2048 },
  [AspectRatio.WIDESCREEN]: { width: 2688, height: 1536 }
} as const

// https://github.com/redux-saga/redux-saga/issues/2018
const { all, call, put, select, takeEvery, take, fork, race, delay }: any =
  Effects

let generateImgApiReqAbortController: AbortController
let generateSimilarImgApiReqAbortController: AbortController

function* handleGenerateImages() {
  while (true) {
    try {
      yield take(generateImages.type)

      const { prompt }: RootState['firefly'] = yield select(
        (state: RootState) => state.firefly
      )
      if (!prompt) {
        throw new Error('Firefly - Prompt required generating firefly img')
      }

      yield put(setImageGenerationStatus('generating-new-images'))

      // delay is required because the main UI thread gets blocked when the engine exports images which prevents the loading spinner to start it's animation until exporting is completed
      yield delay(1000)

      yield put(exportCapture({ format: 'reference' }))

      generateImgApiReqAbortController = new AbortController()

      const { cancel }: any = yield race({
        task: call(initializeCaptureChannel),
        cancel: take(stopImagGeneration.type)
      })

      if (cancel) {
        generateImgApiReqAbortController?.abort()
      }
    } catch (error) {
      yield put(setVisibleToast('error'))
      yield put(stopImagGeneration())
      console.error('handleGenerateImages error', error)
    }
  }
}

/**
 * When firefly view is displayed, frame needs to be enabled with the same size as the selected aspect ratio from the firefly panel
 * When firefly view is hidden, reset back to original frame property values
 */
function* changeFramePropertiesOnPopoverChange({
  payload
}: PayloadAction<PayloadType<typeof setShowFireflyPopover>>) {
  const { firefly, scene }: RootState = yield select(
    (state: RootState) => state
  )

  const { aspectRatio, previousSceneFramePropertiesOnShow } = firefly
  const { frameSize, frameType, frameEnabled } = scene

  if (payload) {
    // Store previous value of frame properties when showing firefly view to revert changes back to original values when firefly view is not displayed
    yield put(
      setPropertyState({
        key: 'previousSceneFramePropertiesOnShow',
        value: {
          frameEnabled,
          frameSize,
          frameType
        }
      })
    )

    const fireflySize = AspectRatioSizeMap[aspectRatio]

    if (frameEnabled) {
      yield put(
        setScenePropertyState({
          key: 'frameEnabled',
          value: false
        })
      )
    }

    // Update scene frame properties because the depth img generated from the engine should use the same size as the selected aspect ratio from the firefly panel
    if (frameType !== 0) {
      yield put(
        setScenePropertyState({
          key: 'frameType',
          value: 0
        })
      )
    }

    yield put(
      setScenePropertyState({
        key: 'frameSize',
        value: { h: fireflySize.height, w: fireflySize.width }
      })
    )

    return
  }

  // Revert frame properties back to original values before firefly view is shown
  if (previousSceneFramePropertiesOnShow) {
    if (previousSceneFramePropertiesOnShow.frameEnabled !== frameEnabled) {
      yield put(
        setScenePropertyState({
          key: 'frameEnabled',
          value: previousSceneFramePropertiesOnShow.frameEnabled
        })
      )
    }

    if (previousSceneFramePropertiesOnShow.frameType !== frameType) {
      yield put(
        setScenePropertyState({
          key: 'frameType',
          value: previousSceneFramePropertiesOnShow.frameType
        })
      )
    }

    yield put(
      setScenePropertyState({
        key: 'frameSize',
        value: previousSceneFramePropertiesOnShow.frameSize
      })
    )

    yield put(
      setPropertyState({
        key: 'previousSceneFramePropertiesOnShow',
        value: null
      })
    )
  }

  yield put(setVisibleToast('none'))
}

function* handleSetPropertyState({
  payload
}: PayloadAction<PayloadType<typeof setPropertyState>>) {
  if (payload.key === 'aspectRatio') {
    // Engine frame size should always be set to the same value as aspect ratio size
    const { height, width } = AspectRatioSizeMap[payload.value]
    yield put(
      setScenePropertyState({
        key: 'frameSize',
        value: { h: height, w: width }
      })
    )
  }
}

function* handleResetState() {
  const { styleImageReference }: RootState['firefly'] = yield select(
    (state: RootState) => state.firefly
  )

  if (styleImageReference?.type === 'user-upload') {
    yield URL.revokeObjectURL(styleImageReference.url)
  }

  yield clearDownloadURLs()
}

function* initializeCaptureChannel() {
  const dataChannel: EventChannel<EngineCaptureImagePayload> = yield call(
    createEventChannel,
    Context.Engine?.EngineDataChannel,
    'capture_reference_image_data'
  )

  while (true) {
    try {
      yield put(
        setCaptureStatus({
          format: 'reference',
          status: 'completed'
        })
      )

      const {
        objectURL,
        depthObjectURL,
        colorObjectURL
      }: EngineCaptureImagePayload = yield take(dataChannel)
      // saveAs(objectURL, 'object.png')
      // saveAs(depthObjectURL, 'depth.png')
      // saveAs(colorObjectURL, 'color.png')

      yield fork(
        handleReferenceCapture,
        objectURL,
        depthObjectURL,
        colorObjectURL
      )
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)
      yield put(stopImagGeneration())
    }
  }
}

function* handleReferenceCapture(
  edgeBlobUrl: URL,
  depthBlobUrl: URL,
  colorBlobUrl: URL
) {
  const {
    aspectRatio,
    contentClass,
    prompt,
    stylePresets,
    styleImageReference,
    imgReference,
    structureStrength,
    styleStrength,
    guideStrength,
    visualIntensity
  }: RootState['firefly'] = yield select((state: RootState) => state.firefly)

  const accessToken: ITokenInformation | null = yield call(
    AdobeIMS.getAccessTokenAsync
  )
  if (!accessToken || !process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID) {
    throw new Error('Firefly - Required fields missing')
  }
  try {
    if (!edgeBlobUrl) throw new Error('Firefly - Img missing')
    const styleImgReferenceBlob: null | Blob = styleImageReference
      ? yield call(() => fetch(styleImageReference.url).then(res => res.blob()))
      : null

    const edgeBlob: Blob = yield call(() =>
      fetch(edgeBlobUrl).then(res => res.blob())
    )

    // we should always have a depth image regardless if we use it or not
    if (!depthBlobUrl) throw new Error('Firefly - Img missing')

    const depthBlob: Blob = yield call(() =>
      fetch(depthBlobUrl).then(res => res.blob())
    )

    const colorBlob: Blob = yield call(() =>
      fetch(colorBlobUrl).then(res => res.blob())
    )

    const imgGuidances: StoreImgGuidancesResponse = yield call(
      storeImgGuidances,
      [
        {
          type: 'depth',
          // edgeBlob can be used as a reference as well if 'depth' is not enabled
          blob: imgReference === 'depth' && depthBlobUrl ? depthBlob : edgeBlob
        },
        ...(styleImgReferenceBlob
          ? [{ type: 'style', blob: styleImgReferenceBlob }]
          : []),
        { type: 'canny', blob: edgeBlob },
        { type: 'color', blob: colorBlob }
      ],
      accessToken.token,
      process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID
    )

    const depthDataImgId = imgGuidances?.find(
      ({ type }) => type === 'depth'
    )?.imgId
    const styleDataImgId = imgGuidances?.find(
      ({ type }) => type === 'style'
    )?.imgId
    const cannyDataImgId = imgGuidances?.find(
      ({ type }) => type === 'canny'
    )?.imgId
    const colorDataImgId = imgGuidances?.find(
      ({ type }) => type === 'color'
    )?.imgId

    if (!depthDataImgId) {
      throw new Error('Firefly - Missing ImgId')
    }

    const body = {
      prompt,
      ...(contentClass !== ContentClass.AUTO && { contentClass }),
      size: AspectRatioSizeMap[aspectRatio],
      visualIntensity,
      locale: 'en-US',
      modelVersion: 'image3_fast',
      seeds: generateUniqueRandomNumbers(100, 45000, 4),
      controlData: {
        depthData: {
          // use estimator when packed depth image is not available
          mode: imgReference === 'depth' ? 'unpack_rgba' : 'estimator',
          adherenceThresholdOverride: structureStrength,
          referenceImage: {
            id: depthDataImgId
          }
        },
        // we can only use edge image for cannyData when depth is enabled
        ...(imgReference === 'depth' && {
          cannyData: {
            adherenceThresholdOverride: structureStrength,
            mode: 'gray_thresholding',
            referenceImage: {
              id: cannyDataImgId
            }
          }
        })
      },
      ...(guideStrength && {
        editData: {
          guideImage: {
            id: colorDataImgId
          },
          guideStrength
        }
      }),
      styles: {
        presets: stylePresets,
        strength: styleStrength > 0 ? styleStrength : 1,
        ...(styleDataImgId && { referenceImage: { id: styleDataImgId } })
      }
    }

    const generatedImgResponse: GeneratedImagesResponse = yield call(
      generateFireflyImages,
      JSON.stringify(body),
      accessToken.token
    )

    if (generatedImgResponse.error_code) {
      if (generatedImgResponse.error_code?.includes('prompt')) {
        yield put(setVisibleToast('warning'))
      } else {
        yield put(setVisibleToast('error'))
      }
      return
    }

    if (generatedImgResponse.outputs.length) {
      const images = generatedImgResponse.outputs.map(({ image }, index) => ({
        url: image.presignedUrl,
        selected: index === 0
      }))

      yield put(setGeneratedImages(images))
    }

    if (generatedImgResponse.promptHasDeniedWords) {
      yield put(setVisibleToast('warning'))
    } else {
      yield put(setVisibleToast('completed'))
    }
  } catch (error) {
    yield put(setVisibleToast('error'))
    console.error('handleGenerateImages error', error)
  } finally {
    yield put(stopImagGeneration())
  }
}

function* handleStopImagGeneration() {
  yield put(setImageGenerationStatus('idle'))
}

function* handleGenerateSimilarImages({
  payload
}: PayloadAction<PayloadType<typeof generateSimilarImages>>) {
  try {
    const accessToken: ITokenInformation | null = yield call(
      AdobeIMS.getAccessTokenAsync
    )
    if (!accessToken || !process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID) {
      throw new Error('Firefly - Required fields missing')
    }

    const { aspectRatio, generatedImages }: RootState['firefly'] = yield select(
      (state: RootState) => state.firefly
    )

    const selectedImage = generatedImages.find(({ selected }) => selected)
    if (!selectedImage) throw new Error('Firefly - No image selected')

    yield put(setImageGenerationStatus('generating-similar-images'))
    yield put(
      setGeneratedImages([
        selectedImage,
        { selected: false },
        { selected: false },
        { selected: false }
      ])
    )

    generateSimilarImgApiReqAbortController = new AbortController()

    const blob: Blob = yield call(() =>
      fetch(payload.imgUrl).then(res => res.blob())
    )

    const storedImage: StoreImgGuidancesResponse = yield call(
      storeImgGuidances,
      [
        {
          type: 'ref',
          blob
        }
      ],
      accessToken.token,
      process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID
    )

    const storedImgId = storedImage.length && storedImage[0].imgId

    if (!storedImgId) {
      throw new Error('Firefly - Missing ImgId')
    }

    const body = {
      image: {
        id: storedImgId
      },
      seeds: generateUniqueRandomNumbers(100, 45000, 3),
      size: AspectRatioSizeMap[aspectRatio]
    }

    const { task, cancel }: { task: GeneratedImagesResponse; cancel: any } =
      yield race({
        task: call(
          generateSimilarFireflyImages,
          JSON.stringify(body),
          accessToken.token
        ),
        cancel: take(stopImagGeneration.type)
      })

    if (cancel) {
      generateSimilarImgApiReqAbortController?.abort()
    } else {
      const images = task.outputs.map(({ image }) => ({
        url: image.presignedUrl,
        selected: false
      }))

      yield put(setGeneratedImages([selectedImage, ...images]))
      yield put(setVisibleToast('completed'))
    }
  } catch (error) {
    console.error('handleGenerateSimilarImages error', error)
    yield put(setVisibleToast('error'))
  } finally {
    yield put(stopImagGeneration())
  }
}

function* handleSelectGeneratedImage({
  payload
}: PayloadAction<PayloadType<typeof selectGeneratedImage>>) {
  const { generatedImages }: RootState['firefly'] = yield select(
    (state: RootState) => state.firefly
  )

  yield put(
    setGeneratedImages(
      generatedImages.map(img => ({
        ...img,
        selected: img.url === payload.url
      }))
    )
  )
}

function* showOnboardingDialog({
  payload
}: PayloadAction<PayloadType<typeof setShowFireflyPopover>>) {
  const showOnboardingDialog =
    payload &&
    localStorage.getItem(LocalStorageKey.fireflyViewedOnboardingDialog) !==
      LocalStorageBooleanValue.TRUE

  if (showOnboardingDialog) {
    yield put(setShowOnboardingDialog(true))
  }
}

function* closeLimitedFreeTimeToastOnShow({
  payload
}: PayloadAction<PayloadType<typeof setShowLimitedFreeTimeToast>>) {
  if (payload) {
    yield delay(6000)
    yield put(setShowLimitedFreeTimeToast(false))
  }
}

function* handleAddDownloadURL({
  payload
}: PayloadAction<PayloadType<typeof addDownloadURL>>) {
  const { downloadURLs }: RootState['firefly'] = yield select(
    (state: RootState) => state.firefly
  )

  yield put(setDownloadURLs([...downloadURLs, payload]))
}

function* clearDownloadURLs() {
  const { downloadURLs }: RootState['firefly'] = yield select(
    (state: RootState) => state.firefly
  )

  downloadURLs.forEach(({ downloadURL }) => {
    URL.revokeObjectURL(downloadURL)
  })

  yield put(setDownloadURLs([]))
}

export default function* fireflySaga() {
  yield all([
    handleGenerateImages(),
    takeEvery(setShowFireflyPopover.type, changeFramePropertiesOnPopoverChange),
    takeEvery(setShowFireflyPopover.type, showOnboardingDialog),
    takeEvery(setPropertyState.type, handleSetPropertyState),
    takeEvery(resetState.type, handleResetState),
    takeEvery(stopImagGeneration.type, handleStopImagGeneration),
    takeEvery(generateSimilarImages.type, handleGenerateSimilarImages),
    takeEvery(selectGeneratedImage.type, handleSelectGeneratedImage),
    takeEvery(
      setShowLimitedFreeTimeToast.type,
      closeLimitedFreeTimeToastOnShow
    ),
    takeEvery(addDownloadURL.type, handleAddDownloadURL),
    takeEvery(setGeneratedImages.type, clearDownloadURLs)
  ])
}

async function storeImgGuidance(
  blob: Blob,
  token: string,
  apiKey: string
): Promise<string | undefined> {
  return await fetch(
    `${process.env.NEXT_PUBLIC_CLIENT_FIREFLY_IMAGE_ENDPOINT}`,
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        accept: 'application/json',
        'accept-language': 'en-US,en;q=0.9,pt;q=0.8',
        authorization: `Bearer ${token}`,
        'content-type': 'image/png',
        'sec-fetch-dest': 'empty',
        'sec-fetch-mode': 'cors',
        'sec-fetch-site': 'cross-site',
        'x-api-key': apiKey
      },
      body: blob,
      method: 'POST',
      mode: 'cors'
    }
  )
    .then(res => res.json())
    .then(res => res?.images?.length && res.images[0]['id'])
}

type ImgGuidance = {
  blob: Blob
  type: string
}

type StoreImgGuidancesResponse = Array<{
  type: string
  imgId: string | undefined
}>

async function storeImgGuidances(
  guidances: ImgGuidance[],
  token: string,
  apiKey: string
): Promise<StoreImgGuidancesResponse> {
  return Promise.all(
    guidances.map(async ({ blob, type }) => {
      const imgId = await storeImgGuidance(blob, token, apiKey)
      return { type, imgId }
    })
  )
}

type GeneratedImagesResponse = {
  outputs: Array<{
    seed: number
    image: { id: string; presignedUrl: string }
  }>
  promptHasDeniedWords?: boolean
  size: { width: number; height: number }
  error_code?: string
}

async function generateFireflyImages(
  body: string,
  token: string
): Promise<GeneratedImagesResponse> {
  return await fetch(
    `${process.env.NEXT_PUBLIC_CLIENT_FIREFLY_GENERATE_ENDPOINT}`,
    {
      headers: {
        accept: 'application/json',
        'accept-language': 'en-US,en;q=0.9,pt;q=0.8',
        authorization: `Bearer ${token}`,
        'content-type': 'application/json',
        'sec-fetch-dest': 'empty',
        'sec-fetch-mode': 'cors',
        'sec-fetch-site': 'cross-site',
        'x-api-key': process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID!
      },
      body,
      method: 'POST',
      mode: 'cors',
      signal: generateImgApiReqAbortController?.signal
    }
  ).then(res => res.json())
}

async function generateSimilarFireflyImages(
  body: string,
  token: string
): Promise<GeneratedImagesResponse> {
  return await fetch(
    `${process.env.NEXT_PUBLIC_CLIENT_FIREFLY_GENERATE_SIMILAR_ENDPOINT}`,
    {
      headers: {
        accept: 'application/json',
        'accept-language': 'en-US,en;q=0.9,pt;q=0.8',
        authorization: `Bearer ${token}`,
        'content-type': 'application/json',
        'sec-fetch-dest': 'empty',
        'sec-fetch-mode': 'cors',
        'sec-fetch-site': 'cross-site',
        'x-api-key': process.env.NEXT_PUBLIC_ADOBE_IMS_CLIENT_ID!
      },
      body,
      method: 'POST',
      mode: 'cors',
      signal: generateSimilarImgApiReqAbortController?.signal
    }
  ).then(res => res.json())
}

function generateUniqueRandomNumbers(
  min: number,
  max: number,
  total: number
): number[] {
  if (total > max - min + 1) {
    console.error('Total count exceeds range of possible unique numbers')
    return []
  }

  const uniqueNumbers: number[] = []
  while (uniqueNumbers.length < total) {
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min
    if (!uniqueNumbers.includes(randomNumber)) {
      uniqueNumbers.push(randomNumber)
    }
  }
  return uniqueNumbers
}
