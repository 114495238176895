import { FC } from 'react'
import { DialogBase, Dialog, Button } from 'ui'
import { useFirefly } from '@hooks/useFirefly'
import { useTranslations } from 'use-intl'

const FireflyEmptySceneDialog: FC = () => {
  const t = useTranslations()
  const { showEmptySceneDialog, setShowEmptySceneDialog } = useFirefly()

  function handleClose() {
    setShowEmptySceneDialog(false)
  }

  if (!showEmptySceneDialog) return null
  return (
    <DialogBase underlay open={showEmptySceneDialog}>
      <Dialog size="s" noDivider style={{ width: 480 }}>
        <h2 slot="heading">{t('studio:firefly:emptyScene:header')}</h2>
        <p>{t('studio:firefly:emptyScene:desc')}</p>
        <p>
          <strong>{t('studio:firefly:emptyScene:solution')}</strong>
        </p>
        <Button onClick={handleClose} variant="accent" slot="button">
          {t('studio:firefly:emptyScene:ok')}
        </Button>
      </Dialog>
    </DialogBase>
  )
}

export default FireflyEmptySceneDialog
