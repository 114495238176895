import React, { FC, ReactNode } from 'react'
import { useProjectActions, useProjectState } from '@hooks/useProject'
import { ProjectState } from '@store/slices/projectSlice'
import { LibraryViewHeader } from './constants'
import PanelHeader from '@components/panel/PanelHeader'
import PanelCard from '@components/panel/PanelCard'
import UserLibraryMaterialItems from './UserLibraryMaterialItems'
import LibraryThreeDShapesItems from './LibraryThreeDShapesItems'
import UserLibraryBackgroundItems from './UserLibraryBackgroundItems'
import { useTranslations } from 'use-intl'

const LibraryItems: Partial<
  Record<ProjectState['selectedLibraryView'], ReactNode>
> = {
  'neo-three-d-shapes': <LibraryThreeDShapesItems />,
  'user-background': <UserLibraryBackgroundItems />,
  'user-material': <UserLibraryMaterialItems />
}

const LibraryFullAssetView: FC = () => {
  const t = useTranslations()
  const selectedLibraryView = useProjectState('selectedLibraryView')
  const { setSelectedLibraryView } = useProjectActions()

  const headers: Record<ProjectState['selectedLibraryView'], string> = {
    'tabs-panel': 'studio:library',
    'neo-three-d-shapes': 'studio:library:neo3dShapes',
    'user-background': 'studio:library:userBackground',
    'user-material': 'studio:library:userMaterial'
  }

  return (
    <>
      <PanelHeader
        onBackClick={() => setSelectedLibraryView('tabs-panel')}
        textAlign="center">
        {t(headers[selectedLibraryView])}
      </PanelHeader>

      <div style={{ padding: 12 }}>
        <PanelCard>{LibraryItems[selectedLibraryView]}</PanelCard>
      </div>
    </>
  )
}

export default LibraryFullAssetView
