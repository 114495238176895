import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import Background from '@components/propertiesPanel/Background'
import Frame from './Frame'
import PanelHeader from '@components/panel/PanelHeader'
import PanelContent from '@components/panel/PanelContent'
import Camera from './Camera'
import History from './History'
import { useStudioEnvironment } from '@hooks/useStudioEnvironment'
import { useTranslations } from 'use-intl'

const SceneProperties = () => {
  const t = useTranslations()
  const flags = useFlags()
  const { isDefaultEnvironment } = useStudioEnvironment()

  return (
    <>
      <PanelHeader textAlign="center">{t('scene:properties')}</PanelHeader>
      <PanelContent>
        {!flags['base-pf-ui-history-slider'] && <History />}
        {isDefaultEnvironment && <Frame />}
        <Background />
        <Camera />
      </PanelContent>
    </>
  )
}

export default SceneProperties
