import React, { FC, useMemo } from 'react'
import { ActionButton, ActionGroup, Tooltip, Divider, Icon } from 'ui'
import styles from '@styles/components/Toolbar.module.scss'
import {
  PrimitiveIds,
  ToolUI,
  useToolEvents,
  useTools
} from '@services/engine/useTools'
import { useUISettings } from '@services/engine/useUISettings'
import { DocumentEventObserver } from '@services/engine/documentEventObserver'
import CubeIcon from '/public/s2_icon_cube.svg'
import {
  useMediumMinimumSizePreference,
  useProjectActions,
  useProjectState
} from '@hooks/useProject'
import ThreeDTextIcon from 'public/s2_icon_3d_text.svg'
import { useStudioEnvironment } from '@hooks/useStudioEnvironment'
import { useTranslations } from 'use-intl'

const Toolbar: FC = () => {
  const t = useTranslations()
  const size = useMediumMinimumSizePreference()
  const { toolUIs, permanentToolID, isToolDraggable } = useTools()
  const showAddObjectRail = useProjectState('showAddObjectRail')
  const { setShowAddObjectRail } = useProjectActions()
  const { setMouseCaptureObject } = useUISettings()

  setMouseCaptureObject({
    enableMouseCapture: (enable: boolean): void => {
      DocumentEventObserver.instance()?.enableMouseCapture(enable)
    }
  })

  const permanentToolIDValue = permanentToolID()

  const isThreeDShapeToolActive = PrimitiveIds.some(
    primId => primId === permanentToolIDValue
  )

  const tools = toolUIs()

  const editAndSelectTools = useMemo(
    () =>
      tools?.filter((tool: ToolUI | undefined) => {
        const id = tool?.id()
        return id === 'select' || id === 'editShape'
      }),
    [tools]
  )

  const navigationTools = useMemo(
    () =>
      tools?.filter((tool: ToolUI | undefined) => {
        const id = tool?.id()
        switch (id) {
          case 'pan':
          case 'zoom':
          case 'orbit':
            return true
          default:
            return false
        }
      }),
    [tools]
  )

  const textTool = useMemo(
    () =>
      tools?.filter((tool: ToolUI | undefined) => {
        const id = tool?.id()
        return id === 'addPrimitive_Text'
      })[0],
    [tools]
  )

  const { handleOnToolClick, handleOnToolDragStart } = useToolEvents()

  return (
    <>
      <ActionGroup
        selects="single"
        emphasized
        selected={permanentToolIDValue}
        size={size}
        vertical
        quiet>
        {editAndSelectTools.map((tool: ToolUI | undefined, index) => {
          if (!tool) return null

          return (
            <ActionButton
              size={size}
              value={tool.id()}
              key={tool.id()}
              draggable={isToolDraggable(tool.id())}
              onClick={() => handleOnToolClick(tool)}
              ondragstart={() => handleOnToolDragStart(tool)}>
              {tool.icon()}
              <Tooltip selfManaged placement="right" className={styles.tooltip}>
                <div className={styles['tooltip-content']}>
                  {tool.tooltipLabel(t)}
                </div>
              </Tooltip>
            </ActionButton>
          )
        })}
      </ActionGroup>

      <ActionButton
        size={size}
        quiet
        onClick={() => setShowAddObjectRail(!showAddObjectRail)}
        id="three-d-shape-menu-trigger"
        selected={showAddObjectRail || isThreeDShapeToolActive}>
        <Icon slot="icon">
          <CubeIcon />
        </Icon>
      </ActionButton>

      {textTool && (
        <ActionButton
          size="m"
          draggable
          emphasized
          selected={permanentToolID() === 'addPrimitive_Text'}
          onClick={() => {
            handleOnToolClick(textTool)
          }}
          onDragStart={() => handleOnToolDragStart(textTool)}>
          <Icon slot="icon">
            <ThreeDTextIcon />
          </Icon>
          <Tooltip selfManaged placement="right">
            {textTool.tooltipLabel(t)}
          </Tooltip>
        </ActionButton>
      )}

      <Divider size="m" style={{ width: 20 }} />

      <ActionGroup
        selects="single"
        emphasized
        selected={permanentToolIDValue}
        size={size}
        vertical
        quiet>
        {navigationTools.map((tool: ToolUI | undefined) => {
          if (!tool) return null

          return (
            <ActionButton
              value={tool.id()}
              key={tool.id()}
              draggable={isToolDraggable(tool.id())}
              onClick={() => handleOnToolClick(tool)}
              ondragstart={() => handleOnToolDragStart(tool)}>
              {tool.icon()}
              <Tooltip selfManaged placement="right" className={styles.tooltip}>
                <div className={styles['tooltip-content']}>
                  {tool.tooltipLabel(t)}
                </div>
              </Tooltip>
            </ActionButton>
          )
        })}
      </ActionGroup>
    </>
  )
}

export default Toolbar
