import React, { ComponentProps, FC } from 'react'
import { ActionButton, Icon } from 'ui'
import AiGenerateIcon from '/public/s2_icon_ai_generate.svg'
import {
  useMediumMinimumSizePreference,
  useProjectActions
} from '@hooks/useProject'
import { StudioPanel } from '@store/slices/projectSlice'
import { useIsEmptyScene } from '@hooks/useScene'
import { useFirefly } from '@hooks/useFirefly'
import { useTranslations } from 'use-intl'

const FireflyShowGeneratePromptButton: FC<
  ComponentProps<typeof ActionButton>
> = props => {
  const t = useTranslations()
  const size = useMediumMinimumSizePreference()

  const { setShowFireflyPopover, setOpenedPanel } = useProjectActions()

  const { setShowEmptySceneDialog } = useFirefly()

  const isEmptyScene = useIsEmptyScene()

  function handleOnClick() {
    if (isEmptyScene) {
      setShowEmptySceneDialog(true)
      return
    }
    setShowFireflyPopover(true)
    setOpenedPanel(StudioPanel.Firefly)
  }

  return (
    <ActionButton {...props} size={size} onClick={handleOnClick}>
      <Icon slot="icon">
        <AiGenerateIcon />
      </Icon>
      {t('studio:contextBar:generatePrompt')}
    </ActionButton>
  )
}

export default FireflyShowGeneratePromptButton
