import { useProjectActions, useProjectState } from '@hooks/useProject'
import { FC, Fragment } from 'react'
import { Button, Dialog, DialogBase } from 'ui'
import styles from '@styles/components/LegalNotices.module.scss'
import { getCopyright, getLegalLinks } from '@components/legal/Legal'
import cn from 'classnames'
import { useTranslations } from 'use-intl'

export const LegalNoticesDialog: FC = () => {
  const t = useTranslations()
  const show = useProjectState('showLegalNoticesDialog')
  const { setShowLegalNoticesDialog } = useProjectActions()
  const legalLinks = getLegalLinks(t, styles['legal-item'])

  return (
    <DialogBase underlay open={show}>
      <Dialog size="m" noDivider>
        <h2 slot="heading" className={styles['heading']}>
          {t('legal:notices')}
        </h2>
        <div className="flex flex-col gap-m">
          <p className={styles['legal-item']}>{t('legal:rightsReserved')}</p>
          {legalLinks.map(({ link }, i) => (
            <Fragment key={`legal-link-${i}`}>{link}</Fragment>
          ))}
          <p
            className={cn(
              styles['legal-item'],
              styles['legal-item--plain-text']
            )}>
            {getCopyright(t)}
          </p>
        </div>
        <div className={styles['button-container']}>
          <Button
            size="m"
            variant="secondary"
            treatment="outline"
            onClick={() => setShowLegalNoticesDialog(false)}>
            {t('legal:notices:close')}
          </Button>
        </div>
      </Dialog>
    </DialogBase>
  )
}
