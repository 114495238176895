import React, { FC, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'ui'
import Textfield from '@components/textfield/Textfield'
import { ClassNameProps } from 'types/reactProps'
import { useDoubleClick } from '@hooks/useDoubleClick'
import { useProjectActions, useProjectState } from '@hooks/useProject'
import { useUpdateProjectMutation } from '@store/graphql/__generated__/schema'
import { useTranslations } from 'use-intl'

interface Props extends ClassNameProps {
  isProjectOwner: boolean
}

const StudioAppBarProjectName: FC<Props> = ({ className, isProjectOwner }) => {
  const t = useTranslations()
  const name = useProjectState('name')
  const projectUuid = useProjectState('projectUuid')
  const { setName } = useProjectActions()

  const [showProjectRenameInput, setShowProjectRenameInput] = useState(false)

  const [renameInputValue, setRenameInputValue] = useState(name || '')

  const [updateProject] = useUpdateProjectMutation()

  const handleProjectNameClick = useDoubleClick(() => {
    setShowProjectRenameInput(true)
    setRenameInputValue(name || '')
  })

  function handleUpdateName() {
    setShowProjectRenameInput(false)

    if (!renameInputValue || renameInputValue === name || !projectUuid) {
      setRenameInputValue(name || '')
      return
    }

    updateProject({
      variables: {
        uuid: projectUuid,
        name: renameInputValue
      }
    })
    setName(renameInputValue)
  }

  if (!isProjectOwner) {
    return <p className={className}>{name}</p>
  }
  return (
    <>
      {showProjectRenameInput ? (
        <Textfield
          style={{ width: 194 }}
          autoFocus
          value={renameInputValue}
          onInput={e => setRenameInputValue(e?.currentTarget?.value || '')}
          onChange={handleUpdateName}
        />
      ) : (
        <OverlayTrigger placement="bottom">
          <p
            className={className}
            onClick={handleProjectNameClick}
            slot="trigger">
            {name}
          </p>
          <Tooltip
            style={{ '--mod-tooltip-max-inline-size': '200px' }}
            slot="hover-content">
            {t('studio:tooltips:doubleClickToRename')}
          </Tooltip>
        </OverlayTrigger>
      )}
    </>
  )
}

export default StudioAppBarProjectName
