import React, { useCallback, useState } from 'react'
import cn from 'classnames'
import styles from '@styles/components/OrbitControls.module.scss'
import OrbitSlider from '@components/orbitSlider/OrbitSlider'
import { OverlayTrigger, Theme, Tooltip } from 'ui'
import { useFlags } from 'launchdarkly-react-client-sdk'
import ZoomIn from '@components/propertiesPanel/ZoomIn'
import ZoomOut from '@components/propertiesPanel/ZoomOut'
import {
  LeftOrbitButton,
  RightOrbitButton,
  UpOrbitButton,
  DownOrbitButton,
  ToggleOrbitConstraintsButton
} from './OrbitButtons'

import CursorOverlay from '@components/cursorOverlay/CursorOverlay'
import { EngineCamera } from '@services/engine/types'

import { useCamera } from '@services/engine/useCamera'
import CameraFrameMenu from './CameraFrameMenu'
import { useTranslations } from 'use-intl'

const TWO_PI = 2 * Math.PI

const OrbitControls = () => {
  const t = useTranslations()
  const {
    cameraType,
    cameraPosition,

    startOrbitDrag,
    orbitDrag,
    endOrbitDrag
  } = useCamera()

  const pose = cameraPosition()

  const phi = pose.view.phi
  const theta = pose.view.theta

  const isometricMode = cameraType() == EngineCamera.ISOMETRIC

  const showVerticalSlider = !isometricMode
  const showHorizontalSlider = true

  const [showVerticalOrbitSliderTooltip, setShowVerticalOrbitSliderTooltip] =
    useState(false)

  const onStartDrag = (x: number, y: number, scale: number) => {
    startOrbitDrag(x, y, 1, scale)
  }

  const onDrag = (x: number, y: number) => {
    orbitDrag(x, y, false)
  }

  const onEndDrag = (x: number, y: number) => {
    endOrbitDrag(x, y, false)
  }

  // Phi
  const onPhiStartDrag = useCallback((value: number) => {
    onStartDrag(value, 0, 1)
  }, [])

  const onPhiDrag = useCallback((initialValue: number, value: number) => {
    onDrag(value, 0)
  }, [])

  const onPhiEndDrag = useCallback((initialValue: number, value: number) => {
    onEndDrag(value, 0)
  }, [])

  // Theta
  const onThetaStartDrag = useCallback((value: number) => {
    onStartDrag(0, value, 1)
  }, [])

  const onThetaDrag = useCallback((initialValue: number, value: number) => {
    onDrag(0, value)
  }, [])

  const onThetaEndDrag = useCallback((initialValue: number, value: number) => {
    onEndDrag(0, value)
  }, [])

  const flags = useFlags()

  const cameraSnappingFeatureEnabled = flags['base-tf-ui-camera-snapping']

  return (
    <>
      {/* Horizontal Slider */}
      {showHorizontalSlider && (
        <Theme color="dark">
          <div className={styles['wrapper__horizontal']}>
            <div className={styles['action-btns--left']}>
              <CameraFrameMenu className={styles['camera-frame-menu']} />

              {!cameraSnappingFeatureEnabled &&
                flags['base-tf-ui-camera-zoom-in'] && <ZoomIn />}
              {!cameraSnappingFeatureEnabled &&
                flags['base-tf-ui-camera-zoom-out'] && <ZoomOut />}
            </div>
            <div>{cameraSnappingFeatureEnabled && <LeftOrbitButton />}</div>
            {cameraSnappingFeatureEnabled && (
              <div className={styles['orbit-button-separator-container']}>
                <div className={styles['orbit-button-separator']} />
              </div>
            )}
            <OverlayTrigger placement="bottom">
              <div slot="trigger">
                <OrbitSlider
                  min={0}
                  max={TWO_PI}
                  step={0.01}
                  value={TWO_PI - phi}
                  periodic
                  onStartDrag={onPhiStartDrag}
                  onDrag={onPhiDrag}
                  onEndDrag={onPhiEndDrag}
                />
              </div>
              <Tooltip slot="hover-content">
                {t('studio:tooltips:orbit')}
              </Tooltip>
            </OverlayTrigger>
            {cameraSnappingFeatureEnabled && (
              <div className={styles['orbit-button-separator-container']}>
                <div className={styles['orbit-button-separator']} />
              </div>
            )}
            <div>{cameraSnappingFeatureEnabled && <RightOrbitButton />}</div>
            <div>
              {cameraSnappingFeatureEnabled && !isometricMode && (
                <ToggleOrbitConstraintsButton />
              )}
            </div>
            <CursorOverlay />
          </div>

          {/* Vertical Slider */}
          {/* Can not use Overlay api because wrapper__vertical container uses transform property which does not correctly position tooltip */}
          {showVerticalSlider && (
            <div
              className={cn(styles['vertical-orbit-slider-tooltip-container'], {
                [styles['vertical-orbit-slider-tooltip-container-invisible']]:
                  !showVerticalOrbitSliderTooltip
              })}>
              <Tooltip open placement="right">
                {t('studio:tooltips:tumble')}
              </Tooltip>
            </div>
          )}

          {showVerticalSlider && (
            <div className={styles['wrapper__vertical']}>
              {cameraSnappingFeatureEnabled && <UpOrbitButton />}
              {cameraSnappingFeatureEnabled && (
                <div className={styles['orbit-button-separator-container']}>
                  <div className={styles['orbit-button-separator']} />
                </div>
              )}
              <div
                onMouseEnter={() => setShowVerticalOrbitSliderTooltip(true)}
                onMouseLeave={() => setShowVerticalOrbitSliderTooltip(false)}>
                <OrbitSlider
                  min={0}
                  max={Math.PI}
                  step={0.01}
                  value={theta}
                  onStartDrag={onThetaStartDrag}
                  onDrag={onThetaDrag}
                  onEndDrag={onThetaEndDrag}
                  vertical
                />
              </div>
              {cameraSnappingFeatureEnabled && (
                <div className={styles['orbit-button-separator-container']}>
                  <div className={styles['orbit-button-separator']} />
                </div>
              )}
              {cameraSnappingFeatureEnabled && <DownOrbitButton />}
              <CursorOverlay />
            </div>
          )}
        </Theme>
      )}
    </>
  )
}

export default OrbitControls
