import { LocalStorageKey } from '@constants/localStorage'
import React, { createContext, useEffect, useState } from 'react'
import { AbstractIntlMessages, IntlProvider, useTranslations } from 'use-intl'

export enum LocaleString {
  English = 'en-US',
  Japanese = 'ja-JP',
  German = 'de-DE',
  Korean = 'ko-KR',
  French = 'fr-FR'
}

export const SupportedLocales: Array<LocaleString> = [
  LocaleString.English,
  LocaleString.Japanese,
  LocaleString.German,
  LocaleString.Korean,
  LocaleString.French
]

export type LocaleMessages = AbstractIntlMessages

export type UseTranslationsHookResult = ReturnType<typeof useTranslations>

const defaultApplicationLocale = LocaleString.English

const getUserLocalePreference = (): LocaleString | null => {
  return localStorage.getItem(LocalStorageKey.userLocale) as LocaleString
}

const persistUserLocalePreference = (
  userSelectedLocale: LocaleString
): void => {
  return localStorage.setItem(LocalStorageKey.userLocale, userSelectedLocale)
}

const importLocaleMessagesFromFile = async (
  locale: LocaleString
): Promise<LocaleMessages> => {
  const json = await import(`../messages/${locale}.json`)
  return json
}

interface LocaleContextValue {
  activeLocale: LocaleString
  localeMessages: LocaleMessages | undefined
  changeApplicationLocale: (locale: LocaleString) => void
}

const defaultLocaleContextValue: LocaleContextValue = {
  activeLocale: LocaleString.English,
  localeMessages: undefined,
  changeApplicationLocale: () => {}
}

export const LocaleContext = createContext<LocaleContextValue>(
  defaultLocaleContextValue
)

export const LocaleProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [activeLocale, setActiveLocale] = useState<LocaleString>(
    LocaleString.English
  )
  const [localeMessages, setLocaleMessages] = useState<
    LocaleMessages | undefined
  >(undefined)

  const initialize = () => {
    const userSelectedLocale = getUserLocalePreference()
    const applicationLocale = userSelectedLocale || defaultApplicationLocale
    loadAndSetLocaleMessages(applicationLocale)
  }

  const changeApplicationLocale = (userSelectedLocale: LocaleString) => {
    persistUserLocalePreference(userSelectedLocale)
    loadAndSetLocaleMessages(userSelectedLocale)
  }

  const loadAndSetLocaleMessages = async (locale: LocaleString) => {
    try {
      const json = await importLocaleMessagesFromFile(locale)
      setActiveLocale(locale)
      setLocaleMessages(json)
    } catch {
      console.warn(
        `Error loading locale messages for ${locale}. Falling back to en-us.`
      )
      const json = await importLocaleMessagesFromFile(defaultApplicationLocale)
      setActiveLocale(defaultApplicationLocale)
      setLocaleMessages(json)
    }
  }

  useEffect(() => {
    initialize()
  }, [])

  return (
    <LocaleContext.Provider
      value={{ activeLocale, localeMessages, changeApplicationLocale }}>
      <IntlProvider
        messages={localeMessages}
        locale={activeLocale}
        timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
        now={new Date()}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  )
}
