import React, { ComponentProps, FC } from 'react'
import { Button } from 'ui'
import { useAuth } from '@hooks/useAuth'
import { SpIconAdd } from 'workflow-icons'
import { createNewProjectName, useCreateProject } from '@hooks/useCreateProject'
import { useRouter } from 'next/router'
import { useTranslations } from 'use-intl'

interface Props {
  className?: string
  label?: string
  hideIcon?: boolean
  variant?: ComponentProps<typeof Button>['variant']
}

const CreateProjectButton: FC<Props> = ({
  className,
  variant,
  label,
  hideIcon
}) => {
  const { localUser } = useAuth()
  const { push } = useRouter()
  const t = useTranslations()

  const [createProject, { loading }] = useCreateProject({
    update: (_, mutationResult) => {
      const uuid = mutationResult.data?.createProject?.uuid
      if (uuid) push(`/studio/${uuid}`)
    },
    variables: {
      name: createNewProjectName(),
      tags: ['lifestyle', 'tech']
    }
  })

  return (
    <Button
      className={className}
      variant={variant || 'accent'}
      size={variant ? 'l' : 'm'}
      pending={!localUser || loading}
      onClick={() => createProject()}
      style={{
        whiteSpace: 'nowrap'
      }}>
      {!hideIcon && <SpIconAdd slot="icon" />}
      {label || t('dashboard:createProjectShort')}
    </Button>
  )
}

export default CreateProjectButton
