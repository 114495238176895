import React, { ComponentProps, FC, ReactNode, useEffect } from 'react'
import dynamic from 'next/dynamic'

const DynamicTheme = dynamic(
  async () => await import('@swc-react/theme').then(({ Theme }) => Theme),
  {
    ssr: false,
    loading: () => <></>
  }
)

interface Props extends ComponentProps<typeof DynamicTheme> {
  children: ReactNode
}

export const Theme: FC<Props> = ({
  color = 'light',
  system = 'express',
  scale = 'medium',
  ...props
}) => {
  useEffect(() => {
    import('@spectrum-web-components/theme/src/express/themes.js')
    import('@spectrum-web-components/theme/express/scale-medium.js')
    import('@spectrum-web-components/theme/src/themes.js')
    initializeMissingDebugWarning()
  }, [])

  // A race condition can happen when `window.__swc` is undefined when `@swc-react/theme` calls window.__swc.warn in debug mode
  function initializeMissingDebugWarning() {
    if (!window || window.__swc) return

    window.__swc = {
      warn: (_, message) => console.warn('SWC', message)
    }
  }

  return <DynamicTheme {...props} scale={scale} color={color} system={system} />
}
