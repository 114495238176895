import React, { FC, memo } from 'react'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { FieldLabel, MenuItem, Picker, Switch } from 'ui'
import { EngineCommitChange } from '@services/engine/types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import FrameSize from './FrameSize'
import { PropertyInputSlider } from '@components/slider/FirstAndSubsequentInputSlider'
import { useProjectActions, useProjectState } from '@hooks/useProject'
import PanelAccordion from '@components/panel/PanelAccordion'
import { PercentPropertySlider } from '@components/slider/PercentPropertySlider'
import { useTranslations } from 'use-intl'

const FrameTypes = [
  { value: '1', label: 'Instagram post 1080x1080' },
  { value: '2', label: 'Instagram story 1080x1920' },
  { value: '3', label: 'Youtube thumbnail 1920x1080' },
  { value: '4', label: 'Flyer (8.5 x 11in)' },
  { value: '5', label: 'Poster (13 x 19in)' },
  { value: '6', label: 'Magazine (9.5 x 13in)' },
  { value: '7', label: '16:9 aspect ratio HD (1920 x 1080)' },
  { value: '8', label: '16:9 aspect ratio 4K (3840 x 2160)' },
  { value: '9', label: '16:9 aspect ratio 8K (7680 x 4320)' },
  { value: '0', label: 'Custom' }
]

const Frame: FC = () => {
  const frameEnabled = useSceneState('frameEnabled')
  const frameOpacity = useSceneState('frameOpacity')
  const frameSizeLocked = useSceneState('frameSizeLocked')
  const frameType = useSceneState('frameType')
  const frameSize = useSceneState('frameSize')
  const frameUnit = useSceneState('frameUnit')
  const { setPropertyState } = useSceneActions()

  const frameArtboardCollapsed = useProjectState('frameArtboardCollapsed')
  const sizePreference = useProjectState('sizePreference')
  const { setPanelAccordionItem } = useProjectActions()

  const disabled = !frameEnabled

  const flags = useFlags()
  const t = useTranslations()

  return (
    <PanelAccordion
      label={t('scene:properties:frame')}
      collapsed={frameArtboardCollapsed}
      onChange={collapsed =>
        setPanelAccordionItem({ key: 'frameArtboardCollapsed', collapsed })
      }>
      <Switch
        id="frame-enabled"
        size={sizePreference}
        checked={frameEnabled}
        onClick={() => {
          setPropertyState({
            key: 'frameEnabled',
            value: !frameEnabled
          })
          document.getElementById('canvas')?.focus()
        }}>
        {t('scene:properties:frame:toggle')}
      </Switch>

      <div className="flex flex-col">
        <FieldLabel for="frame-preset" disabled={disabled || frameSizeLocked}>
          {t('scene:properties:frame:preset')}
        </FieldLabel>
        <Picker
          disabled={disabled || frameSizeLocked}
          id="frame-preset"
          size={sizePreference}
          className="picker-min-width w-auto"
          value={frameType.toString()}
          onchange={e => {
            const value = parseInt(
              (e as unknown as { target: { value: string } }).target.value
            )
            setPropertyState({
              key: 'frameType',
              value
            })
            document.getElementById('canvas')?.focus()
          }}
          label={t('scene:properties:frame:preset:select')}>
          {FrameTypes.map(({ value }) => (
            <MenuItem key={value} value={value}>
              {t(`scene:properties:frame:preset:${value}`)}
            </MenuItem>
          ))}
        </Picker>
      </div>
      {flags['base-tf-ui-frame-size-lock'] && (
        <FrameSize
          sizePreference={sizePreference}
          frameEnabled={frameEnabled}
          frameSizeLocked={frameSizeLocked}
          frameSize={frameSize}
          frameUnit={frameUnit}
          setPropertyState={setPropertyState}
        />
      )}

      <PercentPropertySlider
        disabled={disabled}
        aria-label={t('scene:properties:frame:opacity')}
        label={t('scene:properties:frame:opacity')}
        valueKey="frameOpacity"
        propertyPath="frame.opacity"
      />
    </PanelAccordion>
  )
}

export default memo(Frame)
