import { useProjectActions, useProjectState } from '@hooks/useProject'
import { useSceneActions } from '@hooks/useScene'
import { captureException } from '@sentry/nextjs'
import { useGetProjectByUuidQuery } from '@store/graphql/__generated__/schema'
import { useRouter } from 'next/router'
import { PropsWithChildren, useEffect } from 'react'
import styles from '@styles/components/Studio.module.scss'
import cn from 'classnames'
import { CANVAS_CONTAINER_ID } from '@services/engine/engine'
import { SceneToImageStudioPathname } from '@constants/appConstants'

type Props = {
  mainSectionItemStyle: React.CSSProperties
  uuid: string
}

export const CanvasContainer = ({
  mainSectionItemStyle,
  uuid
}: PropsWithChildren<Props>) => {
  const showFireflyPopover = useProjectState('showFireflyPopover')

  const { push } = useRouter()

  const { refetch: fetchProject } = useGetProjectByUuidQuery({
    variables: { uuid: uuid || '' },
    skip: true,
    onCompleted({ project }) {
      if (!project) return
      setProjectUuid(project.uuid!)
      setIsFeatured(project.featured!)
      setIsPublic(project.public!)
      setName(project.name!)
      setOwnerUserUuid(project.ownerUserUuid || null)
      setEngineState('INITIALIZE')
      loadProject({ requireAuth: true })
    },
    onError(error) {
      captureException(error.message)
      console.error(error)
      push('/discover')
    }
  })

  const {
    setProjectUuid,
    setIsFeatured,
    setName,
    setOwnerUserUuid,
    setIsPublic,
    loadProject
  } = useProjectActions()

  const { setEngineState } = useSceneActions()

  useEffect(() => {
    // window is guaranteed to exist within useEffect callback
    const shouldFetchProject =
      window.location.pathname !== SceneToImageStudioPathname

    if (shouldFetchProject) {
      fetchProject({
        uuid
      })
    }

    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [uuid])

  return (
    <div
      className={cn(styles['canvas-container'], {
        [styles['max-size']]: !showFireflyPopover,
        ['position-relative']: showFireflyPopover,
        [styles['border-radius']]: showFireflyPopover
      })}
      id={CANVAS_CONTAINER_ID}
      style={mainSectionItemStyle}
    />
  )
}
