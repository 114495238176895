import ColorPickerWithTextfield from '@components/colorPickerWithTextfield/ColorPickerWithTextfield'
import { useProjectState } from '@hooks/useProject'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { EngineCommitChange } from '@services/engine/types'
import { FC } from 'react'
import { useTranslations } from 'use-intl'

export const FlatColorPicker: FC = () => {
  const t = useTranslations()
  const sizePreference = useProjectState('sizePreference')
  const materialEColorLig = useSceneState('materialEColorLig')
  const { setPropertyState } = useSceneActions()

  function updateColors(color: string, commit?: EngineCommitChange) {
    setPropertyState({
      key: 'materialEColorTop',
      value: color,
      commit
    })

    setPropertyState({
      key: 'materialEColorLig',
      value: color,
      commit
    })

    setPropertyState({
      key: 'materialEColorSha',
      value: color,
      commit
    })
  }

  return (
    <ColorPickerWithTextfield
      size={sizePreference}
      label={t('object:properties:materialColor')}
      color={materialEColorLig}
      onMouseDown={e =>
        updateColors(e.target!.color, EngineCommitChange.BEGIN_COMMIT)
      }
      onInput={e => updateColors(e.target!.color)}
      onChange={e =>
        updateColors(e.target!.color, EngineCommitChange.END_COMMIT)
      }
    />
  )
}
