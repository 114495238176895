import React, { FC } from 'react'
import { useRouter } from 'next/router'
import { ActionButton, Icon, Tooltip } from 'ui'
import ChevronIcon from '/public/s2_icon_chevron_left.svg'
import { useProjectState } from '@hooks/useProject'
import { ElementSize } from '@store/slices/projectSlice'
import { useStudioEnvironment } from '@hooks/useStudioEnvironment'
import { useTranslations } from 'use-intl'

const BackButton: FC = () => {
  const t = useTranslations()

  const router = useRouter()

  const sizePreference = useProjectState('sizePreference')
  const buttonSize: ElementSize = sizePreference === 'l' ? 'm' : 's'
  const { isDefaultEnvironment, isSceneToImageEnvironment, environment } =
    useStudioEnvironment()

  const handleOnClick = () => {
    if (isDefaultEnvironment) return router.push('/my-projects')
    if (isSceneToImageEnvironment)
      return router.push(process.env.NEXT_PUBLIC_FIREFLY_HOMEPAGE_URL)
  }

  return (
    <ActionButton size={buttonSize} quiet onClick={handleOnClick}>
      <Icon slot="icon">
        <ChevronIcon />
      </Icon>

      <Tooltip selfManaged placement="bottom">
        {t(`studio:tooltips:backToProjects:${environment}`)}
      </Tooltip>
    </ActionButton>
  )
}

export default BackButton
