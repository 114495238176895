import * as React from 'react'

import { TrackingEvent } from '@constants/analytics'
import styles from '@styles/components/ToNeoProperButton.module.scss'
import { useMediumMinimumSizePreference } from '@hooks/useProject'

import NeoIcon from '/public/neo-logo.svg'
import { ActionButton } from 'ui'

interface Props extends React.PropsWithChildren {
  id?: string
  style?: React.CSSProperties
  'data-tracking-event'?: TrackingEvent
  onClick?: (e: React.MouseEvent) => void
}

export function ToNeoProperButton({ children, id, style, 'data-tracking-event': trackingEvent, onClick }: Props) {
  const size = useMediumMinimumSizePreference()

  /* This button is sometimes used to display a popover when it's hovered.
  // Depending on where this button is rendered, it sometimes glitches where if it's focused, it will keep displaying the popover even when it is no longer hovered. To fix this, we blur it upon focus
  */
  const handleOnFocus = () => {
    if (!id) return
    const button = document.getElementById(id)
    button?.blur()
  }

  return (
    <ActionButton
      data-tracking-event={trackingEvent}
      id={id}
      size={size}
      onFocus={handleOnFocus}
      className={styles['button']}
      onClick={onClick}
      aria-label="try Project Neo"
      style={style}>
      <NeoIcon slot="icon" />
      {children}
    </ActionButton>
  )
}
