import { DefaultStudioEnvironmentKey } from '@constants/appConstants'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { StudioEnvironment } from '@services/engine/types'
import { GetUserByUuidQuery } from '@store/graphql/__generated__/schema'
import { MustExtend } from 'types/helper'

export const StudioPanel = {
  Properties: 'properties',
  Community: 'community',
  Chat: 'chat',
  Firefly: 'firefly',
  Player: 'player',
  Meet: 'meet',
  Views: 'views',
  Library: 'library',
  Layers: 'layers',
  Style: 'style'
} as const

export type PanelKeys = (typeof StudioPanel)[keyof typeof StudioPanel]

export const Color = {
  light: 'light',
  dark: 'dark'
} as const

export type ColorKey = (typeof Color)[keyof typeof Color]

export const Size = {
  small: 's',
  medium: 'm',
  large: 'l'
} as const

export type ElementSize = (typeof Size)[keyof typeof Size]

export interface ProjectState {
  colorPreference: ColorKey
  sizePreference: ElementSize
  name: string | null
  isFeatured: boolean | null
  isPublic: boolean | null
  projectUuid: string | null
  ownerUserUuid: string | null
  owner: GetUserByUuidQuery['userByUuid'] | null
  createdAt: string | null
  openedPanel: PanelKeys | null
  documentChannelStatus: null | boolean
  documentSnapshotSuccess: boolean
  documentSnapshotTimestamp: number
  showContextBar: boolean
  showSceneNavigator: boolean
  showOrbitControls: boolean
  selectedLibraryTab: 'neo-library' | 'user-library'
  selectedLibraryView:
    | 'tabs-panel'
    | 'neo-three-d-shapes'
    | 'user-material'
    | 'user-background'
  isLoading: boolean
  showRefreshDialog: boolean
  showTouchDeviceWarningDialog: boolean
  showWindowsGPUConfigInstructionDialog: boolean
  showSceneLayersPanel: boolean
  showSettingsDialog: boolean
  selectedSettingsItem: 'general' | 'input' | 'about' | 'files'
  cursorOverlay: string
  showFireflyPopover: boolean
  isDownloadExportDialogOpen: boolean
  isShareExportDialogOpen: boolean
  isShareProjectDialogOpen: boolean
  isSharedProjectToastOpen: boolean
  fireflyStyleReferenceCollapsed: boolean
  fireflyStyleEffectsCollapsed: boolean
  fireflyGeneralSettingsCollapsed: boolean
  fireflySceneReferenceSettingsCollapsed: boolean
  frameCameraCollapsed: boolean
  frameArtboardCollapsed: boolean
  frameEnvironmentCollapsed: boolean
  libraryThreeDShapeCollapsed: boolean
  libraryUserBackgroundCollapsed: boolean
  libraryUserMaterialCollapsed: boolean
  styleCollapsed: boolean
  threeDTransformCollapsed: boolean
  threeDVolumeShapeCollapsed: boolean
  threeDCombineCollapsed: boolean
  threeDMaterialCollapsed: boolean
  threeDRepeatCollapsed: boolean
  threeDTextCollapsed: boolean
  sceneHistoryCollapsed: boolean
  showSaveMaterialDialog: boolean
  showSaveBackgroundDialog: boolean
  isDraggingContextBar: boolean
  showStylesMenuPopover: boolean
  showEnvironmentMenuPopover: boolean
  studioEnvironment: StudioEnvironment
  showDiscoverNeoDialog: boolean
  showAddObjectRail: boolean
  directToNeoProperStatus: null | 'DIRECTING' | 'COMPLETE'
  showLegalNoticesDialog: boolean
}

type AccordionKey = MustExtend<
  keyof ProjectState,
  | 'fireflyStyleReferenceCollapsed'
  | 'fireflyStyleEffectsCollapsed'
  | 'fireflyGeneralSettingsCollapsed'
  | 'fireflySceneReferenceSettingsCollapsed'
  | 'frameCameraCollapsed'
  | 'frameArtboardCollapsed'
  | 'frameEnvironmentCollapsed'
  | 'libraryThreeDShapeCollapsed'
  | 'libraryUserBackgroundCollapsed'
  | 'libraryUserMaterialCollapsed'
  | 'styleCollapsed'
  | 'threeDTransformCollapsed'
  | 'threeDVolumeShapeCollapsed'
  | 'threeDCombineCollapsed'
  | 'threeDMaterialCollapsed'
  | 'threeDRepeatCollapsed'
  | 'threeDTextCollapsed'
  | 'sceneHistoryCollapsed'
>

export const initialState = (): ProjectState => ({
  colorPreference: 'light',
  sizePreference: 's',
  isFeatured: null,
  isPublic: null,
  openedPanel: StudioPanel.Properties,
  name: null,
  projectUuid: null,
  ownerUserUuid: null,
  owner: null,
  createdAt: null,
  documentChannelStatus: null,
  documentSnapshotSuccess: true,
  documentSnapshotTimestamp: Date.now(),
  showContextBar: true,
  showSceneNavigator: true,
  showOrbitControls: true,
  selectedLibraryTab: 'user-library',
  selectedLibraryView: 'tabs-panel',
  isLoading: true,
  showRefreshDialog: false,
  showTouchDeviceWarningDialog: false,
  showWindowsGPUConfigInstructionDialog: false,
  showSceneLayersPanel: false,
  showSettingsDialog: false,
  selectedSettingsItem: 'general',
  cursorOverlay: '',
  showFireflyPopover: false,
  isDownloadExportDialogOpen: false,
  isShareExportDialogOpen: false,
  isShareProjectDialogOpen: false,
  isSharedProjectToastOpen: false,
  fireflyStyleReferenceCollapsed: false,
  fireflyStyleEffectsCollapsed: false,
  fireflyGeneralSettingsCollapsed: false,
  fireflySceneReferenceSettingsCollapsed: false,
  frameCameraCollapsed: false,
  frameArtboardCollapsed: false,
  frameEnvironmentCollapsed: false,
  libraryThreeDShapeCollapsed: false,
  libraryUserBackgroundCollapsed: false,
  libraryUserMaterialCollapsed: false,
  styleCollapsed: false,
  threeDTransformCollapsed: false,
  threeDVolumeShapeCollapsed: false,
  threeDCombineCollapsed: false,
  threeDMaterialCollapsed: false,
  threeDRepeatCollapsed: false,
  threeDTextCollapsed: false,
  sceneHistoryCollapsed: false,
  showSaveMaterialDialog: false,
  showSaveBackgroundDialog: false,
  isDraggingContextBar: false,
  showStylesMenuPopover: false,
  showEnvironmentMenuPopover: false,
  studioEnvironment: { environment: DefaultStudioEnvironmentKey },
  showDiscoverNeoDialog: false,
  showAddObjectRail: true,
  directToNeoProperStatus: null,
  showLegalNoticesDialog: false
})

export const project = createSlice({
  name: 'project',
  initialState: initialState(),
  reducers: {
    setColorPreference: (
      state,
      { payload }: PayloadAction<ProjectState['colorPreference']>
    ) => {
      state.colorPreference = payload
    },
    setSizePreference: (
      state,
      { payload }: PayloadAction<ProjectState['sizePreference']>
    ) => {
      state.sizePreference = payload
    },
    setIsFeatured: (
      state,
      { payload }: PayloadAction<ProjectState['isFeatured']>
    ) => {
      state.isFeatured = payload
    },
    setIsPublic: (
      state,
      { payload }: PayloadAction<ProjectState['isPublic']>
    ) => {
      state.isPublic = payload
    },
    setName: (state, { payload }: PayloadAction<ProjectState['name']>) => {
      state.name = payload
    },
    setProjectUuid: (
      state,
      { payload }: PayloadAction<ProjectState['projectUuid']>
    ) => {
      state.projectUuid = payload
    },
    setOwnerUserUuid: (
      state,
      { payload }: PayloadAction<ProjectState['ownerUserUuid']>
    ) => {
      state.ownerUserUuid = payload
    },
    setOwner: (state, { payload }: PayloadAction<ProjectState['owner']>) => {
      state.owner = payload
    },
    setCreatedAt: (
      state,
      { payload }: PayloadAction<ProjectState['createdAt']>
    ) => {
      state.createdAt = payload
    },
    setOpenedPanel: (
      state,
      { payload }: PayloadAction<ProjectState['openedPanel']>
    ) => {
      state.openedPanel = payload
    },
    loadProject: (
      state,
      { payload }: PayloadAction<{ requireAuth: boolean }>
    ) => {},
    toggleProjectPublicStatus: () => {},
    projectLoaded: () => {},
    setDocumentChannelStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.documentChannelStatus = payload
    },
    setDocumentSnapshotSuccess: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.documentSnapshotSuccess = payload
    },
    setDocumentSnapshotTimestamp: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.documentSnapshotTimestamp = payload
    },
    setShowContextBar: (
      state,
      { payload }: PayloadAction<ProjectState['showContextBar']>
    ) => {
      state.showContextBar = payload
    },
    setShowSceneNavigator: (
      state,
      { payload }: PayloadAction<ProjectState['showSceneNavigator']>
    ) => {
      state.showSceneNavigator = payload
    },
    setShowOrbitControls: (
      state,
      { payload }: PayloadAction<ProjectState['showOrbitControls']>
    ) => {
      state.showOrbitControls = payload
    },
    setSelectedLibraryTab: (
      state,
      { payload }: PayloadAction<ProjectState['selectedLibraryTab']>
    ) => {
      state.selectedLibraryTab = payload
    },
    setSelectedLibraryView: (
      state,
      { payload }: PayloadAction<ProjectState['selectedLibraryView']>
    ) => {
      state.selectedLibraryView = payload
    },
    resetState: state => {
      state.isFeatured = null
      state.isPublic = null
      state.name = null
      state.projectUuid = null
      state.ownerUserUuid = null
      state.documentChannelStatus = null
      state.documentSnapshotSuccess = true
      state.documentSnapshotTimestamp = Date.now()
      state.showSceneNavigator = true
      state.isLoading = true
      state.openedPanel = StudioPanel.Properties
      state.showFireflyPopover = false
    },
    setIsLoading: (
      state,
      { payload }: PayloadAction<ProjectState['isLoading']>
    ) => {
      state.isLoading = payload
    },
    setShowRefreshModal: (
      state,
      { payload }: PayloadAction<ProjectState['showRefreshDialog']>
    ) => {
      state.showRefreshDialog = payload
    },
    setShowTouchDeviceWarningDialog: (
      state,
      { payload }: PayloadAction<ProjectState['showTouchDeviceWarningDialog']>
    ) => {
      state.showTouchDeviceWarningDialog = payload
    },
    setShowWindowsGPUConfigInstructionDialog: (
      state,
      {
        payload
      }: PayloadAction<ProjectState['showWindowsGPUConfigInstructionDialog']>
    ) => {
      state.showWindowsGPUConfigInstructionDialog = payload
    },
    setShowSettingsDialog: (
      state,
      { payload }: PayloadAction<ProjectState['showSettingsDialog']>
    ) => {
      state.showSettingsDialog = payload
    },
    setSelectedSettingsItem: (
      state,
      { payload }: PayloadAction<ProjectState['selectedSettingsItem']>
    ) => {
      state.selectedSettingsItem = payload
    },
    setShowSceneLayersPanel: (
      state,
      { payload }: PayloadAction<ProjectState['showSceneLayersPanel']>
    ) => {
      state.showSceneLayersPanel = payload
    },
    setCursorOverlay: (state, { payload }: PayloadAction<string>) => {
      state.cursorOverlay = payload
    },
    setShowFireflyPopover: (
      state,
      { payload }: PayloadAction<ProjectState['showFireflyPopover']>
    ) => {
      state.showFireflyPopover = payload
    },
    setIsDownloadExportDialogOpen: (
      state,
      { payload }: PayloadAction<ProjectState['isDownloadExportDialogOpen']>
    ) => {
      state.isDownloadExportDialogOpen = payload
    },
    setIsShareExportDialogOpen: (
      state,
      { payload }: PayloadAction<ProjectState['isShareExportDialogOpen']>
    ) => {
      state.isShareExportDialogOpen = payload
    },
    setIsShareProjectDialogOpen: (
      state,
      { payload }: PayloadAction<ProjectState['isShareProjectDialogOpen']>
    ) => {
      state.isShareProjectDialogOpen = payload
    },
    setIsSharedProjectToastOpen: (
      state,
      { payload }: PayloadAction<ProjectState['isSharedProjectToastOpen']>
    ) => {
      state.isSharedProjectToastOpen = payload
    },
    setPanelAccordionItem: (
      state,
      { payload }: PayloadAction<{ key: AccordionKey; collapsed: boolean }>
    ) => {
      state[payload.key] = payload.collapsed
    },
    saveProject: () => {},
    setShowSaveMaterialDialog: (
      state,
      { payload }: PayloadAction<ProjectState['showSaveMaterialDialog']>
    ) => {
      state.showSaveMaterialDialog = payload
    },
    setShowSaveBackgroundDialog: (
      state,
      { payload }: PayloadAction<ProjectState['showSaveBackgroundDialog']>
    ) => {
      state.showSaveBackgroundDialog = payload
    },
    setIsDraggingContextBar: (
      state,
      { payload }: PayloadAction<ProjectState['isDraggingContextBar']>
    ) => {
      state.isDraggingContextBar = payload
    },
    setShowEnvironmentMenuPopover: (
      state,
      { payload }: PayloadAction<ProjectState['showEnvironmentMenuPopover']>
    ) => {
      state.showEnvironmentMenuPopover = payload
    },
    setShowStylesMenuPopover: (
      state,
      { payload }: PayloadAction<ProjectState['showStylesMenuPopover']>
    ) => {
      state.showStylesMenuPopover = payload
    },
    setStudioEnvironment(
      state,
      { payload }: PayloadAction<ProjectState['studioEnvironment']>
    ) {
      state.studioEnvironment = payload
    },
    setShowDiscoverNeoDialog(
      state,
      { payload }: PayloadAction<ProjectState['showDiscoverNeoDialog']>
    ) {
      state.showDiscoverNeoDialog = payload
    },
    setShowAddObjectRail(
      state,
      { payload }: PayloadAction<ProjectState['showAddObjectRail']>
    ) {
      state.showAddObjectRail = payload
    },
    setDirectToNeoProperStatus: (
      state,
      { payload }: PayloadAction<ProjectState['directToNeoProperStatus']>
    ) => {
      state.directToNeoProperStatus = payload
    },
    setShowLegalNoticesDialog: (
      state,
      { payload }: PayloadAction<ProjectState['showLegalNoticesDialog']>
    ) => {
      state.showLegalNoticesDialog = payload
    }
  }
})

export const {
  setColorPreference,
  setSizePreference,
  setIsFeatured,
  setIsPublic,
  setName,
  setProjectUuid,
  setOwnerUserUuid,
  setOwner,
  setCreatedAt,
  loadProject,
  projectLoaded,
  setOpenedPanel,
  setDocumentChannelStatus,
  setDocumentSnapshotSuccess,
  setDocumentSnapshotTimestamp,
  setShowContextBar,
  setShowSceneNavigator,
  setShowOrbitControls,
  setSelectedLibraryTab,
  setSelectedLibraryView,
  resetState,
  setIsLoading,
  setShowRefreshModal,
  setShowTouchDeviceWarningDialog,
  setShowWindowsGPUConfigInstructionDialog,
  setShowSettingsDialog,
  setSelectedSettingsItem,
  setShowSceneLayersPanel,
  setCursorOverlay,
  setShowFireflyPopover,
  setPanelAccordionItem,
  saveProject,
  setShowSaveMaterialDialog,
  setShowSaveBackgroundDialog,
  setIsDraggingContextBar,
  setShowEnvironmentMenuPopover,
  setShowStylesMenuPopover,
  setIsShareProjectDialogOpen,
  toggleProjectPublicStatus,
  setIsSharedProjectToastOpen,
  setStudioEnvironment,
  setShowDiscoverNeoDialog,
  setShowAddObjectRail,
  setDirectToNeoProperStatus,
  setShowLegalNoticesDialog
} = project.actions
export default project.reducer
