import React, { FC } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ActionButton, ActionGroup, Tooltip } from 'ui'
import styles from '@styles/components/AddObjectRail.module.scss'
import { ToolUI, useToolEvents, useTools } from '@services/engine/useTools'
import { useUISettings } from '@services/engine/useUISettings'
import { DocumentEventObserver } from '@services/engine/documentEventObserver'
import CursorOverlay from '@components/cursorOverlay/CursorOverlay'
import {
  useMediumMinimumSizePreference,
  useProjectState
} from '@hooks/useProject'
import { useTranslations } from 'use-intl'

export const AddObjectRail: FC = () => {
  const flags = useFlags()
  const t = useTranslations()

  const primitiveIdsToShow = flags['base-pf-ui-library-assets']
    ? [
        'addPrimitive_Box',
        'addPrimitive_Cylinder',
        'addPrimitive_Sphere',
        'addPrimitive_Cone',
        'addPrimitive_Poly',
        'addPrimitive_Egg',
        'addPrimitive_Torus',
        'addPrimitive_Horseshoe'
      ]
    : [
        'addPrimitive_Box',
        'addPrimitive_Cylinder',
        'addPrimitive_Sphere',
        'addPrimitive_Cone',
        'addPrimitive_Poly',
        'addPrimitive_Egg',
        'addPrimitive_Torus',
        'addPrimitive_Horseshoe',
        'addPrimitive_Capsule',
        'addPrimitive_HollowCone',
        'addPrimitive_Drop',
        'addPrimitive_Triangle',
        'addPrimitive_Star',
        'addPrimitive_Pin'
      ]

  const { toolUIs } = useTools()

  const { setMouseCaptureObject } = useUISettings()

  setMouseCaptureObject({
    enableMouseCapture: (enable: boolean): void => {
      DocumentEventObserver.instance()?.enableMouseCapture(enable)
    }
  })

  const { permanentToolID } = useTools()
  const { handleOnToolClick, handleOnToolDragStart } = useToolEvents()
  const size = useMediumMinimumSizePreference()
  const showAddObjectRail = useProjectState('showAddObjectRail')

  if (!showAddObjectRail) return null

  return (
    <div className={styles['rail']}>
      <CursorOverlay />
      <ActionGroup
        selects="single"
        selected={[permanentToolID()]}
        className={styles['action-group']}>
        {toolUIs()
          ?.filter((tool: ToolUI | undefined) => {
            const id = tool?.id()
            return primitiveIdsToShow.some(pId => pId === id)
          })
          .map(tool => {
            if (!tool) return null
            const id = tool?.id()
            return (
              <ActionButton
                className={styles['action-button']}
                data-icon-size={size}
                key={id}
                value={id}
                draggable
                onDragStart={() => handleOnToolDragStart(tool)}
                onClick={e => handleOnToolClick(tool)}>
                {tool.icon()}
                <Tooltip selfManaged placement="right" offset={2}>
                  {tool.tooltipLabel(t)}
                </Tooltip>
              </ActionButton>
            )
          })}
      </ActionGroup>
    </div>
  )
}
