import React, { FC } from 'react'
import { ActionMenu, Icon, Menu, MenuDivider, MenuItem } from 'ui'
import {
  useIsDocumentUpdatable,
  useMediumMinimumSizePreference,
  useProjectActions,
  useProjectState
} from '@hooks/useProject'
import { useSceneState, useSceneActions } from '@hooks/useScene'
import HamburgerMenuIcon from '/public/s2_icon_menu_hamburger.svg'
import { createNewProjectName, useCreateProject } from '@hooks/useCreateProject'
import { useTranslations } from 'use-intl'
import { useIsMobile } from '@hooks/useIsMobile'

interface Props {
  navigateHome: () => void
}

const StudioAppBarMainMenu: FC<Props> = ({ navigateHome }) => {
  const t = useTranslations()

  const showContextBar = useProjectState('showContextBar')
  const showOrbitControls = useProjectState('showOrbitControls')
  const showSceneNavigator = useProjectState('showSceneNavigator')
  const {
    saveProject,
    setShowContextBar,
    setShowOrbitControls,
    setShowSceneNavigator,
    setShowSettingsDialog
  } = useProjectActions()
  const size = useMediumMinimumSizePreference()
  const groupOutlineEnabled = useSceneState('groupOutlineEnabled')
  const isDocumentUpdatable = useIsDocumentUpdatable()
  const { setPropertyState } = useSceneActions()
  const isMobile = useIsMobile()

  const [createProject] = useCreateProject({
    variables: {
      name: createNewProjectName(),
      tags: ['lifestyle', 'tech']
    },
    onCompleted(data) {
      if (!data?.createProject?.uuid) return

      window.open(
        `${process.env.NEXT_PUBLIC_CLIENT_WEB_HOST}/studio/${data.createProject.uuid}`,
        '_blank'
      )
    }
  })

  return (
    <ActionMenu aria-label="app menu" quiet size={size}>
      <Icon slot="icon">
        <HamburgerMenuIcon />
      </Icon>
      <MenuItem onClick={navigateHome}>
        {t('studio:appBar:appMenu:home')}
      </MenuItem>
      <MenuDivider size="s" />
      {!isMobile && (
        <MenuItem onClick={() => createProject()}>
          {t('studio:appBar:appMenu:new')}
        </MenuItem>
      )}
      {isDocumentUpdatable && !isMobile && (
        <MenuItem onClick={() => saveProject()}>
          {t('studio:appBar:appMenu:save')}
          {/* TODO save to key binding */}
          {/* <kbd slot="value">⌘S</kbd> */}
        </MenuItem>
      )}
      <MenuDivider size="s" />
      {!isMobile && (
        <MenuItem>
          {t('studio:appBar:appMenu:view')}
          <Menu slot="submenu" className="menu-non-selected-offset" size={size}>
            <MenuItem
              selected={!groupOutlineEnabled}
              onClick={() =>
                setPropertyState({
                  key: 'groupOutlineEnabled',
                  value: false
                })
              }>
              {t('studio:appBar:appMenu:view:groupOutlineDisabled')}
            </MenuItem>
            <MenuItem
              selected={groupOutlineEnabled}
              onClick={() =>
                setPropertyState({
                  key: 'groupOutlineEnabled',
                  value: true
                })
              }>
              {t('studio:appBar:appMenu:view:groupOutlineEnabled')}
            </MenuItem>
            <MenuDivider size="s" />
            <MenuItem
              selected={showContextBar}
              onClick={() => setShowContextBar(!showContextBar)}>
              {t('studio:appBar:appMenu:view:contextualTaskBar')}
            </MenuItem>
            <MenuItem
              selected={showOrbitControls}
              onClick={() => setShowOrbitControls(!showOrbitControls)}>
              {t('studio:appBar:appMenu:view:orbitControls')}
            </MenuItem>
            <MenuItem
              selected={showSceneNavigator}
              onClick={() => setShowSceneNavigator(!showSceneNavigator)}>
              {t('studio:appBar:appMenu:view:miniLayerWidget')}
            </MenuItem>
          </Menu>
        </MenuItem>
      )}
      <MenuDivider size="s" />
      {!isMobile && (
        <MenuItem onClick={() => setShowSettingsDialog(true)}>
          {t('studio:appBar:appMenu:settings')}
          {/* TODO hook up key shortcut to open settings dialog */}
          {/* <kbd slot="value">⌘K</kbd> */}
        </MenuItem>
      )}
    </ActionMenu>
  )
}

export default StudioAppBarMainMenu
