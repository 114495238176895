import React, { FC } from 'react'
import { useSceneActions } from '@hooks/useScene'

import { EngineUndoRedo } from '@services/engine/types'

import { SpIconUndo, SpIconRedo } from 'workflow-icons'
import { ActionGroup, ActionButton, OverlayTrigger, Tooltip } from 'ui'

import { getCmdModifier } from '@services/engine/utils'
import { useMediumMinimumSizePreference } from '@hooks/useProject'
import { useTranslations } from 'use-intl'

const UndoRedo: FC = () => {
  const t = useTranslations()
  const { undoRedo } = useSceneActions()
  const size = useMediumMinimumSizePreference()
  const undoKeybind = t(`studio:tooltips:undoKeybind:${getCmdModifier()}`)
  const redoKeybind = t(`studio:tooltips:redoKeybind:${getCmdModifier()}`)

  return (
    <ActionGroup quiet size={size}>
      <OverlayTrigger offset={0} placement="bottom">
        <ActionButton
          slot="trigger"
          onClick={() => {
            undoRedo(EngineUndoRedo.UNDO)
            document.getElementById('canvas')?.focus()
          }}
          value={EngineUndoRedo.UNDO.toString()}
          aria-label={t('studio:tooltips:undo')}>
          <SpIconUndo slot="icon" />
        </ActionButton>
        <Tooltip slot="hover-content">
          {t('studio:tooltips:undo')} ({undoKeybind})
        </Tooltip>
      </OverlayTrigger>
      <OverlayTrigger offset={0} placement="bottom">
        <ActionButton
          slot="trigger"
          onClick={() => {
            undoRedo(EngineUndoRedo.REDO)
            document.getElementById('canvas')?.focus()
          }}
          value={EngineUndoRedo.REDO.toString()}
          aria-label={t('studio:tooltips:redo')}>
          <SpIconRedo slot="icon" />
        </ActionButton>
        <Tooltip slot="hover-content">
          {t('studio:tooltips:redo')} ({redoKeybind})
        </Tooltip>
      </OverlayTrigger>
    </ActionGroup>
  )
}

export default UndoRedo
