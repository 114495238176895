import React, { FC } from 'react'
import { useUISettings } from '@services/engine/useUISettings'
import { Switch } from 'ui'
import { useTranslations } from 'use-intl'

const ObjectSnappingSwitch: FC = () => {
  const { snappingActive } = useUISettings()
  const [isSnappingActive, setSnappingActive] = snappingActive()
  const snappingCurrentlyActive = isSnappingActive() ?? false
  const t = useTranslations()

  return (
    <Switch
      checked={snappingCurrentlyActive}
      onclick={() => {
        setSnappingActive(!snappingCurrentlyActive)
      }}>
      {t('dashboard:appSettings:behaviors:objectSnapping')}
    </Switch>
  )
}

export default ObjectSnappingSwitch
