import React, { useState } from 'react'
import {
  Button,
  ButtonGroup,
  Checkbox,
  DialogBase,
  Icon,
  OverlayCloseEvent
} from 'ui'
import styles from '@styles/components/ProjectShareDialog.module.scss'
import { useProjectActions, useProjectState } from '@hooks/useProject'
import {
  LocalStorageBooleanValue,
  LocalStorageKey
} from '@constants/localStorage'
import GlobeGridIcon from '/public/s2_icon_globe_grid_2.svg'
import ShareLink from '@components/project/ShareLink'
import { useTranslations } from 'use-intl'

const ProjectShareDialog = () => {
  const t = useTranslations()
  const isShareProjectDialogOpen = useProjectState('isShareProjectDialogOpen')
  const {
    setIsSharedProjectToastOpen,
    setIsShareProjectDialogOpen,
    toggleProjectPublicStatus
  } = useProjectActions()

  const [
    doNotShowShareProjectDialogAgain,
    setDoNotShowShareProjectDialogAgain
  ] = useState(false)

  function handleConfirmShareProjectDialog(
    e: React.MouseEvent<typeof Button, MouseEvent>
  ) {
    closeShareProjectDialog(e)
    toggleProjectPublicStatus()
    localStorage.setItem(
      LocalStorageKey.projectHideShareCommunityDialog,
      doNotShowShareProjectDialogAgain
        ? LocalStorageBooleanValue.TRUE
        : LocalStorageBooleanValue.FALSE
    )

    setIsSharedProjectToastOpen(true)
  }

  function closeShareProjectDialog(
    e: React.MouseEvent<typeof Button, MouseEvent>
  ) {
    e.currentTarget.dispatchEvent(OverlayCloseEvent)
    setIsShareProjectDialogOpen(false)
  }

  return (
    <>
      <DialogBase underlay open={isShareProjectDialogOpen}>
        <div className={styles['share-project-dialog']}>
          <h2>{t('studio:shareProject')}</h2>
          <p>
            {t.rich('studio:shareProject:body', {
              community: chunks => <strong>{chunks}</strong>,
              remove: chunks => <strong>{chunks}</strong>
            })}
          </p>
          <ShareLink />
          <div className={styles['footer']}>
            <Checkbox
              size="s"
              checked={doNotShowShareProjectDialogAgain}
              onClick={() =>
                setDoNotShowShareProjectDialogAgain(
                  !doNotShowShareProjectDialogAgain
                )
              }>
              {t('studio:shareProject:dismissPermanently')}
            </Checkbox>
            <ButtonGroup slot="button">
              <Button
                variant="secondary"
                treatment="outline"
                onClick={closeShareProjectDialog}>
                {t('studio:shareProject:cancel')}
              </Button>
              <Button
                variant="accent"
                treatment="fill"
                onClick={handleConfirmShareProjectDialog}>
                <Icon slot="icon">
                  <GlobeGridIcon />
                </Icon>
                {t('studio:shareProject')}
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </DialogBase>
    </>
  )
}

export default ProjectShareDialog
