import { FC } from 'react'
import { DialogBase, Button, ActionButton, Icon } from 'ui'
import styles from '@styles/components/FireflyOnboardingDialog.module.scss'
import {
  LocalStorageBooleanValue,
  LocalStorageKey
} from 'constants/localStorage'

import { useFirefly } from '@hooks/useFirefly'
import CrossIcon from '/public/s2_icon_cross.svg'
import { useMediumMinimumSizePreference } from '@hooks/useProject'
import { useTranslations } from 'use-intl'

const FireflyOnboardingDialog: FC = () => {
  const t = useTranslations()
  const size = useMediumMinimumSizePreference()

  const {
    showOnboardingDialog,
    setShowOnboardingDialog,
    setShowLimitedFreeTimeToast
  } = useFirefly()

  function handleClose() {
    setShowOnboardingDialog(false)
    localStorage.setItem(
      LocalStorageKey.fireflyViewedOnboardingDialog,
      LocalStorageBooleanValue.TRUE
    )
    setShowLimitedFreeTimeToast(true)
  }

  if (!showOnboardingDialog) return null
  return (
    <DialogBase underlay open={showOnboardingDialog}>
      <div className={styles['dialog']}>
        <ActionButton
          quiet
          className={styles['close-button']}
          onClick={handleClose}>
          <Icon slot="icon">
            <CrossIcon />
          </Icon>
        </ActionButton>
        <img src="/firefly_onboarding.png" alt="onboarding firefly" />
        <div className={styles['content']}>
          <h2>{t('studio:firefly:onboarding:header')}</h2>
          <p>{t('studio:firefly:onboarding:body')}</p>
          <Button onClick={handleClose} variant="accent" size={size}>
            {t('studio:firefly:onboarding:button')}
          </Button>
        </div>
      </div>
    </DialogBase>
  )
}

export default FireflyOnboardingDialog
