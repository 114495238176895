import React from 'react'
import { Button, ButtonGroup, Dialog, DialogBase, Link } from 'ui'
import { useRouter } from 'next/router'
import { DiscordLink } from '@components/navigation/JoinDiscordButton'
import { useSceneState } from '@hooks/useScene'
import { useTranslations } from 'use-intl'

const ErrorDialog = () => {
  const t = useTranslations()
  const engineState = useSceneState('engineState')
  const { push, reload } = useRouter()

  if (engineState !== 'ERROR') {
    return null
  }

  return (
    <DialogBase underlay open>
      <Dialog size="s">
        <h2 slot="heading">{t('studio:crashError:header')}</h2>
        <p>
          {t.rich('studio:crashError:body', {
            hyperlinked: chunks => (
              <Link href={DiscordLink} target="_blank">
                {chunks}
              </Link>
            )
          })}
        </p>
        <ButtonGroup slot="button">
          <Button onClick={() => push('/my-projects')} variant="secondary">
            {t('studio:crashError:back')}
          </Button>
          <Button onClick={() => reload()} variant="primary">
            {t('studio:crashError:refresh')}
          </Button>
        </ButtonGroup>
      </Dialog>
    </DialogBase>
  )
}

export default ErrorDialog
