import { FC } from 'react'
import { DialogBase, Dialog, Button, ButtonGroup } from 'ui'
import { useFirefly } from '@hooks/useFirefly'
import { uploadReferenceImg } from './FireflyPanel'
import { useMediumMinimumSizePreference } from '@hooks/useProject'
import { useTranslations } from 'use-intl'

const FireflyUploadImagesRightsDialog: FC = () => {
  const t = useTranslations()
  const size = useMediumMinimumSizePreference()

  const {
    showUploadingImageRightsDialog,
    setShowUploadingImageRightsDialog,
    setHasViewedUploadingImageRightsDialog
  } = useFirefly()

  function closeDialog() {
    setShowUploadingImageRightsDialog(false)
    setHasViewedUploadingImageRightsDialog(true)
  }

  function handleContinue() {
    closeDialog()
    uploadReferenceImg()
  }

  if (!showUploadingImageRightsDialog) return null
  return (
    <DialogBase underlay open={showUploadingImageRightsDialog}>
      <Dialog size={size} noDivider style={{ width: size === 'm' ? 480 : 530 }}>
        <h2 slot="heading">{t('studio:firefly:aboutUploading')}</h2>
        <p>{t('studio:firefly:aboutUploading:body')}</p>
        <ButtonGroup slot="button" size={size}>
          <Button onClick={closeDialog} variant="secondary" treatment="outline">
            {t('studio:firefly:aboutUploading:cancel')}
          </Button>
          <Button onClick={handleContinue} variant="accent">
            {t('studio:firefly:aboutUploading:continue')}
          </Button>
        </ButtonGroup>
      </Dialog>
    </DialogBase>
  )
}

export default FireflyUploadImagesRightsDialog
