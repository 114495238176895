import React, { FC, memo, useMemo } from 'react'
import {
  ActionButton,
  FieldLabel,
  Icon,
  NumberField,
  OverlayTrigger,
  Tooltip
} from 'ui'
import { SceneStateProperties } from '@hooks/useScene'
import LockIcon from '/public/s2_icon_lock.svg'
import LockOpenIcon from '/public/s2_icon_lock_open.svg'
import FrameUnits from './FrameUnits'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ProjectState } from '@store/slices/projectSlice'
import { useTranslations } from 'use-intl'

const PIXELS_PER_INCH = 330

const FrameSize: FC<
  Pick<
    SceneStateProperties,
    | 'frameEnabled'
    | 'frameSizeLocked'
    | 'frameSize'
    | 'frameUnit'
    | 'setPropertyState'
  > &
    Pick<ProjectState, 'sizePreference'> & {
      focusCanvasOnInputChange?: boolean
    }
> = ({
  frameEnabled,
  frameSizeLocked,
  frameSize,
  frameUnit,
  setPropertyState,
  focusCanvasOnInputChange = true,
  sizePreference = 's'
}) => {
  const t = useTranslations()
  const flags = useFlags()

  const disabled = !frameEnabled

  const convert = useMemo(
    () =>
      frameUnit === 'pixels'
        ? {
            increment: 1,
            display: (val: number) => val,
            state: (val: number) => val
          }
        : {
            increment: 0.001,
            display: (val: number) => val / PIXELS_PER_INCH,
            state: (val: number) => Math.round(val * PIXELS_PER_INCH)
          },
    [frameUnit]
  )

  return (
    <div className="flex align-end gap-sm">
      <div className="flex flex-col flex-1">
        <FieldLabel for="frame-size-width" disabled={disabled}>
          {t('scene:properties:frame:width')}
        </FieldLabel>
        <NumberField
          style={{ width: '100%' }}
          size={sizePreference}
          id="frame-size-width"
          disabled={disabled || frameSizeLocked}
          min={0}
          step={convert.increment}
          value={convert.display(frameSize.w)}
          onChange={newValue => {
            setPropertyState({
              key: 'frameSize',
              value: {
                w: convert.state(newValue.target.value),
                h: frameSize.h
              }
            })
            if (focusCanvasOnInputChange) {
              document.getElementById('canvas')?.focus()
            }
          }}
          hideStepper
        />
      </div>
      <div className="flex flex-col flex-1">
        <FieldLabel for="frame-size-height" disabled={disabled}>
          {t('scene:properties:frame:height')}
        </FieldLabel>
        <NumberField
          style={{ width: '100%' }}
          id="frame-size-height"
          aria-label="frame-size-height"
          disabled={disabled || frameSizeLocked}
          min={0}
          step={convert.increment}
          value={convert.display(frameSize.h)}
          onChange={newValue => {
            setPropertyState({
              key: 'frameSize',
              value: { w: frameSize.w, h: convert.state(newValue.target.value) }
            })
            if (focusCanvasOnInputChange) {
              document.getElementById('canvas')?.focus()
            }
          }}
          hideStepper
          size={sizePreference}
        />
      </div>

      {flags['base-tf-ui-frame-units'] && (
        <FrameUnits
          sizePreference={sizePreference}
          frameEnabled={frameEnabled}
          frameUnit={frameUnit}
          showLabel={false}
          setPropertyState={setPropertyState}
        />
      )}

      <OverlayTrigger placement="top" offset={0}>
        <ActionButton
          size={sizePreference}
          quiet
          disabled={disabled}
          slot="trigger"
          onClick={() => {
            setPropertyState({
              key: 'frameSizeLocked',
              value: !frameSizeLocked
            })
            if (focusCanvasOnInputChange) {
              document.getElementById('canvas')?.focus()
            }
          }}
          selected={frameSizeLocked}
          aria-label="repeat type none">
          <Icon slot="icon">
            {frameSizeLocked ? <LockIcon /> : <LockOpenIcon />}
          </Icon>
        </ActionButton>
        <Tooltip slot="hover-content">{t('scene:properties:frame:locked')}</Tooltip>
      </OverlayTrigger>
    </div>
  )
}

export default memo(
  FrameSize,
  (prevProps, nextProps) =>
    prevProps.focusCanvasOnInputChange === nextProps.focusCanvasOnInputChange &&
    prevProps.frameEnabled === nextProps.frameEnabled &&
    prevProps.frameSizeLocked === nextProps.frameSizeLocked &&
    prevProps.frameUnit === nextProps.frameUnit &&
    prevProps.frameSize.h === nextProps.frameSize.h &&
    prevProps.frameSize.w === nextProps.frameSize.w &&
    prevProps.sizePreference === nextProps.sizePreference
)
