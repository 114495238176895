import React, { useState } from 'react'
import { Button, ButtonGroup, Dialog, DialogBase, FieldLabel } from 'ui'
import { useUserLibrary } from '@hooks/useUserLibrary'
import { useSceneState } from '@hooks/useScene'
import { useProjectState, useProjectActions } from '@hooks/useProject'
import Textfield from '@components/textfield/Textfield'
import { useTranslations } from 'use-intl'

const SaveBackgroundDialog = () => {
  const t = useTranslations()
  const [inputValue, setInputValue] = useState('')
  const { createUserLibraryAsset } = useUserLibrary()
  const backgroundColorA = useSceneState('backgroundColorA')
  const backgroundColorB = useSceneState('backgroundColorB')
  const backgroundType = useSceneState('backgroundType')

  const showSaveBackgroundDialog = useProjectState('showSaveBackgroundDialog')
  const { setShowSaveBackgroundDialog } = useProjectActions()

  function saveBackgroundAndExit() {
    if (inputValue) {
      createUserLibraryAsset({
        type: 'background',
        name: inputValue,
        document: {
          backgroundColorA,
          backgroundColorB,
          backgroundType
        }
      })
    }

    closeDialog()
  }

  function closeDialog() {
    setShowSaveBackgroundDialog(false)
    setInputValue('')
    document.getElementById('canvas')?.focus()
  }

  return (
    <DialogBase underlay open={showSaveBackgroundDialog}>
      <Dialog size="s" noDivider>
        <h2 slot="heading">{t('studio:saveBackground')}</h2>
        <FieldLabel size="m">{t('studio:saveBackground:name')}</FieldLabel>
        <Textfield
          autoFocus
          value={inputValue}
          onInput={e => setInputValue(e.currentTarget.value)}
          onChange={saveBackgroundAndExit}
        />

        <ButtonGroup slot="button">
          <Button onClick={closeDialog} variant="secondary">
            {t('studio:saveBackground:cancel')}
          </Button>
          <Button onClick={saveBackgroundAndExit} variant="primary">
            {t('studio:saveBackground:save')}
          </Button>
        </ButtonGroup>
      </Dialog>
    </DialogBase>
  )
}

export default SaveBackgroundDialog
