import { bindActionCreators } from '@reduxjs/toolkit'
import { launchDarkly } from '@store/slices/launchDarklySlice'
import { RootState } from '@store/store'
import { useDispatch, useSelector } from 'react-redux'

export const useLaunchDarklyActions = () => {
  const dispatch = useDispatch()
  const boundedActions = bindActionCreators(launchDarkly.actions, dispatch)
  return boundedActions
}

export function useLaunchDarklyState<T extends keyof RootState['launchDarkly']>(
  key: T
): RootState['launchDarkly'][T] {
  const state = useSelector((state: RootState) => state.launchDarkly[key])
  return state
}
