import React, { FC } from 'react'
import styles from '@styles/components/OrbitControls.module.scss'
import { ActionButton, Tooltip, Icon } from 'ui'
import { getCmdModifier } from '@services/engine/utils'
import { SnappedAngleDirection, useCamera } from '@services/engine/useCamera'
import IconLeftArrowOrbit from '/public/s2_icon_left_arrow_orbit.svg'
import LockCameraOrientationIcon from '/public/temp-icon-orbit-snap.svg'
import { useMediumMinimumSizePreference } from '@hooks/useProject'
import { useTranslations } from 'use-intl'

export const LeftOrbitButton: FC = () => {
  const t = useTranslations()
  const size = useMediumMinimumSizePreference()
  const modifier = getCmdModifier()
  const { orbit } = useCamera()

  return (
    <ActionButton
      size={size}
      className={styles['navigation-btn-rounded-left']}
      quiet
      onClick={() => {
        orbit(SnappedAngleDirection.next, SnappedAngleDirection.stationary)
        document.getElementById('canvas')?.focus()
      }}>
      <div className={styles['icon_container_rotated_0_deg']}>
        <div className={styles['navigation-btn__icon']}>
          <IconLeftArrowOrbit />
        </div>
      </div>
      <Tooltip selfManaged placement="bottom">
        {t('studio:tooltips:turnLeft')} (
        {t(`studio:tooltips:turnLeftKeybind:${modifier}`)})
      </Tooltip>
    </ActionButton>
  )
}

export const RightOrbitButton: FC = () => {
  const t = useTranslations()
  const size = useMediumMinimumSizePreference()
  const modifier = getCmdModifier()
  const { orbit } = useCamera()

  return (
    <ActionButton
      size={size}
      className={styles['navigation-btn-rounded-right']}
      quiet
      onClick={() => {
        orbit(SnappedAngleDirection.previous, SnappedAngleDirection.stationary)
        document.getElementById('canvas')?.focus()
      }}>
      <div className={styles['icon_container_rotated_180_deg']}>
        <div className={styles['navigation-btn__icon']}>
          <IconLeftArrowOrbit />
        </div>
      </div>

      <Tooltip selfManaged placement="bottom">
        {t('studio:tooltips:turnRight')} (
        {t(`studio:tooltips:turnRightKeybind:${modifier}`)})
      </Tooltip>
    </ActionButton>
  )
}

export const DownOrbitButton: FC = () => {
  const t = useTranslations()
  const size = useMediumMinimumSizePreference()
  const modifier = getCmdModifier()
  const { orbit } = useCamera()

  return (
    <ActionButton
      size={size}
      className={styles['navigation-btn-rounded-up']}
      quiet
      onClick={() => {
        orbit(SnappedAngleDirection.stationary, SnappedAngleDirection.next)
        document.getElementById('canvas')?.focus()
      }}>
      <div className={styles['icon_container_rotated_180_deg']}>
        <div className={styles['navigation-btn__icon']}>
          <IconLeftArrowOrbit />
        </div>
      </div>

      <Tooltip selfManaged placement="bottom">
        {t('studio:tooltips:tiltUp')} (
        {t(`studio:tooltips:tiltUpKeybind:${modifier}`)})
      </Tooltip>
    </ActionButton>
  )
}

export const UpOrbitButton: FC = () => {
  const t = useTranslations()
  const size = useMediumMinimumSizePreference()
  const modifier = getCmdModifier()
  const { orbit } = useCamera()

  return (
    <ActionButton
      size={size}
      className={styles['navigation-btn-rounded-down']}
      quiet
      onClick={() => {
        orbit(SnappedAngleDirection.stationary, SnappedAngleDirection.previous)
        document.getElementById('canvas')?.focus()
      }}>
      <div className={styles['icon_container_rotated_0_deg']}>
        <div className={styles['navigation-btn__icon']}>
          <IconLeftArrowOrbit />
        </div>
      </div>
      <Tooltip selfManaged placement="bottom">
        {t('studio:tooltips:tiltDown')} (
        {t(`studio:tooltips:tiltDownKeybind:${modifier}`)})
      </Tooltip>
    </ActionButton>
  )
}

export const ToggleOrbitConstraintsButton: FC = () => {
  const t = useTranslations()
  const size = useMediumMinimumSizePreference()
  const { cameraOrbitSnapping } = useCamera()
  const [isCameraOrbitSnappingEnabled, setCameraOrbitSnappingEnabled] =
    cameraOrbitSnapping()
  const cameraOrbitSnappingCurrentlyEnabled =
    isCameraOrbitSnappingEnabled() ?? false

  return (
    <div className={styles['lock-camera-button-container']}>
      <ActionButton
        size={size}
        className={styles['action-btn']}
        toggles
        selected={cameraOrbitSnappingCurrentlyEnabled}
        onClick={() => {
          setCameraOrbitSnappingEnabled(!cameraOrbitSnappingCurrentlyEnabled)
        }}>
        <Icon slot="icon">
          <LockCameraOrientationIcon />
        </Icon>
        <Tooltip selfManaged placement="bottom">
          {t('studio:tooltips:snapCameraOrientation')}
        </Tooltip>
      </ActionButton>
    </div>
  )
}
