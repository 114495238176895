import React, { FC, memo } from 'react'
import { FieldLabel, NumberField, Slider, Switch } from 'ui'
import { EngineCommitChange, EngineMode } from '@services/engine/types'
import { useSceneState, useSceneActions } from '@hooks/useScene'
import { PropertyInputSlider } from '@components/slider/FirstAndSubsequentInputSlider'
import ColorPickerWithTextfield from '@components/colorPickerWithTextfield/ColorPickerWithTextfield'
import { ElementSize } from '@store/slices/projectSlice'
import { useTranslations } from 'use-intl'

interface Props {
  size: ElementSize
}

const StylesInputs: FC<Props> = ({ size }) => {
  const t = useTranslations()
  const mode = useSceneState('mode')
  const pixelOutlineEnabled = useSceneState('pixelOutlineEnabled')
  const modeExpressiveOutline = useSceneState('modeExpressiveOutline')
  const modeOutlineColor = useSceneState('modeOutlineColor')
  const modePixelSize = useSceneState('modePixelSize')
  const modeIllustrativeLightTexture = useSceneState(
    'modeIllustrativeLightTexture'
  )
  const modeIllustrativeGlobalStrokeSize = useSceneState(
    'modeIllustrativeGlobalStrokeSize'
  )
  const modeIllustrativeFilterStrength = useSceneState(
    'modeIllustrativeFilterStrength'
  )
  const modeIllustrativeHighlightIntensity = useSceneState(
    'modeIllustrativeHighlightIntensity'
  )
  const modeIllustrativeAmbientOcclusionIntensity = useSceneState(
    'modeIllustrativeAmbientOcclusionIntensity'
  )
  const modeIllustrativeEdgeBlendStrength = useSceneState(
    'modeIllustrativeEdgeBlendStrength'
  )
  const modeIllustrativeOutlineEnabled = useSceneState(
    'modeIllustrativeOutlineEnabled'
  )
  const modeIllustrativeOutlineTolerance = useSceneState(
    'modeIllustrativeOutlineTolerance'
  )
  const modeIllustrativeHighlightColor = useSceneState(
    'modeIllustrativeHighlightColor'
  )
  const modeIllustrativeShadowColor = useSceneState(
    'modeIllustrativeShadowColor'
  )
  const modeIllustrativeSkyColor = useSceneState('modeIllustrativeSkyColor')
  const modeIllustrativeBounceColor = useSceneState(
    'modeIllustrativeBounceColor'
  )
  const modeIllustrativeOutlineColor = useSceneState(
    'modeIllustrativeOutlineColor'
  )

  const { setPropertyState, setIllustrativeOutlineEnabled } = useSceneActions()

  const showContent =
    mode === EngineMode.PIXEL ||
    mode === EngineMode.OUTLINE ||
    mode === EngineMode.ILLUSTRATIVE

  if (!showContent) return null
  return (
    <>
      {mode === EngineMode.PIXEL && (
        <div className="flex flex-col gap-xs">
          <Slider
            size={size}
            variant="filled"
            min={16}
            max={256}
            step={1}
            label={t('scene:styles:pixelSize')}
            label={t('scene:styles:pixelSize')}
            value={modePixelSize}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modePixelSize',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modePixelSize',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modePixelSize',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            editable
            hideStepper
          />

          <Switch
            size={size}
            checked={pixelOutlineEnabled}
            onClick={() =>
              setPropertyState({
                key: 'pixelOutlineEnabled',
                value: !pixelOutlineEnabled
              })
            }>
            {t('scene:styles:pixelOutline')}
          </Switch>

          {pixelOutlineEnabled && (
            <ColorPickerWithTextfield
              size={size}
              label="Pixel outline color"
              color={modeOutlineColor}
              onMouseDown={e =>
                setPropertyState({
                  key: 'modeOutlineColor',
                  value: e?.target?.color,
                  commit: EngineCommitChange.BEGIN_COMMIT
                })
              }
              onInput={e =>
                setPropertyState({
                  key: 'modeOutlineColor',
                  value: e?.target?.color
                })
              }
              onChange={e =>
                setPropertyState({
                  key: 'modeOutlineColor',
                  value: e?.target?.color,
                  commit: EngineCommitChange.END_COMMIT
                })
              }
            />
          )}
        </div>
      )}
      {mode === EngineMode.ILLUSTRATIVE && (
        <div className="flex flex-col gap-xs">
          <PropertyInputSlider
            size={size}
            variant="filled"
            min={0}
            max={7}
            step={1}
            aria-label={t('scene:styles:lightTexture:ariaLabel')}
            label={t('scene:styles:lightTexture')}
            value={modeIllustrativeLightTexture}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeLightTexture',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeLightTexture',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeLightTexture',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
          />
          <PropertyInputSlider
            size={size}
            variant="filled"
            min={0}
            max={100}
            step={1}
            aria-label={t('scene:styles:globalStrokeSize:ariaLabel')}
            label={t('scene:styles:globalStrokeSize')}
            value={modeIllustrativeGlobalStrokeSize}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeGlobalStrokeSize',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeGlobalStrokeSize',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeGlobalStrokeSize',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
          />
          <PropertyInputSlider
            size={size}
            variant="filled"
            min={0}
            max={100}
            step={1}
            aria-label={t('scene:styles:filterStrength:ariaLabel')}
            label={t('scene:styles:filterStrength')}
            value={modeIllustrativeFilterStrength}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeFilterStrength',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeFilterStrength',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeFilterStrength',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
          />
          <PropertyInputSlider
            size={size}
            variant="filled"
            min={0}
            max={100}
            step={1}
            aria-label={t('scene:styles:highlightIntensity:ariaLabel')}
            label={t('scene:styles:highlightIntensity')}
            value={modeIllustrativeHighlightIntensity}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeHighlightIntensity',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeHighlightIntensity',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeHighlightIntensity',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
          />
          <PropertyInputSlider
            size={size}
            variant="filled"
            min={0}
            max={100}
            step={1}
            aria-label={t('scene:styles:ambientOcclusionIntensity:ariaLabel')}
            label={t('scene:styles:ambientOcclusionIntensity')}
            value={modeIllustrativeAmbientOcclusionIntensity}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeAmbientOcclusionIntensity',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeAmbientOcclusionIntensity',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeAmbientOcclusionIntensity',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
          />
          <PropertyInputSlider
            size={size}
            variant="filled"
            min={0}
            max={100}
            step={1}
            aria-label={t('scene:styles:edgeBlendStrength:ariaLabel')}
            label={t('scene:styles:edgeBlendStrength')}
            value={modeIllustrativeEdgeBlendStrength}
            onInput={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeEdgeBlendStrength',
                value
              })
            }}
            onMouseDown={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeEdgeBlendStrength',
                value,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }}
            onMouseUp={e => {
              const value = (e as unknown as { target: { value: number } })
                .target.value
              setPropertyState({
                key: 'modeIllustrativeEdgeBlendStrength',
                value,
                commit: EngineCommitChange.END_COMMIT
              })
            }}
          />

          <Switch
            size={size}
            checked={modeIllustrativeOutlineEnabled}
            onClick={() =>
              setIllustrativeOutlineEnabled(!modeIllustrativeOutlineEnabled)
            }>
            {t('scene:styles:illustrativeOutline')}
          </Switch>

          {modeIllustrativeOutlineEnabled && (
            <PropertyInputSlider
              size={size}
              variant="filled"
              min={1}
              max={100}
              step={1}
              aria-label={t('scene:styles:outlineTolerance:ariaLabel')}
              label={t('scene:styles:outlineTolerance')}
              value={modeIllustrativeOutlineTolerance}
              onInput={e => {
                const value = (e as unknown as { target: { value: number } })
                  .target.value
                setPropertyState({
                  key: 'modeIllustrativeOutlineTolerance',
                  value
                })
              }}
              onMouseDown={e => {
                const value = (e as unknown as { target: { value: number } })
                  .target.value
                setPropertyState({
                  key: 'modeIllustrativeOutlineTolerance',
                  value,
                  commit: EngineCommitChange.BEGIN_COMMIT
                })
              }}
              onMouseUp={e => {
                const value = (e as unknown as { target: { value: number } })
                  .target.value
                setPropertyState({
                  key: 'modeIllustrativeOutlineTolerance',
                  value,
                  commit: EngineCommitChange.END_COMMIT
                })
              }}
              editable
              hideStepper
            />
          )}

          {modeIllustrativeOutlineEnabled && (
            <ColorPickerWithTextfield
              size={size}
              // label="Outline color"
              label={t('scene:styles:outlineColor')}
              color={modeIllustrativeOutlineColor}
              onMouseDown={e =>
                setPropertyState({
                  key: 'modeIllustrativeOutlineColor',
                  value: e?.target?.color,
                  commit: EngineCommitChange.BEGIN_COMMIT
                })
              }
              onInput={e =>
                setPropertyState({
                  key: 'modeIllustrativeOutlineColor',
                  value: e?.target?.color
                })
              }
              onChange={e =>
                setPropertyState({
                  key: 'modeIllustrativeOutlineColor',
                  value: e?.target?.color,
                  commit: EngineCommitChange.END_COMMIT
                })
              }
            />
          )}

          <ColorPickerWithTextfield
            size={size}
            label={t('scene:styles:highlightColor')}
            color={modeIllustrativeHighlightColor}
            onMouseDown={e =>
              setPropertyState({
                key: 'modeIllustrativeHighlightColor',
                value: e?.target?.color,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }
            onInput={e =>
              setPropertyState({
                key: 'modeIllustrativeHighlightColor',
                value: e?.target?.color
              })
            }
            onChange={e =>
              setPropertyState({
                key: 'modeIllustrativeHighlightColor',
                value: e?.target?.color,
                commit: EngineCommitChange.END_COMMIT
              })
            }
          />

          <ColorPickerWithTextfield
            size={size}
            label={t('scene:styles:shadowColor')}
            color={modeIllustrativeShadowColor}
            onMouseDown={e =>
              setPropertyState({
                key: 'modeIllustrativeShadowColor',
                value: e?.target?.color,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }
            onChange={e =>
              setPropertyState({
                key: 'modeIllustrativeShadowColor',
                value: e?.target?.color,
                commit: EngineCommitChange.END_COMMIT
              })
            }
            onInput={e =>
              setPropertyState({
                key: 'modeIllustrativeShadowColor',
                value: e?.target?.color
              })
            }
          />

          <ColorPickerWithTextfield
            size={size}
            label={t('scene:styles:skyColor')}
            color={modeIllustrativeSkyColor}
            onMouseDown={e =>
              setPropertyState({
                key: 'modeIllustrativeSkyColor',
                value: e?.target?.color,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }
            onChange={e =>
              setPropertyState({
                key: 'modeIllustrativeSkyColor',
                value: e?.target?.color,
                commit: EngineCommitChange.END_COMMIT
              })
            }
            onInput={e =>
              setPropertyState({
                key: 'modeIllustrativeSkyColor',
                value: e?.target?.color
              })
            }
          />

          <ColorPickerWithTextfield
            size={size}
            label={t('scene:styles:bounceColor')}
            color={modeIllustrativeBounceColor}
            onMouseDown={e =>
              setPropertyState({
                key: 'modeIllustrativeBounceColor',
                value: e?.target?.color,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }
            onChange={e =>
              setPropertyState({
                key: 'modeIllustrativeBounceColor',
                value: e?.target?.color,
                commit: EngineCommitChange.END_COMMIT
              })
            }
            onInput={e =>
              setPropertyState({
                key: 'modeIllustrativeBounceColor',
                value: e?.target?.color
              })
            }
          />
        </div>
      )}
      {mode === EngineMode.OUTLINE && (
        <div className="flex flex-col gap-xs">
          <ColorPickerWithTextfield
            size={size}
            // label="Outline color"
            label={t('scene:styles:outlineColor')}
            color={modeOutlineColor}
            onMouseDown={e =>
              setPropertyState({
                key: 'modeOutlineColor',
                value: e?.target?.color,
                commit: EngineCommitChange.BEGIN_COMMIT
              })
            }
            onInput={e =>
              setPropertyState({
                key: 'modeOutlineColor',
                value: e?.target?.color
              })
            }
            onChange={e =>
              setPropertyState({
                key: 'modeOutlineColor',
                value: e?.target?.color,
                commit: EngineCommitChange.END_COMMIT
              })
            }
          />

          <div className="flex align-center justify-between">
            <FieldLabel for="vector-outline-thickness">
              {/* Outline thickness */}
              {t('scene:styles:outlineThickness')}
            </FieldLabel>
            <NumberField
              size={size}
              min={0}
              max={8}
              step={1}
              id="vector-outline-thickness"
              value={modeExpressiveOutline}
              onChange={e => {
                if (isNaN(e.target.value)) {
                  e.target.value = modeExpressiveOutline
                  e.stopPropagation()
                } else {
                  const value = (e as unknown as { target: { value: number } })
                    .target.value
                  setPropertyState({
                    key: 'modeExpressiveOutline',
                    value,
                    commit: EngineCommitChange.END_COMMIT
                  })
                }
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default memo(
  StylesInputs,
  (prevProps, nextProps) => prevProps.size === nextProps.size
)
