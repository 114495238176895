import React, { FC, useEffect } from 'react'
import { useSync } from '@hooks/useSync'
import { useAuth } from '@hooks/useAuth'
import Chat from '@components/chatPanel/Chat'
import ChatInput from '@components/chatPanel/ChatInput'
import styles from '@styles/components/ChatPanel.module.scss'
import PanelHeader from '@components/panel/PanelHeader'
import PanelContainer from '@components/panel/PanelContainer'
import InlineAlert from '../inlineAlert/InlineAlert'
import { useTranslations } from 'use-intl'

const ChatPanel: FC = () => {
  const t = useTranslations()
  const syncSlice = useSync()
  const messageCount = syncSlice.messages.length
  const { localUser } = useAuth()

  useEffect(() => {
    if (messageCount > 0) {
      const latestMessage = document.getElementById(
        `chat-message-${messageCount - 1}`
      )
      latestMessage?.scrollIntoView()
    }
  }, [messageCount])

  const showInviteCard = syncSlice.remoteUsers.length === 1

  return (
    <PanelContainer>
      <div className={styles['chat-panel__content']}>
        <PanelHeader>{t('studio:chat')}</PanelHeader>
        {showInviteCard && <InlineAlert>{t('studio:chat:invite')}</InlineAlert>}
        <div className={styles['chat-panel__chat-container']}>
          <div className={styles['chat-panel__message-container']}>
            <div className={styles['chat-panel__message-list']}>
              {syncSlice.messages.map((message, index) => (
                <div key={`chat-message-${index}`} id={`chat-message-${index}`}>
                  <Chat
                    key={message.user.uuid}
                    userAvatar={message.user.adobe_user_avatar_url}
                    userName={message.user.adobe_user_display_name}
                    userColor={message.user.preferences.color}
                    message={message.message}
                    timestamp={message.timestamp}
                    isLocalUser={localUser?.uuid === message.user.uuid}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles['chat-panel__input-container']}>
          <ChatInput />
        </div>
      </div>
    </PanelContainer>
  )
}

export default ChatPanel
