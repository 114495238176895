import React from 'react'
import styles from '@styles/components/LandingPageHero.module.scss'
import LandingPageActions from './LandingPageActions'
import { useTranslations } from 'use-intl'

const LandingPageHero = () => {
  const t = useTranslations()

  return (
    <div className={styles['container']}>
      <div className={styles['content-container']}>
        <div className={styles['content-alignment-container']}>
          <div className={styles['content']}>
            <div className={styles['content-text']}>
              <span>{t('page:landing:hero:header')}</span>
              <div>
                <p>{t('page:landing:hero:body')}</p>
              </div>
            </div>
            <LandingPageActions />
          </div>
        </div>
        <div className={styles['video-container']}>
          <video className={styles['video']} autoPlay loop muted playsInline>
            <source src="landing_page/header_video.mp4" type="video/mp4" />
          </video>
          <div className={styles['video-container-overlay']}></div>
        </div>
      </div>
    </div>
  )
}

export default LandingPageHero
