import { useProjectState } from '@hooks/useProject'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { ActionButton, ActionGroup, FieldLabel, Icon } from 'ui'
import LightShadedSphereIcon from '/public/icon_shaded_sphere_light.svg'
import DarkShadedSphereIcon from '/public/icon_shaded_sphere_dark.svg'
import LightSplitColorSphere from '/public/icon_split_color_sphere_light.svg'
import DarkSplitColorSphere from '/public/icon_split_color_sphere_dark.svg'
import LightFlatColorSphere from '/public/icon_flat_color_sphere_light.svg'
import DarkFlatColorSphere from '/public/icon_flat_color_sphere_dark.svg'
import { FC, ReactNode } from 'react'
import { ProjectState } from '@store/slices/projectSlice'
import { VariableColorModeType } from '@services/engine/types'
import { ShadedColorPicker } from './ShadedColorPicker'
import { FlatColorPicker } from './FlatColorPicker'
import { SplitColorPicker } from './SplitColorPicker'
import { RootState } from '@store/store'
import { TinyColor } from '@ctrl/tinycolor'
import { useVectorOrPixelMode } from '@hooks/useVectorOrPixelMode'
import { useTranslations } from 'use-intl'

const variableColorModeIcons: Record<
  VariableColorModeType,
  { dark: ReactNode; light: ReactNode }
> = {
  Shaded: {
    dark: <DarkShadedSphereIcon />,
    light: <LightShadedSphereIcon />
  },
  Flat: {
    dark: <DarkFlatColorSphere />,
    light: <LightFlatColorSphere />
  },
  Split: {
    dark: <DarkSplitColorSphere />,
    light: <LightSplitColorSphere />
  }
}

export function getShadedTopAndShadowColors(color: string) {
  return {
    colorTop: '#' + new TinyColor(color).lighten().toHex(),
    colorSha: '#' + new TinyColor(color).darken(20).toHex()
  }
}

function getColorModeIcons(
  colorMode: VariableColorModeType,
  selectedVectorColorMode: VariableColorModeType,
  colorPreference: ProjectState['colorPreference']
) {
  const isSelected = selectedVectorColorMode === colorMode

  if (colorPreference === 'light') {
    return isSelected
      ? variableColorModeIcons[colorMode].dark
      : variableColorModeIcons[colorMode].light
  } else {
    return isSelected
      ? variableColorModeIcons[colorMode].light
      : variableColorModeIcons[colorMode].dark
  }
}

export type EngineMaterialColorKey = Extract<
  keyof RootState['scene'],
  | 'materialEColorSha'
  | 'materialEColorTop'
  | 'materialEColorLig'
  | 'materialIColor'
  | 'materialIEmissiveColor'
>

export const VariableColorPicker: FC = () => {
  const t = useTranslations()
  const sizePreference = useProjectState('sizePreference')
  const colorPreference = useProjectState('colorPreference')
  const variableColorMode = useSceneState('variableColorMode')
  const materialEColorLig = useSceneState('materialEColorLig')
  const materialEColorSha = useSceneState('materialEColorSha')
  const materialEColorTop = useSceneState('materialEColorTop')
  const materialIColor = useSceneState('materialIColor')
  const materialIEmissiveColor = useSceneState('materialIEmissiveColor')

  const { setPropertyState } = useSceneActions()
  const isSmallSizePreference = sizePreference === 's'
  const isExpressiveOrPixelMode = useVectorOrPixelMode()
  const modes = isExpressiveOrPixelMode
    ? ['Shaded', 'Split', 'Flat']
    : ['Shaded', 'Split']

  function handleOnChange(e) {
    const keys = (e as unknown as { target: { selected: string[] } }).target
      .selected

    if (!keys.length) return

    const newMode = keys[0] as VariableColorModeType

    setPropertyState({
      key: 'variableColorMode',
      value: newMode
    })

    if (newMode === 'Shaded') {
      const color = isExpressiveOrPixelMode ? materialEColorLig : materialIColor

      const { colorSha, colorTop } = getShadedTopAndShadowColors(color)

      if (isExpressiveOrPixelMode) {
        updateEngineColors({
          materialEColorLig: color,
          materialEColorTop: colorTop,
          materialEColorSha: colorSha
        })
      } else {
        updateEngineColors({
          materialIColor: color,
          materialIEmissiveColor: colorSha
        })
      }
    } else if (newMode === 'Flat') {
      if (isExpressiveOrPixelMode) {
        updateEngineColors({
          materialEColorLig: materialEColorLig,
          materialEColorTop: materialEColorLig,
          materialEColorSha: materialEColorLig
        })
      }
    } else if (newMode === 'Split') {
      if (isExpressiveOrPixelMode) {
        updateEngineColors({
          materialEColorLig: materialEColorLig,
          materialEColorTop: materialEColorTop,
          materialEColorSha: materialEColorSha
        })
      } else {
        updateEngineColors({
          materialIColor: materialIColor,
          materialIEmissiveColor: materialIEmissiveColor
        })
      }
    }
  }

  function updateEngineColors(
    colors: Partial<Record<EngineMaterialColorKey, string>>
  ) {
    Object.entries(colors).forEach(([key, color]) => {
      setPropertyState({
        key: key as EngineMaterialColorKey,
        value: color
      })
    })
  }

  return (
    <>
      <div
        className={`flex w-full ${
          isSmallSizePreference
            ? 'flex-row justify-between align-center'
            : 'flex-col'
        }`}>
        <FieldLabel size={sizePreference} for="Color">
          {t('object:properties:materialColorType')}
        </FieldLabel>
        <ActionGroup
          size={sizePreference}
          className={`${
            isSmallSizePreference ? 'justify-end' : 'justify-start'
          }`}
          selects="single"
          selected={[variableColorMode]}
          change={handleOnChange}>
          {modes.map(mode => (
            <ActionButton
              key={mode}
              value={mode}
              selected={variableColorMode === mode}>
              <Icon slot="icon">
                {getColorModeIcons(
                  mode as VariableColorModeType,
                  variableColorMode,
                  colorPreference
                )}
              </Icon>
              {t(`object:properties:materialColorType:${mode}`)}
            </ActionButton>
          ))}
        </ActionGroup>
      </div>
      {variableColorMode === 'Shaded' && <ShadedColorPicker />}
      {variableColorMode === 'Flat' && <FlatColorPicker />}
      {variableColorMode === 'Split' && <SplitColorPicker />}
    </>
  )
}
