import React, { useState } from 'react'
import cn from 'classnames'
import { ActionButton, ActionMenu, Icon, MenuItem, Overlay, Popover } from 'ui'
import styles from '@styles/components/Snapshot.module.scss'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import Textfield from '@components/textfield/Textfield'
import CameraIcon from '/public/s2_icon_camera.svg'
import AddCircleIcon from '/public/s2_icon_add_circle.svg'
import RefreshIcon from '/public/s2_icon_refresh.svg'
import RenameIcon from '/public/s2_icon_rename.svg'
import DeleteIcon from '/public/s2_icon_delete.svg'
import ChevronIcon from '/public/s2_icon_chevron.svg'
import {
  useMediumMinimumSizePreference,
  useProjectState
} from '@hooks/useProject'
import { Snapshot as SnapshotType } from '@services/engine/types'
import { useTranslations } from 'use-intl'

type SnapshotIndex = SnapshotType['index']
type SnapshotName = SnapshotType['name']

const Snapshot = () => {
  const t = useTranslations()
  const triggerBtnSize = useMediumMinimumSizePreference()
  const menuSize = useProjectState('sizePreference')

  const snapshots = useSceneState('snapshots')

  const {
    addSnapShot,
    applySnapshot,
    renameSnapShot,
    removeSnapshot,
    overwriteSnapshot
  } = useSceneActions()

  const [renameSnapshotIndex, setRenameSnapshotIndex] =
    useState<SnapshotIndex | null>(null)

  const [renameInputValue, setRenameInputValue] = useState('')

  function showRenameInput(index: SnapshotIndex, name: SnapshotName) {
    setRenameInputValue(name)
    setRenameSnapshotIndex(index)
  }

  function handleRenameInputChange(index: SnapshotIndex, name: SnapshotName) {
    if (renameInputValue && renameInputValue !== name) {
      renameSnapShot({ index, name: renameInputValue })
    }

    setRenameSnapshotIndex(null)
  }

  return (
    <>
      <div className={styles['trigger-container']}>
        <ActionButton
          size={triggerBtnSize}
          id="snapshot-popover-trigger"
          quiet
          className={styles['trigger']}>
          <Icon slot="icon">
            <CameraIcon />
          </Icon>
          <Icon className={styles['trigger-chevron-icon']}>
            <ChevronIcon />
          </Icon>
        </ActionButton>
      </div>

      <Overlay
        placement="bottom-start"
        offset={6}
        trigger="snapshot-popover-trigger@click">
        <Popover
          className={cn(styles['popover'], styles[`popover-size-${menuSize}`])}>
          <ActionButton
            size={menuSize}
            className={styles['add-snapshot']}
            onClick={() =>
              addSnapShot({
                index: snapshots.length,
                name: `${t('studio:snapshot')} ${snapshots.length + 1}`
              })
            }>
            <Icon slot="icon">
              <AddCircleIcon />
            </Icon>
            {t('studio:snapshot')}
          </ActionButton>

          {snapshots.length ? (
            <div className={styles['menu']}>
              {snapshots.map(({ index, name }) => (
                <div
                  key={index}
                  className={styles['menu-item']}
                  onClick={() => applySnapshot({ index })}>
                  {renameSnapshotIndex === index ? (
                    <Textfield
                      size={menuSize}
                      autoFocus
                      value={renameInputValue}
                      onInput={e => setRenameInputValue(e.currentTarget.value)}
                      onChange={() => handleRenameInputChange(index, name)}
                    />
                  ) : (
                    <p>{name}</p>
                  )}

                  <ActionMenu size="s" quiet onClick={e => e.stopPropagation()}>
                    <MenuItem onClick={() => overwriteSnapshot({ index })}>
                      <Icon slot="icon">
                        <RefreshIcon />
                      </Icon>
                      {t('studio:snapshot:overwrite')}
                    </MenuItem>
                    <MenuItem onClick={() => showRenameInput(index, name)}>
                      <Icon slot="icon">
                        <RenameIcon />
                      </Icon>
                      {t('studio:snapshot:rename')}
                    </MenuItem>
                    <MenuItem onClick={() => removeSnapshot({ index })}>
                      <Icon slot="icon">
                        <DeleteIcon />
                      </Icon>
                      {t('studio:snapshot:delete')}
                    </MenuItem>
                  </ActionMenu>
                </div>
              ))}
            </div>
          ) : null}
        </Popover>
      </Overlay>
    </>
  )
}

export default Snapshot
