import React, { FC, ReactNode } from 'react'
import { ActionMenu, Icon, MenuItem, OverlayTrigger, Tooltip } from 'ui'
import { useMediumMinimumSizePreference } from '@hooks/useProject'
import CommentTextIcon from '/public/s2_icon_comment_text.svg'
import LightBulbIcon from '/public/s2_icon_lightbulb.svg'
import BugIcon from '/public/s2_icon_bug.svg'
import CommunityIcon from '/public/s2_icon_community.svg'
import ModelTrainingIcon from '/public/s2_icon_firefly_model_training.svg'
import styles from '@styles/components/FireflyFeedbackMenu.module.scss'
import { useAuthState } from '@hooks/useAuth'

const MenuItemValues = [
  'Submit an idea',
  'Report a bug',
  'Join the Firefly community',
  'Rate Firefly images'
] as const

const menuItems: {
  text: (typeof MenuItemValues)[number]
  subtext: string
  icon: ReactNode
  url: string
}[] = [
  {
    text: 'Submit an idea',
    subtext: 'Suggestions and feature requests',
    icon: <LightBulbIcon />,
    url: 'https://www.adobe.com/go/firefly_feature'
  },
  {
    text: 'Report a bug',
    subtext: 'Problems using Adobe Firefly',
    icon: <BugIcon />,
    url: 'https://www.adobe.com/go/firefly_bugs'
  },
  {
    text: 'Join the Firefly community',
    subtext: 'Connect, learn, and engage',
    icon: <CommunityIcon />,
    url: 'https://www.adobe.com/go/firefly_community'
  }
  // This link shows a 404 for users who are not authenticated
  // on FF, even if they're authenticated on IMS. We are hiding
  // this until the FF team changes that.

  // {
  //   text: 'Rate Firefly images',
  //   subtext: 'Firefly model training and feedback',
  //   icon: <ModelTrainingIcon />,
  //   url: 'https://firefly.adobe.com/upload/feedback'
  // }
]

export const FireflyFeedbackMenu: FC = () => {
  const size = useMediumMinimumSizePreference()
  const localUser = useAuthState('localUser')

  return (
    <OverlayTrigger placement="bottom" offset={10}>
      <ActionMenu size={size} slot="trigger" placement="bottom-end" quiet>
        <Icon slot="icon">
          <CommentTextIcon />
        </Icon>
        {menuItems.map((item, index) => {
          // Was asking to only show this link if the user is authenticated. I'm guarding for it from now in case we do allow unauthenticated studio access in the future.
          if (item.text === 'Rate Firefly images' && !localUser) return null

          return (
            <MenuItem key={index}>
              <a
                className={styles['menu-item']}
                href={item.url}
                target="_blank">
                <Icon slot="icon">{item.icon}</Icon>
                <div className={styles['text-container']}>
                  <span className={styles['text']}>{item.text}</span>
                  <span className={styles['subtext']}>{item.subtext}</span>
                </div>
              </a>
            </MenuItem>
          )
        })}
      </ActionMenu>
      <Tooltip slot="hover-content">Feedback</Tooltip>
    </OverlayTrigger>
  )
}
