import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { LDContext } from 'launchdarkly-js-client-sdk'

export type LaunchDarklyState = {
  initialized: boolean
  launchDarklyContext: LDContext
}

const initialState: LaunchDarklyState = {
  initialized: false,
  launchDarklyContext: {}
}

export const launchDarkly = createSlice({
  name: 'launchDarkly',
  initialState,
  reducers: {
    // will insert whatever context is provided, overwriting fields that already exist
    updateLDContext: (_, { payload }: PayloadAction<LDContext>) => {},
    setLDInitialized: state => {
      state.initialized = true
    },
    // ONLY THE LAUNCH DARKLY SAGA SHOULD USE THIS
    dispatchToLDContextUpdater: (
      state,
      { payload }: PayloadAction<LDContext>
    ) => {
      state.launchDarklyContext = {
        ...state.launchDarklyContext,
        ...payload
      }
    },
    ldContextSuccessfullyUpdated: () => {}
  }
})

export const {
  updateLDContext,
  setLDInitialized,
  ldContextSuccessfullyUpdated,
  dispatchToLDContextUpdater
} = launchDarkly.actions

export default launchDarkly.reducer
