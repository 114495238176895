import { ComponentProps, FC } from 'react'
import { Overlay, Popover } from 'ui'
import styles from '@styles/components/ToNeoHoverCard.module.scss'
import cn from 'classnames'
import { useTranslations } from 'use-intl'

type Props = Pick<ComponentProps<typeof Overlay>, 'trigger' | 'placement'>

export const ToNeoHoverCard: FC<Props> = ({ trigger, placement }) => {
  const t = useTranslations()
  return (
    <Overlay placement={placement} offset={10} trigger={trigger}>
      <Popover className={styles['popover']}>
        <img
          src="/firefly/module/hover-card-image/hover-card-image.png"
          className={styles['image']}
        />
        <div className={styles['content-container']}>
          <p className={cn(styles['text'], styles['header'])}>
            {t('studio:neoHoverCard')}
          </p>
          <p className={cn(styles['text'], styles['body'])}>
            {t('studio:neoHoverCard:body')}
          </p>
        </div>
      </Popover>
    </Overlay>
  )
}
