import React from 'react'

import styles from '@styles/components/NeoDiscoverButton.module.scss'
import { ToNeoProperButton } from '@components/toNeo/ToNeoProperButton'
import { TrackingEvent } from '@constants/analytics'
import { useProjectActions } from '@hooks/useProject'

import { ActionGroup } from 'ui'
import { useTranslations } from 'use-intl'

interface Props extends React.PropsWithChildren {
  id?: string
  'data-tracking-event': TrackingEvent
}

export function NeoDiscoverButton(props: Props) {
  const { children, id, 'data-tracking-event': trackingEvent } = props
  const { setDirectToNeoProperStatus } = useProjectActions()
  const t = useTranslations()

  return (
    <ActionGroup
      data-tracking-event={trackingEvent}
      className={styles['button']}
      aria-label="try Project Neo"
      onClick={() => setDirectToNeoProperStatus('DIRECTING')}
      id={id}>
      <p className={styles['text']}>{children}</p>
      <ToNeoProperButton
        style={{
          paddingLeft: 12,
          paddingRight: 12,
          width: 95.25,
          height: 30.25,
          columnGap: 8
        }}>
        {t('studio:discoverNeoButton')}
      </ToNeoProperButton>
    </ActionGroup>
  )
}
