import { FC } from 'react'
import { ActionButton, Divider, Icon, Tooltip } from 'ui'
import styles from '@styles/components/FireflyPanelToggleButton.module.scss'
import cn from 'classnames'
import { useProjectActions, useProjectState } from '@hooks/useProject'
import { StudioPanel } from '@store/slices/projectSlice'
import TwoOverFireflyPopoverIcon from '/public/s2_icon_repeat_linear.svg'
import StudioIcon from '/public/s2_icon_video.svg'

export const FireflyPanelToggleButton: FC = () => {
  const showFireflyPopover = useProjectState('showFireflyPopover')
  const { setShowFireflyPopover, setOpenedPanel } = useProjectActions()

  const showFireflyTwoUpPopover = () => {
    setShowFireflyPopover(true)
    setOpenedPanel(null)
  }

  const closeFireflyTwoUpPopover = () => {
    setShowFireflyPopover(false)
    setOpenedPanel(StudioPanel.Properties)
  }

  return (
    <div
      className={cn(styles['container'], {
        [styles['container--firefly-popover-opened']]: showFireflyPopover,
        [styles['container--firefly-popover-closed']]: !showFireflyPopover
      })}>
      <ActionButton
        onClick={showFireflyTwoUpPopover}
        quiet
        selected={showFireflyPopover}>
        <Icon slot="icon">
          <TwoOverFireflyPopoverIcon />
        </Icon>
        <Tooltip selfManaged placement="top" offset={0}>
          View 3D scene and generated image
        </Tooltip>
      </ActionButton>
      <Divider size="s" className={styles['divider']} />
      <ActionButton
        quiet
        onClick={closeFireflyTwoUpPopover}
        selected={!showFireflyPopover}>
        <Icon slot="icon">
          <StudioIcon />
        </Icon>
        <Tooltip selfManaged placement="top" offset={0}>
          View 3D scene only
        </Tooltip>
      </ActionButton>
    </div>
  )
}
