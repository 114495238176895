import { call, delay, put, select, take, takeEvery } from 'redux-saga/effects'
import { userLoaded } from '@store/slices/authSlice'
import {
  loadProject,
  projectLoaded,
  resetState,
  saveProject,
  setColorPreference,
  setDirectToNeoProperStatus,
  setIsPublic,
  setOpenedPanel,
  setProjectUuid,
  setShowFireflyPopover,
  setSizePreference,
  setStudioEnvironment,
  toggleProjectPublicStatus
} from '@store/slices/projectSlice'
import { PayloadType, RootState } from '@store/store'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  EngineState,
  setEngineState,
  setPropertyState
} from '@store/slices/sceneSlice'
import ApolloClient from '@store/graphql/client'
import {
  UpdateProjectDocument,
  UpdateProjectMutationResult
} from '@store/graphql/__generated__/schema'
import {
  handleDocumentSnapshotUpdate,
  isDocumentUpdatable
} from '../document/documentSaga'
import { LocalStorageKey } from 'constants/localStorage'
import {
  DefaultStudioEnvironmentKey,
  SceneToImageStudioEnvironmentKey,
  StudioEnvironmentQueryKey
} from '@constants/appConstants'
import {
  ldContextSuccessfullyUpdated,
  updateLDContext
} from '@store/slices/launchDarklySlice'

function* handleLoadProject({
  payload: { requireAuth }
}: PayloadAction<PayloadType<typeof loadProject>>) {
  if (requireAuth) {
    yield call(ensureUserLoaded)
  }

  yield put(projectLoaded())
}

function* ensureUserLoaded() {
  // Sometimes, the user loads before loadProject() gets called
  const { localUser } = yield select((state: RootState) => state.auth)
  if (!localUser) yield take(userLoaded.type)
}

function* handleSetEngineState({
  payload
}: PayloadAction<PayloadType<typeof setEngineState>>) {
  if (payload !== 'UNMOUNTED') return

  yield put(resetState())
}

function* handleSaveProject() {
  const { auth, project }: RootState = yield select((state: RootState) => state)

  const { localUser } = auth
  const { isFeatured, ownerUserUuid } = project

  if (isDocumentUpdatable(localUser, isFeatured, ownerUserUuid)) {
    yield handleDocumentSnapshotUpdate()
  }
}

function* handleSetColorPreference({
  payload
}: PayloadAction<PayloadType<typeof setColorPreference>>) {
  yield localStorage.setItem(LocalStorageKey.appPreferenceColor, payload)
}

function* handleSetSizePreference({
  payload
}: PayloadAction<PayloadType<typeof setSizePreference>>) {
  yield localStorage.setItem(LocalStorageKey.appPreferenceSize, payload)
}

function* handleToggleProjectPublicStatus() {
  const { isPublic, projectUuid }: RootState['project'] = yield select(
    (state: RootState) => state.project
  )
  if (!projectUuid) return

  const newPublicStatus = !isPublic

  yield put(setIsPublic(newPublicStatus))
  yield call(setProjectPublicStatus, projectUuid, newPublicStatus)
}

async function setProjectPublicStatus(uuid: string, isPublic: boolean) {
  return await ApolloClient.mutate<UpdateProjectMutationResult['data']>({
    mutation: UpdateProjectDocument,
    variables: {
      uuid,
      public: isPublic
    }
  })
}

export function* initializeSceneToImageStudioState() {
  yield put(setShowFireflyPopover(true))
  yield put(setOpenedPanel(null))

  let projectUuid: string = yield select(
    (state: RootState) => state.project.projectUuid
  )

  if (!projectUuid) yield take(setProjectUuid.type)

  // Need to get value from store again because projectUuid may have been initialized
  // as undefined
  projectUuid = yield select((state: RootState) => state.project.projectUuid)

  const colorPreference = new URLSearchParams(window.location.search).get(
    'theme'
  ) as RootState['project']['colorPreference']

  if (colorPreference) yield put(setColorPreference(colorPreference))

  const engineState: EngineState = yield select(
    (state: RootState) => state.scene.engineState
  )
  if (engineState !== 'INITIALIZED_AND_DOCUMENT_LOADED')
    yield take(
      (action: any) =>
        action.type === setEngineState.type &&
        action.payload === 'INITIALIZED_AND_DOCUMENT_LOADED'
    )

  yield put(setPropertyState({ key: 'frameEnabled', value: false }))

  window.history.replaceState(
    {},
    '',
    `${process.env.NEXT_PUBLIC_CLIENT_WEB_HOST}/studio/${projectUuid}?${StudioEnvironmentQueryKey}=${SceneToImageStudioEnvironmentKey}`
  )
}

function* handleDirectToNeoProper({
  payload
}: PayloadAction<RootState['project']['directToNeoProperStatus']>) {
  if (payload === 'DIRECTING') {
    yield put(
      updateLDContext({
        custom: { studioEnvironment: DefaultStudioEnvironmentKey }
      })
    )

    yield take(ldContextSuccessfullyUpdated.type)

    const projectUuid: string = yield select(
      (state: RootState) => state.project.projectUuid
    )

    window.history.replaceState(
      {},
      '',
      `${process.env.NEXT_PUBLIC_CLIENT_WEB_HOST}/studio/${projectUuid}`
    )

    // LD can be so fast that the screen will just appear to flash. This will allow the
    // loading overlay to actually be visible and the transition will look smoother.
    yield delay(1000)
    yield put(setDirectToNeoProperStatus('COMPLETE'))
  }
}

export default function* projectSaga() {
  yield takeEvery(loadProject.type, handleLoadProject)
  yield takeEvery(setEngineState.type, handleSetEngineState)
  yield takeEvery(saveProject.type, handleSaveProject)
  yield takeEvery(setColorPreference.type, handleSetColorPreference)
  yield takeEvery(setSizePreference.type, handleSetSizePreference)
  yield takeEvery(
    toggleProjectPublicStatus.type,
    handleToggleProjectPublicStatus
  )

  yield takeEvery(
    (action: any) =>
      action.type === setStudioEnvironment.type &&
      action.payload.environment === SceneToImageStudioEnvironmentKey,
    initializeSceneToImageStudioState
  )

  yield takeEvery(setDirectToNeoProperStatus.type, handleDirectToNeoProper)
}
