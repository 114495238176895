import { FC } from 'react'
import { Button, ButtonGroup, Dialog, DialogBase } from 'ui'
import { useUpdateProjectMutation } from '@store/graphql/__generated__/schema'
import { useTranslations } from 'use-intl'

type Props = {
  show: boolean
  setShow: (...args: any[]) => void
  textCopy?: string
  projectUuid: string
  onDelete?: (...args: any[]) => void
}

const ProjectDeleteDialog: FC<Props> = ({
  show,
  setShow,
  textCopy,
  projectUuid,
  onDelete
}) => {
  const t = useTranslations()
  const [updateProject] = useUpdateProjectMutation({
    onCompleted() {
      onDelete?.()
    }
  })

  const archiveProject = () => {
    updateProject({
      variables: {
        uuid: projectUuid,
        archived: true
      }
    })

    setShow(false)
  }

  return (
    <DialogBase underlay open={show}>
      <Dialog size="s" noDivider>
        <h2 slot="heading">{t('dashboard:projectCard:deleteDialog:header')}</h2>
        {textCopy ?? <p>{t('dashboard:projectCard:deleteDialog:body')}</p>}
        {show && (
          <ButtonGroup slot="button">
            <Button
              variant="secondary"
              treatment="outline"
              onClick={() => setShow(false)}>
              {t('dashboard:projectCard:deleteDialog:cancel')}
            </Button>
            <Button
              variant="negative"
              treatment="fill"
              onClick={archiveProject}>
              {t('dashboard:projectCard:deleteDialog:confirm')}
            </Button>
          </ButtonGroup>
        )}
      </Dialog>
    </DialogBase>
  )
}

export default ProjectDeleteDialog
