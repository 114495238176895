import React from 'react'
import { Toast } from 'ui'
import { useFirefly } from '@hooks/useFirefly'
import { useTranslations } from 'use-intl'

const FireflyFreeLimitedTimeToast = () => {
  const t = useTranslations()
  const { showLimitedFreeTimeToast, setShowLimitedFreeTimeToast } = useFirefly()

  return (
    <Toast
      open={showLimitedFreeTimeToast}
      close={() => setShowLimitedFreeTimeToast(false)}
      variant="info"
      className="absolute-align-horizontal"
      style={{
        top: 20,
        zIndex: showLimitedFreeTimeToast ? 999999 : -1
      }}>
      {t('studio:firefly:limitedFree')}
    </Toast>
  )
}

export default FireflyFreeLimitedTimeToast
