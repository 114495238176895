import React, { FC, useMemo, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  DialogBase,
  ActionButton,
  Icon,
  FieldLabel,
  Picker,
  MenuItem,
  RadioGroup,
  Radio,
  Switch
} from 'ui'
import styles from '@styles/components/SettingsDialog.module.scss'
import { useProjectActions, useProjectState } from '@hooks/useProject'
import {
  Color,
  ColorKey,
  ProjectState,
  Size,
  ElementSize
} from '@store/slices/projectSlice'
import CrossIcon from '/public/s2_icon_cross_2.svg'
import SideNavigationItem from '@components/sideNavigationItem/SideNavigationItem'
import PanelAccordion from '@components/panel/PanelAccordion'
import GroupAutoFocus from '@components/propertiesPanel/GroupAutoFocus'
import ObjectSnappingSwitch from '@components/propertiesPanel/ObjectSnappingSwitch'
import GroupInputDevice from '@components/propertiesPanel/GroupInputDevice'
import ShareLink from '@components/project/ShareLink'
import { useAuth } from '@hooks/useAuth'
import { useUpdateUserMutation } from '@store/graphql/__generated__/schema'
import { useStudioEnvironment } from '@hooks/useStudioEnvironment'
import { useTranslations } from 'use-intl'
import LocaleSwitcher from '@concerns/i18n/components/LocaleSwitcher'

type NavigationItem = {
  value: ProjectState['selectedSettingsItem']
  featureFlagKey?: string
}

const NavigationItems: NavigationItem[] = [
  { value: 'general' },
  { value: 'input' },
  {
    value: 'files',
    featureFlagKey: 'base-pf-ui-project-private-default'
  },
  { value: 'about' }
]

const SettingsDialog: FC = () => {
  const flags = useFlags()
  const { isSceneToImageEnvironment, isDefaultEnvironment } =
    useStudioEnvironment()
  const { localUser, setProjectPrivateDefault } = useAuth()
  const showSettingsDialog = useProjectState('showSettingsDialog')
  const selectedSettingsItem = useProjectState('selectedSettingsItem')
  const colorPreference = useProjectState('colorPreference')
  const sizePreference = useProjectState('sizePreference')
  const {
    setColorPreference,
    setSizePreference,
    setSelectedSettingsItem,
    setShowSettingsDialog
  } = useProjectActions()
  const t = useTranslations()

  const [generalBehaviorsCollapsed, setGeneralBehaviorsCollapsed] =
    useState(false)
  const [generalInterfaceCollapsed, setGeneralInterfaceCollapsed] =
    useState(false)
  const [inputOptionsCollapsed, setInputOptionsCollapsed] = useState(false)
  const [projectPublicStatusCollapsed, setProjectPublicStatusCollapsed] =
    useState(false)
  const [aboutCollapsed, setAboutCollapsed] = useState(false)

  const [updateUser] = useUpdateUserMutation({
    onCompleted(data) {
      setProjectPrivateDefault(
        Boolean(data.updateUser?.preferences?.projectsPrivateDefault)
      )
    }
  })

  const filteredNavigationItems = NavigationItems.filter(trigger => {
    // Show only general and input section if viewing scene to image firefly environment
    if (isSceneToImageEnvironment) {
      return trigger.value === 'general' // Just return general
    }

    if (trigger.featureFlagKey) {
      return flags[trigger.featureFlagKey]
    }

    return true
  })

  const handleSwitchClick = () => {
    const newValue = !localUser?.preferences?.projectsPrivateDefault
    setProjectPrivateDefault(newValue)
    updateUser({
      variables: {
        preferences: {
          projectsPrivateDefault: newValue
        }
      }
    })
  }

  return (
    <DialogBase underlay open={showSettingsDialog}>
      <div className={styles['dialog']}>
        <ActionButton
          className={styles['close-btn']}
          onClick={() => setShowSettingsDialog(false)}>
          <Icon slot="icon">
            <CrossIcon />
          </Icon>
        </ActionButton>

        <div className={styles['header']}>
          <h2>{t('dashboard:appSettings:header')}</h2>
        </div>

        <div className={styles['side-navigation']}>
          {filteredNavigationItems.map(({ value }) => (
            <SideNavigationItem
              key={value}
              selected={value === selectedSettingsItem}
              onClick={() => setSelectedSettingsItem(value)}>
              {t(`dashboard:appSettings:${value}`)}
            </SideNavigationItem>
          ))}
        </div>

        <div className={styles['main-content']}>
          {selectedSettingsItem === 'general' && (
            <>
              {(flags['base-pf-ui-color-theme'] ||
                flags['base-pf-ui-size-preference']) && (
                <PanelAccordion
                  label={t('dashboard:appSettings:interface')}
                  collapsed={generalInterfaceCollapsed}
                  onChange={setGeneralInterfaceCollapsed}>
                  <div className="flex gap-m">
                    <div className="flex flex-1 flex-col gap-s">
                      {flags['base-pf-ui-color-theme'] && (
                        <div>
                          <FieldLabel size="m" for="color-theme">
                            {t('dashboard:appSettings:theme')}
                          </FieldLabel>
                          <Picker
                            id="color-theme"
                            value={colorPreference}
                            onchange={e => {
                              setColorPreference(
                                (
                                  e as unknown as {
                                    target: { value: ColorKey }
                                  }
                                ).target.value
                              )
                            }}>
                            <MenuItem value={Color.light}>
                              {t('dashboard:appSettings:theme:light')}
                            </MenuItem>
                            <MenuItem value={Color.dark}>
                              {t('dashboard:appSettings:theme:dark')}
                            </MenuItem>
                          </Picker>
                        </div>
                      )}
                      {flags['base-tf-ui-locale-switcher'] && (
                        <div>
                          <FieldLabel size="m" for="color-theme">
                            {t('dashboard:appSettings:language')}
                          </FieldLabel>
                          <LocaleSwitcher />
                        </div>
                      )}
                    </div>
                    <div className="flex flex-1 flex-col">
                      {flags['base-pf-ui-size-preference'] && (
                        <>
                          <FieldLabel size="m" for="scale">
                            {t('dashboard:appSettings:uiScale')}
                          </FieldLabel>

                          <RadioGroup
                            id="scale"
                            selected={sizePreference}
                            change={e =>
                              setSizePreference(
                                (
                                  e as unknown as {
                                    target: { selected: ElementSize }
                                  }
                                ).target.selected
                              )
                            }>
                            <Radio value={Size.small}>
                              {t('dashboard:appSettings:uiScale:small')}
                            </Radio>
                            <Radio value={Size.medium}>
                              {t('dashboard:appSettings:uiScale:medium')}
                            </Radio>
                            <Radio value={Size.large}>
                              {t('dashboard:appSettings:uiScale:large')}
                            </Radio>
                          </RadioGroup>
                        </>
                      )}
                    </div>
                  </div>
                </PanelAccordion>
              )}
              {isDefaultEnvironment && (
                <PanelAccordion
                  label={t('dashboard:appSettings:behaviors')}
                  collapsed={generalBehaviorsCollapsed}
                  onChange={setGeneralBehaviorsCollapsed}>
                  {flags['base-tf-fx-snapping'] && <ObjectSnappingSwitch />}
                  {flags['base-tf-fx-camera-autofocus'] && <GroupAutoFocus />}
                </PanelAccordion>
              )}
            </>
          )}

          {selectedSettingsItem === 'input' && (
            <PanelAccordion
              label={t('dashboard:appSettings:inputOptions')}
              collapsed={inputOptionsCollapsed}
              onChange={setInputOptionsCollapsed}>
              <GroupInputDevice />
            </PanelAccordion>
          )}

          {selectedSettingsItem === 'files' && (
            <>
              <PanelAccordion
                label={t('dashboard:appSettings:files:sharingStatus')}
                collapsed={projectPublicStatusCollapsed}
                onChange={setProjectPublicStatusCollapsed}>
                <FieldLabel size="m">
                  {t('dashboard:appSettings:historyView')}
                </FieldLabel>
                {/* TODO hook up state and action */}
                <Switch
                  size="m"
                  checked={
                    localUser?.preferences?.projectsPrivateDefault ?? false
                  }
                  onClick={handleSwitchClick}>
                  {t('dashboard:appSettings:historyView:toggle')}
                </Switch>

                <div>
                  <p className={styles['description']}>
                    {t('dashboard:appSettings:historyView:description')}
                  </p>

                  <ShareLink className={styles['description']} />
                </div>
              </PanelAccordion>
            </>
          )}

          {selectedSettingsItem === 'about' && (
            <>
              <PanelAccordion
                label={t('dashboard:appSettings:about:neo')}
                collapsed={aboutCollapsed}
                onChange={setAboutCollapsed}>
                <p className={styles['description']}>
                  {t('dashboard:appSettings:about:description')}
                </p>
              </PanelAccordion>
            </>
          )}
        </div>
      </div>
    </DialogBase>
  )
}

export default SettingsDialog
