import React from 'react'
import styles from '@styles/components/HelpDialog.module.scss'
import HelpCircleIcon from '/public/s2_icon_help_circle.svg'
import { ActionButton, DialogWrapper, Icon, OverlayTrigger, Tooltip } from 'ui'
import { useMediumMinimumSizePreference } from '@hooks/useProject'
import { useTranslations } from 'use-intl'

const content = [
  {
    header: 'studio:help:camera',
    list: [
      {
        action: 'studio:help:camera:frame',
        shortcut: 'studio:help:camera:frame:shortcut'
      },
      {
        action: 'studio:help:camera:orbit',
        shortcut: 'studio:help:camera:orbit:shortcut'
      },
      {
        action: 'studio:help:camera:pan',
        shortcut: 'studio:help:camera:pan:shortcut'
      },
      {
        action: 'studio:help:camera:zoom',
        shortcut: 'studio:help:camera:zoom:shortcut'
      }
    ]
  },
  {
    header: 'studio:help:shape',
    list: [
      {
        action: 'studio:help:shape:cornerSize',
        shortcut: 'studio:help:shape:cornerSize:shortcut'
      },
      {
        action: 'studio:help:shape:cycle',
        shortcut: 'studio:help:shape:cycle:shortcut'
      },
      {
        action: 'studio:help:shape:uniformScaling',
        shortcut: 'studio:help:shape:uniformScaling:shortcut'
      }
      // {
      //   action: 'Snap to 0.1 units',
      //   shortcut: 'Cmd + Primary Mouse Button + Drag'
      // },
      // {
      //   action: '45 degree rotation',
      //   shortcut: 'Shift + Primary Mouse Button + Drag'
      // }
    ]
  },
  {
    header: 'studio:help:shortcuts',
    list: [
      // {
      //   action: 'Copy shape', shortcut: 'Cmd + C',
      // },
      // {
      //   action: 'Cut shape', shortcut: 'Cmd + X',
      // },
      {
        action: 'studio:help:deselect',
        shortcut: 'studio:help:deselect:shortcut'
      },
      {
        action: 'studio:help:duplicate',
        shortcut: 'studio:help:duplicate:shortcut'
      },
      // {
      //   action: 'Group selected items',
      //   shortcut: 'Cmd + G'
      // },
      {
        action: 'studio:help:toggleHide',
        shortcut: 'studio:help:toggleHide:shortcut'
      },
      // {
      //   action: 'Lock shape', shortcut: 'Cmd + L',
      // },
      {
        action: 'studio:help:moveXyz',
        shortcut: 'studio:help:moveXyz:shortcut'
      },
      {
        action: 'studio:help:moveXyYzZx',
        shortcut: 'studio:help:moveXyYzZx:shortcut'
      },
      {
        action: 'studio:help:moveObject',
        shortcut: 'studio:help:moveObject:shortcut'
      },
      {
        action: 'studio:help:moveDownHierarchy',
        shortcut: 'studio:help:moveDownHierarchy:shortcut'
      },
      {
        action: 'studio:help:moveUpHierarchy',
        shortcut: 'studio:help:moveUpHierarchy:shortcut'
      },
      {
        action: 'studio:help:moveBottomHierarchy',
        shortcut: 'studio:help:moveBottomHierarchy:shortcut'
      },
      {
        action: 'studio:help:moveTopHierarchy',
        shortcut: 'studio:help:moveTopHierarchy:shortcut'
      },

      {
        action: 'studio:help:multiSelect',
        shortcut: 'studio:help:multiSelect:shortcut'
      },
      // {
      //   action: 'Paste shape', shortcut: 'Cmd + V',
      // },
      {
        action: 'studio:help:redo',
        shortcut: 'studio:help:redo:shortcut'
      },
      {
        action: 'studio:help:removeObject',
        shortcut: 'studio:help:removeObject:shortcut'
      },
      {
        action: 'studio:help:selectAll',
        shortcut: 'studio:help:selectAll:shortcut'
      },
      {
        action: 'studio:help:selectObject',
        shortcut: 'studio:help:selectObject:shortcut'
      },
      {
        action: 'studio:help:selectFirstChild',
        shortcut: 'studio:help:selectFirstChild:shortcut'
      },
      {
        action: 'studio:help:selectParentGroup',
        shortcut: 'studio:help:selectParentGroup:shortcut'
      },
      {
        action: 'studio:help:selectNextShape',
        shortcut: 'studio:help:selectNextShape:shortcut'
      },
      {
        action: 'studio:help:selectPreviousShape',
        shortcut: 'studio:help:selectPreviousShape:shortcut'
      },
      {
        action: 'studio:help:toggleDebugPanel',
        shortcut: 'studio:help:toggleDebugPanel:shortcut'
      },
      {
        action: 'studio:help:undo',
        shortcut: 'studio:help:undo:shortcut'
      }
      // {
      //   action: 'Ungroup selected items',
      //   shortcut: 'Cmd + Shift + G'
      // }
      // {
      //   action: 'Unlock shape', shortcut: 'Cmd + Shift + L',
      // },
    ]
  },
  {
    header: 'studio:help:collaboration',
    list: [
      {
        action: 'studio:help:collaboration:closeInputMenu',
        shortcut: 'studio:help:collaboration:closeInputMenu:shortcut'
      },
      {
        action: 'studio:help:collaboration:emoji',
        shortcut: 'studio:help:collaboration:emoji:shortcut'
      },
      {
        action: 'studio:help:collaboration:submitTextInput',
        shortcut: 'studio:help:collaboration:submitTextInput:shortcut'
      },
      {
        action: 'studio:help:collaboration:textInput',
        shortcut: 'studio:help:collaboration:textInput:shortcut'
      }
    ]
  }
]

const HelpDialog = () => {
  const t = useTranslations()
  const size = useMediumMinimumSizePreference()

  return (
    <OverlayTrigger type="modal">
      <DialogWrapper
        slot="click-content"
        headline={t('studio:help:header')}
        dismissable
        underlay>
        {content.map((section, sectionIndex) => (
          <div key={sectionIndex} className={styles['help-dialog-content']}>
            <h3 className={styles['header']}>{t(section.header)}</h3>
            {section.list.map((item, itemIndex) => (
              <div key={itemIndex} className={styles['flex-container']}>
                <div className={styles['flex-action']}>{t(item.action)}</div>
                <div className={styles['flex-shortcut']}>
                  {t(item.shortcut)}
                </div>
              </div>
            ))}
          </div>
        ))}
        <div>
          <h3 className={styles['header']}>
            {t('studio:help:platform:recommended')}
          </h3>
          <div className={styles['help-dialog-content']}>
            <div className={styles['flex-container']}>
              <div className={styles['flex-action']}>
                {t('studio:help:platform:intelMacs')}
              </div>
              <div className={styles['flex-shortcut']}>
                {t('studio:help:platform:safari')}
              </div>
            </div>
            <div className={styles['flex-container']}>
              <div className={styles['flex-action']}>
                {t('studio:help:platform:siliconMacs')}
              </div>
              <div className={styles['flex-shortcut']}>
                {t('studio:help:platform:chromeEdgeOpera')}
              </div>
            </div>
            <div className={styles['flex-container']}>
              <div className={styles['flex-action']}>
                {t('studio:help:platform:allMacs')}
              </div>
              <div className={styles['flex-shortcut']}>
                {t('studio:help:platform:chromeSafari')}
              </div>
            </div>
            <div className={styles['flex-container']}>
              <div className={styles['flex-action']}>
                {t('studio:help:platform:windows')}
              </div>
              <div className={styles['flex-shortcut']}>
                {t('studio:help:platform:chromeEdge')}
              </div>
            </div>
          </div>
        </div>
        <a href={t('studio:help:releaseNotes:url')} target="_blank">
          <h2
            style={{
              textDecoration: 'underline'
            }}>
            {t('studio:help:releaseNotes:text')}
          </h2>
        </a>
      </DialogWrapper>
      <ActionButton slot="trigger" quiet aria-label="Help" size={size}>
        <Icon slot="icon">
          <HelpCircleIcon />
        </Icon>
      </ActionButton>
      <Tooltip slot="hover-content" placement="bottom">
        {t('studio:tooltips:help')}
      </Tooltip>
    </OverlayTrigger>
  )
}

export default HelpDialog
