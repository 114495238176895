import React from 'react'
import { OverlayTrigger, Tooltip, ActionButton, Icon } from 'ui'
import { useSceneActions } from '@hooks/useScene'
import DeleteIcon from '/public/icon_delete.svg'
import { useMediumMinimumSizePreference } from '@hooks/useProject'
import { useTranslations } from 'use-intl'

const ObjectDeleteButton = () => {
  const t = useTranslations()
  const size = useMediumMinimumSizePreference()

  const { deletePrimitive } = useSceneActions()

  return (
    <OverlayTrigger placement="top" offset={0}>
      <ActionButton
        size={size}
        quiet
        slot="trigger"
        onClick={() => {
          deletePrimitive()
          document.getElementById('canvas')?.focus()
        }}>
        <Icon slot="icon" className="icon-m">
          <DeleteIcon />
        </Icon>
      </ActionButton>
      <Tooltip slot="hover-content">
        {t('studio:tooltips:deleteObject')}
      </Tooltip>
    </OverlayTrigger>
  )
}

export default ObjectDeleteButton
