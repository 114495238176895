import React, { FC, memo } from 'react'
import { FieldLabel, MenuItem, Picker } from 'ui'
import { SceneState } from '@store/slices/sceneSlice'
import { SceneStateProperties } from '@hooks/useScene'
import { ProjectState } from '@store/slices/projectSlice'
import { useTranslations } from 'use-intl'

const UnitLabels: Record<SceneState['frameUnit'], string> = {
  pixels: 'px',
  inches: 'in'
}
const FrameUnits: FC<
  Pick<
    SceneStateProperties,
    'frameEnabled' | 'frameUnit' | 'setPropertyState'
  > &
    Pick<ProjectState, 'sizePreference'> & {
      showLabel?: boolean
      focusCanvasOnInputChange?: boolean
    }
> = ({
  frameEnabled,
  frameUnit,
  showLabel = true,
  focusCanvasOnInputChange,
  setPropertyState,
  sizePreference
}) => {
  const t = useTranslations()
  const disabled = !frameEnabled

  return (
    <div>
      {showLabel && (
        <FieldLabel for="frame-units" disabled={disabled}>
        {t('scene:properties:frame:unit')}
        </FieldLabel>
      )}
      <Picker
        size={sizePreference}
        disabled={disabled}
        id="frame-units"
        style={{ width: 65 }}
        value={frameUnit}
        onchange={e => {
          const value = (e as unknown as { target: { value: string } }).target
            .value as SceneState['frameUnit']
          setPropertyState({
            key: 'frameUnit',
            value
          })
          if (focusCanvasOnInputChange) {
            document.getElementById('canvas')?.focus()
          }
        }}
        label="Selection frame unit">
        {Object.entries(UnitLabels).map(([value, label]) => (
          <MenuItem key={value} value={value}>
            {t(`scene:properties:frame:unit:${value}`)}
          </MenuItem>
        ))}
      </Picker>
    </div>
  )
}

export default memo(FrameUnits)
