import ColorPickerWithTextfield from '@components/colorPickerWithTextfield/ColorPickerWithTextfield'
import { TinyColor } from '@ctrl/tinycolor'
import { useProjectState } from '@hooks/useProject'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { useVectorOrPixelMode } from '@hooks/useVectorOrPixelMode'
import { EngineCommitChange } from '@services/engine/types'
import { FC } from 'react'
import { useTranslations } from 'use-intl'

export const ShadedColorPicker: FC = () => {
  const t = useTranslations()
  const sizePreference = useProjectState('sizePreference')
  const materialEColorLig = useSceneState('materialEColorLig')
  const { setPropertyState } = useSceneActions()
  const materialIColor = useSceneState('materialIColor')
  const isExpressiveOrPixelMode = useVectorOrPixelMode()

  function updateColors(color: string, commit?: EngineCommitChange) {
    const colorTop = new TinyColor(color).lighten().toHex()
    const colorSha = new TinyColor(color).darken(20).toHex()

    if (isExpressiveOrPixelMode) {
      setPropertyState({
        key: 'materialEColorTop',
        value: '#' + colorTop,
        commit
      })

      setPropertyState({
        key: 'materialEColorLig',
        value: color,
        commit
      })

      setPropertyState({
        key: 'materialEColorSha',
        value: '#' + colorSha,
        commit
      })
    } else {
      setPropertyState({
        key: 'materialIColor',
        value: color,
        commit
      })

      setPropertyState({
        key: 'materialIEmissiveColor',
        value: color,
        commit
      })
    }
  }

  return (
    <ColorPickerWithTextfield
      size={sizePreference}
      label={t('object:properties:materialColor')}
      color={isExpressiveOrPixelMode ? materialEColorLig : materialIColor}
      onMouseDown={e =>
        updateColors(e.target!.color, EngineCommitChange.BEGIN_COMMIT)
      }
      onInput={e => updateColors(e.target!.color)}
      onChange={e =>
        updateColors(e.target!.color, EngineCommitChange.END_COMMIT)
      }
    />
  )
}
