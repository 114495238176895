import React, { ComponentProps, FC } from 'react'
import { Link } from 'ui'
import { useTranslations } from 'use-intl'

type Props = ComponentProps<typeof Link>

const ShareLink: FC<Props> = props => {
  const t = useTranslations()
  return (
    <Link
      variant="secondary"
      onClick={() => window.open(t('link:projectShare:url'), '_blank')}
      {...props}>
      {t('link:projectShare:text')}
    </Link>
  )
}

export default ShareLink
