import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react'
import {
  ActionButton,
  FieldLabel,
  Icon,
  MenuItem,
  OverlayTrigger,
  Picker,
  Tooltip
} from 'ui'

import { useSceneActions, useSceneState } from '@hooks/useScene'
import Typography from '@components/base/Typography/Typography'
import LockIcon from '/public/s2_icon_lock.svg'
import LockOpenIcon from '/public/s2_icon_lock_open.svg'
import VideoFormNumberField, {
  convertNumberStringToNumber,
  isValidInput
} from '@components/propertiesPanel/VideoFormNumberField'
import styles from '@styles/components/VideoExportDialog.module.scss'
import { useProject } from '@hooks/useProject'
import { MediaIO } from '@services/engine/MediaIO'
import { useTranslations } from 'use-intl'

export type FrameDimensions = {
  width: number
  height: number
}

function roundToValidEvenInteger(n: number): number {
  const integerPart: number = Math.trunc(n)

  if (integerPart <= 1) {
    return 2
  }

  if (integerPart % 2 === 0) {
    return integerPart
  }

  if (Math.floor(integerPart + 1) > 2) {
    return Math.floor(integerPart + 1)
  }

  return 2
}

interface VideoFrameSizeProps {
  error: string
  setError: Dispatch<SetStateAction<string>>
}

const VideoFrameSize: FC<VideoFrameSizeProps> = ({ error, setError }) => {
  const t = useTranslations()

  const [aspectRatio, setAspectRatio] = useState<number>(1)
  const lockAspectRatio = useSceneState('frameSizeLocked')
  const frameSize = useSceneState('frameSize')
  const { setPropertyState } = useSceneActions()

  const { isDownloadExportDialogOpen } = useProject()
  const { getFrameDimensionValidators, getMaxPixels } = MediaIO

  const validators = useMemo(() => {
    const maxPixels: number = getMaxPixels()

    const widthValidators = getFrameDimensionValidators(
      t,
      frameSize.w,
      frameSize.h,
      maxPixels,
      'Width'
    )

    const heightValidators = getFrameDimensionValidators(
      t,
      frameSize.w,
      frameSize.h,
      maxPixels,
      'Height'
    )

    return [...widthValidators, ...heightValidators]
  }, [frameSize])

  const setLockAspectRatio = (lock: boolean) => {
    setPropertyState({
      key: 'frameSizeLocked',
      value: lock
    })
  }

  const setWidth = (w: number) => {
    setPropertyState({
      key: 'frameSize',
      value: { w, h: frameSize.h }
    })
  }

  const setHeight = (h: number) => {
    setPropertyState({
      key: 'frameSize',
      value: { w: frameSize.w, h }
    })
  }

  function handleOnWidthFocusLost(value: number) {
    setWidth(value)
    setPropertyState({
      key: 'frameSize',
      value: { w: value, h: frameSize.h }
    })
  }

  function handleOnWidthInput(e: React.FormEvent<HTMLInputElement>) {
    if (lockAspectRatio) {
      const newWidth: number = convertNumberStringToNumber(
        e.currentTarget.value
      )

      setHeight(roundToValidEvenInteger(newWidth / aspectRatio))
    }
  }

  function handleOnHeightFocusLost(value: number) {
    setHeight(value)
    setPropertyState({
      key: 'frameSize',
      value: { w: frameSize.w, h: value }
    })
  }

  function handleOnHeightInput(e: React.FormEvent<HTMLInputElement>) {
    if (lockAspectRatio) {
      const newHeight: number = convertNumberStringToNumber(
        e.currentTarget.value
      )

      setWidth(roundToValidEvenInteger(newHeight * aspectRatio))
    }
  }

  function handleOnLockButtonClick() {
    if (!lockAspectRatio && isValidInput(validators).isValid) {
      setLockAspectRatio(true)
      setAspectRatio(frameSize.w / frameSize.h)
    } else {
      setLockAspectRatio(false)
    }
  }

  return (
    <div className="flex flex-col">
      <Typography variant="h4">{t('studio:downloadMenu:mp4:size')}</Typography>
      <div className="flex align-end justify-between">
        <div className="flex flex-col">
          <FieldLabel for="video-frame-size-width">
            {t('studio:downloadMenu:mp4:width')}
          </FieldLabel>
          <VideoFormNumberField
            id="video-frame-size-width"
            aria-label="video-frame-size-width"
            name="Width"
            positiveOnly
            step={1}
            value={frameSize.w}
            setValue={setWidth}
            onChange={handleOnWidthFocusLost}
            onInput={handleOnWidthInput}
            validators={validators}
            isError={Boolean(error)}
            onError={setError}
            minWidth={75}
            validateUseEffectDeps={[isDownloadExportDialogOpen]}
          />
        </div>
        <div className="flex flex-col">
          <FieldLabel for="video-frame-size-height">
            {t('studio:downloadMenu:mp4:height')}
          </FieldLabel>
          <VideoFormNumberField
            id="video-frame-size-height"
            aria-label="video-frame-size-height"
            name="Height"
            positiveOnly
            step={1}
            value={frameSize.h}
            setValue={setHeight}
            onChange={handleOnHeightFocusLost}
            onInput={handleOnHeightInput}
            validators={validators}
            isError={Boolean(error)}
            onError={setError}
            minWidth={75}
            validateUseEffectDeps={[isDownloadExportDialogOpen]}
          />
        </div>
        <div className="flex flex-col">
          <Picker style={{ width: 60 }} value="Px" disabled>
            <MenuItem key="random" value="Px">
              Px
            </MenuItem>
          </Picker>
        </div>

        <div className="flex flex-col justify-end">
          <OverlayTrigger placement="top" offset={0}>
            <ActionButton
              quiet
              disabled={false}
              className="action-btn-m"
              slot="trigger"
              onClick={handleOnLockButtonClick}
              selected={lockAspectRatio}
              aria-label="repeat type none">
              <Icon slot="icon" className="icon-m">
                {lockAspectRatio ? <LockIcon /> : <LockOpenIcon />}
              </Icon>
            </ActionButton>
            <Tooltip slot="hover-content">Lock aspect ratio</Tooltip>
          </OverlayTrigger>
        </div>
      </div>
      {error && <p className={styles.error}>{error}</p>}
    </div>
  )
}

export default VideoFrameSize
