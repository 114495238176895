import React, { FC } from 'react'
import {
  InputDeviceSetting,
  useUISettings
} from '@services/engine/useUISettings'
import { ActionGroup, ActionButton, FieldLabel } from 'ui'
import { useTranslations } from 'use-intl'

const GroupInputDevice: FC = () => {
  const { deducedInputDeviceSetting, inputDeviceSetting } = useUISettings()
  const t = useTranslations()

  const deducedInputDevice = deducedInputDeviceSetting()

  const getDeducedInputDeviceLabel = () => {
    return deducedInputDevice == InputDeviceSetting.TRACKPAD
      ? t('dashboard:appSettings:inputDevice:trackpad')
      : t('dashboard:appSettings:inputDevice:mouse')
  }

  const [getInputDevice, setInputDevice] = inputDeviceSetting()
  const currentInputDeviceSetting = getInputDevice() ?? InputDeviceSetting.MOUSE

  return (
    <div>
      <FieldLabel size="m">{t('dashboard:appSettings:inputDevice')}</FieldLabel>
      <ActionGroup
        selects="single"
        selected={currentInputDeviceSetting.toString()}
        change={e => {
          const keys = (e as unknown as { target: { selected: string[] } })
            .target.selected
          if (!keys.length) return

          setInputDevice(Number(keys[0]) as InputDeviceSetting)
        }}>
        <ActionButton
          value={InputDeviceSetting.MOUSE.toString()}
          aria-label="mouse">
          {t('dashboard:appSettings:inputDevice:mouse')}
        </ActionButton>
        <ActionButton
          value={InputDeviceSetting.TRACKPAD.toString()}
          aria-label="trackpad">
          {t('dashboard:appSettings:inputDevice:trackpad')}
        </ActionButton>
        <ActionButton
          value={InputDeviceSetting.AUTOMATIC.toString()}
          aria-label="trackpad">
          {t('dashboard:appSettings:inputDevice:automatic')} (
          {getDeducedInputDeviceLabel()})
        </ActionButton>
      </ActionGroup>
    </div>
  )
}

export default GroupInputDevice
