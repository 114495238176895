import React from 'react'
import { ActionButton, Icon, Tooltip } from 'ui'
import styles from '@styles/components/Toolbar.module.scss'
import { useUISettings } from '@services/engine/useUISettings'
import DirectSelectIcon from '/public/s2_icon_direct_select.svg'
import { useMediumMinimumSizePreference } from '@hooks/useProject'
import { useTranslations } from 'use-intl'

const EditShape = () => {
  const t = useTranslations()
  const { shapeEditionMode } = useUISettings()
  const [getShapeEditionMode, setShapeEditionMode] = shapeEditionMode()
  const isShapeEditionMode = getShapeEditionMode() ?? false

  const size = useMediumMinimumSizePreference()

  return (
    <ActionButton
      size={size}
      selected={isShapeEditionMode}
      onClick={() => {
        setShapeEditionMode(!isShapeEditionMode)
      }}
      aria-label={t('studio:contextBar:editShape:ariaLabel')}>
      <Tooltip selfManaged placement="top" className={styles.tooltip}>
        <div className={styles['tooltip-content']}>
          {t('studio:tooltips:editShape')}
        </div>
      </Tooltip>
      <Icon slot="icon">
        <DirectSelectIcon />
      </Icon>
      {t('studio:contextBar:editShape')}
    </ActionButton>
  )
}

export default EditShape
