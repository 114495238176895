import React, { FC, memo } from 'react'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import {
  EngineCommitChange,
  PrimitiveDimensionType
} from '@services/engine/types'
import { ActionButton, ActionGroup, FieldLabel, Icon } from 'ui'
import { PropertyInputSlider } from '@components/slider/FirstAndSubsequentInputSlider'
import { useProjectActions, useProjectState } from '@hooks/useProject'
import PanelAccordion from '@components/panel/PanelAccordion'
import FlatShape from './FlatShape'
import ExtrudeIcon from '/public/s2_icon_3d_extrude.svg'
import RevolveIcon from '/public/s2_icon_3d_revolve.svg'
import { Section } from './ObjectProperties'
import { PercentPropertySlider } from '@components/slider/PercentPropertySlider'
import { BoundedPropertySlider } from '@components/slider/BoundedPropertySlider'
import { useTranslations } from 'use-intl'

const VolumeShaping: FC = () => {
  const t = useTranslations()
  const threeDVolumeShapeCollapsed = useProjectState(
    'threeDVolumeShapeCollapsed'
  )
  const sizePreference = useProjectState('sizePreference')
  const { setPanelAccordionItem } = useProjectActions()

  const { setPropertyState } = useSceneActions()

  const address = useSceneState('address')
  const primitiveDimensionType = useSceneState('primitiveDimensionType')
  const primitiveDistance = useSceneState('primitiveDistance')
  const primitiveRotation = useSceneState('primitiveRotation')

  const showCornerOne =
    primitiveDimensionType === PrimitiveDimensionType.EXTRUDE
  const showCornerTwo =
    primitiveDimensionType === PrimitiveDimensionType.EXTRUDE
  const showDistance = primitiveDimensionType === PrimitiveDimensionType.REVOLVE
  const showRotation = primitiveDimensionType === PrimitiveDimensionType.REVOLVE

  return (
    <PanelAccordion
      id={Section.shape}
      label={t('object:properties:shape')}
      collapsed={threeDVolumeShapeCollapsed}
      onChange={collapsed =>
        setPanelAccordionItem({ key: 'threeDVolumeShapeCollapsed', collapsed })
      }>
      <div
        className="flex justify-between align-center"
        style={{ paddingTop: 4 }}>
        <FieldLabel>{t('object:properties:shape:type')}</FieldLabel>
        <ActionGroup
          compact
          size={sizePreference}
          selects="single"
          selected={[primitiveDimensionType.toString()]}
          change={e => {
            const keys = (e as unknown as { target: { selected: string[] } })
              .target.selected
            if (!keys.length) return
            setPropertyState({
              address,
              key: 'primitiveDimensionType',
              value: Number(keys[0]) as PrimitiveDimensionType
            })
          }}>
          <ActionButton
            key={PrimitiveDimensionType.EXTRUDE.toString()}
            value={PrimitiveDimensionType.EXTRUDE.toString()}>
            <Icon slot="icon">
              <ExtrudeIcon />
            </Icon>
            {t('object:properties:shape:extrude')}
          </ActionButton>
          <ActionButton
            key={PrimitiveDimensionType.REVOLVE.toString()}
            value={PrimitiveDimensionType.REVOLVE.toString()}>
            <Icon slot="icon">
              <RevolveIcon />
            </Icon>
            {t('object:properties:shape:revolve')}
          </ActionButton>
        </ActionGroup>
      </div>

      {showDistance && (
        <PropertyInputSlider
          size={sizePreference}
          variant="filled"
          min={0}
          max={0.2}
          step={0.001}
          aria-label={t('object:properties:shape:distance')}
          label={t('object:properties:shape:distance')}
          value={primitiveDistance}
          onInput={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              address,
              key: 'primitiveDistance',
              value
            })
          }}
          onMouseDown={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              address,
              key: 'primitiveDistance',
              value,
              commit: EngineCommitChange.BEGIN_COMMIT
            })
          }}
          onMouseUp={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              address,
              key: 'primitiveDistance',
              value,
              commit: EngineCommitChange.END_COMMIT
            })
          }}
          editable
          hideStepper
        />
      )}
      {showRotation && (
        <BoundedPropertySlider
          ariaLabel={t('object:properties:shape:rotation')}
          label={t('object:properties:shape:rotation')}
          valueKey="primitiveRotation"
          propertyPath="treeNode.dimension.rotation"
          format="unit"
          unitType="degree"
        />
      )}
      {showCornerOne && (
        <PercentPropertySlider
          ariaLabel={t('object:properties:shape:bottomCorner')}
          label={t('object:properties:shape:bottomCorner')}
          valueKey="primitiveCornerOne"
          propertyPath="treeNode.dimension.bottomFillet"
        />
      )}
      {showCornerTwo && (
        <PercentPropertySlider
          ariaLabel={t('object:properties:shape:topCorner')}
          label={t('object:properties:shape:topCorner')}
          valueKey="primitiveCornerTwo"
          propertyPath="treeNode.dimension.topFillet"
        />
      )}
      <PercentPropertySlider
        ariaLabel={t('object:properties:shape:insideShell')}
        label={t('object:properties:shape:insideShell')}
        valueKey="primitiveShell"
        propertyPath="treeNode.standard.onion"
      />
      <PercentPropertySlider
        ariaLabel={t('object:properties:shape:inflate')}
        label={t('object:properties:shape:inflate')}
        valueKey="primitiveInflate"
        propertyPath="treeNode.standard.round"
        enableUpperboundOverride
      />

      <FlatShape />
    </PanelAccordion>
  )
}

export default memo(VolumeShaping)
