import { useStudioEnvironment } from '@hooks/useStudioEnvironment'
import styles from '@styles/components/MobileWarning.module.scss'
import { FC } from 'react'
import { Button } from 'ui'
import { useRouter } from 'next/router'
import { useTranslations } from 'use-intl'

const MobileWarning: FC = () => {
  const { environment, isSceneToImageEnvironment } = useStudioEnvironment()
  const t = useTranslations()
  const { push } = useRouter()

  let imgSrc = '/mobile-warning-image.png'
  let redirectUrl = '/discover'

  if (isSceneToImageEnvironment) {
    imgSrc = '/firefly/module/mobile-warning/mobile-warning-image.png'
    redirectUrl = 'https://firefly.adobe.com'
  }

  return (
    <div className={styles['container']}>
      <img className={styles['image']} src={imgSrc} />
      <h2 className={styles['heading']}>{t('studio:mobileWarning:heading')}</h2>
      <p className={styles['body-text']}>
        {t(`studio:mobileWarning:body:${environment}`)}
      </p>
      <Button
        variant="primary"
        treatment="outline"
        onClick={() => push(redirectUrl)}
        className={styles['button']}>
        {t(`studio:mobileWarning:button:${environment}`)}
      </Button>
    </div>
  )
}

export default MobileWarning
