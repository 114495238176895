import React, { FC } from 'react'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import { EngineCamera, EngineCommitChange } from '@services/engine/types'
import { ActionButton, ActionGroup } from 'ui'
import { PropertyInputSlider } from '@components/slider/FirstAndSubsequentInputSlider'
import PanelAccordion from '@components/panel/PanelAccordion'
import { useProjectActions, useProjectState } from '@hooks/useProject'
import { useStudioEnvironment } from '@hooks/useStudioEnvironment'
import { useTranslations } from 'use-intl'

const Camera: FC = () => {
  const cameraFocalLength = useSceneState('cameraFocalLength')
  const cameraType = useSceneState('cameraType')
  const cameraFocalPlane = useSceneState('cameraFocalPlane')
  const cameraAperture = useSceneState('cameraAperture')
  const cameraDistortion = useSceneState('cameraDistortion')
  const { setPropertyState } = useSceneActions()

  const frameCameraCollapsed = useProjectState('frameCameraCollapsed')
  const sizePreference = useProjectState('sizePreference')
  const { setPanelAccordionItem } = useProjectActions()
  const { isDefaultEnvironment } = useStudioEnvironment()
  const t = useTranslations()

  return (
    <PanelAccordion
      label={t('scene:properties:camera')}
      collapsed={frameCameraCollapsed}
      onChange={collapsed =>
        setPanelAccordionItem({ key: 'frameCameraCollapsed', collapsed })
      }>
      <ActionGroup
        quiet
        size={sizePreference}
        selects="single"
        selected={cameraType.toString()}
        change={e => {
          const keys = (e as unknown as { target: { selected: string[] } })
            .target.selected
          if (!keys.length) return
          setPropertyState({
            key: 'cameraType',
            value: Number(keys[0]) as EngineCamera
          })
        }}>
        {isDefaultEnvironment && (
          <ActionButton
            value={EngineCamera.ISOMETRIC.toString()}
            aria-label={t('scene:properties:camera:isometric')}>
            {t('scene:properties:camera:isometric')}
          </ActionButton>
        )}
        <ActionButton
          value={EngineCamera.FREE_ORTHOGONAL.toString()}
          aria-label={t('scene:properties:camera:parallel')}>
          {t('scene:properties:camera:parallel')}
        </ActionButton>
        <ActionButton
          value={EngineCamera.PERSPECTIVE.toString()}
          aria-label={t('scene:properties:camera:perspective')}>
          {t('scene:properties:camera:perspective')}
        </ActionButton>
      </ActionGroup>

      {cameraType === EngineCamera.PERSPECTIVE && isDefaultEnvironment && (
        <PropertyInputSlider
          size={sizePreference}
          variant="filled"
          min={1}
          max={400}
          step={1}
          aria-label={t('scene:properties:camera:focalLength')}
          label={t('scene:properties:camera:focalLength')}
          value={cameraFocalLength}
          defaultValue={cameraFocalLength}
          onInput={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              key: 'cameraFocalLength',
              value
            })
          }}
          onMouseDown={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              key: 'cameraFocalLength',
              value,
              commit: EngineCommitChange.BEGIN_COMMIT
            })
          }}
          onMouseUp={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              key: 'cameraFocalLength',
              value,
              commit: EngineCommitChange.END_COMMIT
            })
          }}
          editable
          hideStepper
        />
      )}
      {cameraType === EngineCamera.PERSPECTIVE && isDefaultEnvironment && (
        <PropertyInputSlider
          size={sizePreference}
          variant="filled"
          min={0}
          max={10}
          step={0.001}
          aria-label={t('scene:properties:camera:focalPlane')}
          label={t('scene:properties:camera:focalPlane')}
          value={cameraFocalPlane}
          onInput={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              key: 'cameraFocalPlane',
              value
            })
          }}
          onMouseDown={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              key: 'cameraFocalPlane',
              value,
              commit: EngineCommitChange.BEGIN_COMMIT
            })
          }}
          onMouseUp={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              key: 'cameraFocalPlane',
              value,
              commit: EngineCommitChange.END_COMMIT
            })
          }}
          editable
          hideStepper
        />
      )}
      {cameraType === EngineCamera.PERSPECTIVE && isDefaultEnvironment && (
        <PropertyInputSlider
          size={sizePreference}
          variant="filled"
          min={0}
          max={10}
          step={0.001}
          aria-label={t('scene:properties:camera:aperture')}
          label={t('scene:properties:camera:aperture')}
          value={cameraAperture}
          onInput={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              key: 'cameraAperture',
              value
            })
          }}
          onMouseDown={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              key: 'cameraAperture',
              value,
              commit: EngineCommitChange.BEGIN_COMMIT
            })
          }}
          onMouseUp={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              key: 'cameraAperture',
              value,
              commit: EngineCommitChange.END_COMMIT
            })
          }}
          editable
          hideStepper
        />
      )}

      {isDefaultEnvironment && (
        <PropertyInputSlider
          size={sizePreference}
          variant="filled"
          min={0}
          max={1}
          step={0.001}
          aria-label={t('scene:properties:camera:distortion')}
          label={t('scene:properties:camera:distortion')}
          value={cameraDistortion}
          onInput={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              key: 'cameraDistortion',
              value
            })
          }}
          onMouseDown={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              key: 'cameraDistortion',
              value,
              commit: EngineCommitChange.BEGIN_COMMIT
            })
          }}
          onMouseUp={e => {
            const value = (e as unknown as { target: { value: number } }).target
              .value
            setPropertyState({
              key: 'cameraDistortion',
              value,
              commit: EngineCommitChange.END_COMMIT
            })
          }}
          editable
          hideStepper
        />
      )}
    </PanelAccordion>
  )
}

export default Camera
