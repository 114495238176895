import React, { FC, memo } from 'react'
import { useSceneActions, useSceneState } from '@hooks/useScene'
import {
  ActionButton,
  Checkbox,
  FieldLabel,
  Icon,
  OverlayTrigger,
  Tooltip
} from 'ui'
import { MaterialType } from '@services/engine/types'
import MaterialPicker from '@components/propertiesPanel/MaterialPicker'
import SaveToIcon from '/public/temp-icon-add-to-lib.svg'
import { useProjectState, useProjectActions } from '@hooks/useProject'
import PanelAccordion from '@components/panel/PanelAccordion'
import { Section } from './ObjectProperties'
import { VariableColorPicker } from '@components/variableModeColorPicker/VariableModeColorPicker'
import { PercentPropertySlider } from '@components/slider/PercentPropertySlider'
import { useStudioEnvironment } from '@hooks/useStudioEnvironment'
import { useTranslations } from 'use-intl'

const Material: FC = () => {
  const t = useTranslations()
  const threeDMaterialCollapsed = useProjectState('threeDMaterialCollapsed')
  const sizePreference = useProjectState('sizePreference')
  const { setPanelAccordionItem } = useProjectActions()

  const materialType = useSceneState('materialType')
  const address = useSceneState('address')
  const materialColor = useSceneState('materialColor')
  const materialEColorLig = useSceneState('materialEColorLig')
  const materialEColorTop = useSceneState('materialEColorTop')
  const materialEColorSha = useSceneState('materialEColorSha')
  const materialIColor = useSceneState('materialIColor')
  const materialIEmissiveColor = useSceneState('materialIEmissiveColor')
  const materialKeepStylesInSync = useSceneState('materialKeepStylesInSync')
  const materialReflective = useSceneState('materialReflective')
  const { isDefaultEnvironment } = useStudioEnvironment()

  const { setPropertyState } = useSceneActions()

  const { setShowSaveMaterialDialog } = useProjectActions()

  const isPTypeSelected = materialType === MaterialType.P
  const isETypeSelected = materialType === MaterialType.E
  const isPixelTypeSelected = materialType === MaterialType.PIXEL
  const isIllustrativeTypeSelected = materialType === MaterialType.I

  return (
    <PanelAccordion
      id={Section.appearance}
      label={
        <>
          {t('object:properties:appearance')}
          {isDefaultEnvironment && (
            <div className="position-relative">
              <div className="panel-accordion-label-button">
                <OverlayTrigger placement="bottom">
                  <ActionButton
                    size={sizePreference}
                    quiet
                    slot="trigger"
                    onClick={e => {
                      e.stopPropagation()
                      setShowSaveMaterialDialog(true)
                    }}>
                    <Icon slot="icon">
                      <SaveToIcon />
                    </Icon>
                  </ActionButton>
                  <Tooltip slot="hover-content">
                    {t('object:properties:saveMaterial')}
                  </Tooltip>
                </OverlayTrigger>
              </div>
            </div>
          )}
        </>
      }
      collapsed={threeDMaterialCollapsed}
      onChange={collapsed =>
        setPanelAccordionItem({ key: 'threeDMaterialCollapsed', collapsed })
      }>
      {isPTypeSelected && (
        <MaterialPicker
          sizePreference={sizePreference}
          label={t('object:properties:material')}
          showHexField
          address={address}
          materialColor={materialColor}
          materialEColorLig={materialEColorLig}
          materialEColorTop={materialEColorTop}
          materialEColorSha={materialEColorSha}
          materialIColor={materialIColor}
          materialIEmissiveColor={materialIEmissiveColor}
          materialKeepStylesInSync={materialKeepStylesInSync}
          materialType={materialType}
          setPropertyState={setPropertyState}
        />
      )}
      {(isETypeSelected ||
        isPixelTypeSelected ||
        isIllustrativeTypeSelected) && <VariableColorPicker />}

      {isPTypeSelected ? (
        <>
          {isDefaultEnvironment && (
            <>
              <PercentPropertySlider
                ariaLabel={t('object:properties:materialRoughness')}
                label={t('object:properties:materialRoughness')}
                valueKey="materialRoughness"
                propertyPath="shapeMaterial.pbrRoughness"
              />

              <PercentPropertySlider
                ariaLabel={t('object:properties:materialMetalness')}
                label={t('object:properties:materialMetalness')}
                valueKey="materialMetalness"
                propertyPath="shapeMaterial.pbrMetalness"
              />
              <div className="flex align-center gap-xs">
                <Checkbox
                  id="primitive-material-reflective"
                  size={sizePreference}
                  checked={materialReflective}
                  change={e =>
                    setPropertyState({
                      address,
                      key: 'materialReflective',
                      value: !materialReflective
                    })
                  }></Checkbox>
                <FieldLabel for="primitive-material-reflective">
                  {t('object:properties:materialReflective')}
                </FieldLabel>
              </div>
            </>
          )}
        </>
      ) : isETypeSelected || isPixelTypeSelected ? (
        <>
          <PercentPropertySlider
            disabled
            ariaLabel={t('object:properties:highlightSize')}
            label={t('object:properties:highlightSize')}
            valueKey="materialESpecularSize"
            propertyPath="shapeMaterial.exprSpecularSize"
          />
          <PercentPropertySlider
            ariaLabel={t('object:properties:highlightIntensity')}
            label={t('object:properties:highlightIntensity')}
            valueKey="materialESpecularIntensity"
            propertyPath="shapeMaterial.exprSpecularIntensity"
          />
        </>
      ) : (
        <>
          <PercentPropertySlider
            ariaLabel={t('object:properties:strokeSize')}
            label={t('object:properties:strokeSize')}
            valueKey="materialIStrokeSize"
            propertyPath="shapeMaterial.illustrativeStrokeSize"
          />
          <PercentPropertySlider
            ariaLabel={t('object:properties:highlightIntensity')}
            label={t('object:properties:highlightIntensity')}
            valueKey="materialIHighlightIntensity"
            propertyPath="shapeMaterial.illustrativeSpecularIntensity"
          />
          <PercentPropertySlider
            ariaLabel={t('object:properties:paintStrokeIntensity')}
            label={t('object:properties:paintStrokeIntensity')}
            valueKey="materialIStrokeIntensity"
            propertyPath="shapeMaterial.illustrativeNormalIntensity"
          />
          <PercentPropertySlider
            ariaLabel={t('object:properties:strokeColorVariation')}
            label={t('object:properties:strokeColorVariation')}
            valueKey="materialIColorVarIntensity"
            propertyPath="shapeMaterial.illustrativeColorVariation"
          />
          <PercentPropertySlider
            ariaLabel={t('object:properties:scaleX')}
            label={t('object:properties:scaleX')}
            valueKey="materialIScaleX"
            propertyPath="shapeMaterial.illustrativeScaleX"
          />
          <PercentPropertySlider
            ariaLabel={t('object:properties:scaleY')}
            label={t('object:properties:scaleY')}
            valueKey="materialIScaleY"
            propertyPath="shapeMaterial.illustrativeScaleY"
          />
          <PercentPropertySlider
            ariaLabel={t('object:properties:angle')}
            label={t('object:properties:angle')}
            valueKey="materialIAngle"
            propertyPath="shapeMaterial.illustrativeAngle"
          />
        </>
      )}
    </PanelAccordion>
  )
}

export default memo(Material)
