import React, { FC } from 'react'
import { Overlay, Popover } from 'ui'
import styles from '@styles/components/SharedBadge.module.scss'
import { ElementSize } from '@store/slices/projectSlice'
import Badge from '@components/badge/Badge'
import GlobeGridIcon from '/public/s2_icon_globe_grid.svg'
import ShareLink from './ShareLink'
import { useTranslations } from 'use-intl'

interface Props {
  size?: ElementSize
}

const SharedBadge: FC<Props> = ({ size = 'm' }) => {
  const t = useTranslations()

  return (
    <>
      <Badge
        id="project-shared-badge"
        icon={<GlobeGridIcon />}
        size={size}
        variant="positive">
        {t('studio:sharedBadge')}
      </Badge>
      <Overlay
        placement="bottom-start"
        offset={10}
        trigger="project-shared-badge@hover">
        <Popover className={styles['popover']}>
          <p>{t('studio:sharedBadge:description')}</p>
          <ShareLink />
        </Popover>
      </Overlay>
    </>
  )
}

export default SharedBadge
