import React, { useState } from 'react'
import cn from 'classnames'
import { ActionButton } from 'ui'
import styles from '@styles/components/LandingPageVideos.module.scss'
import { useTranslations } from 'use-intl'
import { UseTranslationsHookResult } from '@concerns/i18n/components/LocaleProvider'

const getVideoInfo = (
  t: UseTranslationsHookResult
): {
  label: string
  desc: string
}[] => {
  return [
    {
      label: t('page:landing:video:create'),
      desc: t('page:landing:video:create:desc')
    },
    {
      label: t('page:landing:video:refine'),
      desc: t('page:landing:video:refine:desc')
    },
    {
      label: t('page:landing:video:collaborate'),
      desc: t('page:landing:video:collaborate:desc')
    },
    {
      label: t('page:landing:video:deliver'),
      desc: t('page:landing:video:deliver:desc')
    }
  ]
}

const LandingPageVideos = () => {
  const t = useTranslations()

  const [activeSection, setActiveSection] = useState(0)
  const currentVideoIndex = activeSection + 1
  const videoInfo = getVideoInfo(t)

  return (
    <div className={styles['container']}>
      <div className={styles['video-container']}>
        <video
          className={styles['video']}
          key={`video-${currentVideoIndex}`}
          autoPlay
          loop
          muted
          playsInline>
          <source
            src={`landing_page/video_picker/${currentVideoIndex}.mp4`}
            type="video/mp4"
          />
        </video>
      </div>
      <div className={styles['video-picker']}>
        {videoInfo.map(({ label }, i) => (
          <ActionButton
            staticColor="black"
            size="l"
            className={cn(styles['video-switch-button'], {
              [styles['active']]: activeSection === i
            })}
            selected={false}
            active={activeSection === i}
            onClick={() => setActiveSection(i)}
            key={i}>
            <span>{label}</span>
          </ActionButton>
        ))}
      </div>
      <span className={styles['video-blurb']}>
        {videoInfo[activeSection].desc}
      </span>
    </div>
  )
}

export default LandingPageVideos
